import {axios} from "../config";

export const LogOutUsers = async () => {
  const videos = await axios.post('/logout', null, {
    withCredentials: true
  }).then((a) => {
    return a;
  })

  return videos;
}