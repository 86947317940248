import React from 'react';
import { Trash } from 'react-bootstrap-icons';

const Button = (props) => {
  const divclass = 'flex ' + props.class;
  const flexStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', ...props.style };
  return (
    <div>
      <div 
        id={props.id} 
        className={`button ${props.disabled ? 'disabled' : divclass}`} 
        onClick={props.onClick} 
        style={flexStyle}
      >
        {props.icon} {props.text}
      </div>
    </div>
  );
};

export default Button;
