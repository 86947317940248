import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import Button from '../button/Button';
// import ConfirmedModal from './Confirmed';

// const confirmStyle = {
//   width: '180px',
//   height: '40px',
//   backgroundColor: '#003c71',
//   color: 'white',
//   cursor: 'pointer',
//   borderRadius: '8px',
//   border: 'none',
//   alignItems: 'center',
//   justifyContent: 'center',
//   marginTop: '100px',
// };
// const cancelStyle = {
//   width: '180px',
//   height: '40px',
//   backgroundColor: 'lightgray',
//   color: 'black',
//   cursor: 'pointer',
//   borderRadius: '8px',
//   border: 'none',
//   alignItems: 'center',
//   justifyContent: 'center',
//   marginTop: '100px',
// };

// const handleSendReminder = () => {
//   onConfirm();
//   showConfirmedModal(true);
// };

// const [isReminderModalOpen, setReminderModalOpen] = useState(false);
// const [isConfirmedModalOpen, setConfirmedModalOpen] = useState(false);

// const handleOpenReminderModal = () => {
//   setReminderModalOpen(true);
// };

// const handleCloseReminderModal = () => {
//   setReminderModalOpen(false);
// };

// const handleConfirmReminderModal = () => {
//   setConfirmedModalOpen(true);
// };

// const handleCloseConfirmedModal = () => {
//   setConfirmedModalOpen(false);
// };

// <Button
//   onClick={handleConfirmReminderModal}
//   style={confirmStyle}
//   text={'Send Reminder'}
// />
// <ConfirmedModal
//   isOpen={isConfirmedModalOpen}
//   onClose={handleCloseConfirmedModal}
//   content={<p>You have sent out the reminder</p>}
// />
// <Button onClick={onClose} style={cancelStyle} text={'Cancel'} />
const ModalTextButton = (props) => {
  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  };

  const [reminderText, setReminderText] = useState('');

  return (
    props.isOpen && (
      <div style={modalOverlay}>
        <div style={props.ModalStyle}>
          <div className='flex flex-row items-center font-["Arial"] text-[24px]'>
            {props.showFaCheck && <FontAwesomeIcon
              icon={faCheck}
              style={{ color: 'green', fontSize: '4em', marginRight: '20px' }}
            />}
            <p style={props.text_style}>{props.text}</p>
          </div>
          <div className='flex justify-center'>{props.Button}</div>
        </div>
      </div>
    )
  );
};

export default ModalTextButton;
