import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Playlist from './pages/home/Playlist';
import Login from './pages/login/login';
import Register from './pages/login/register';
import ResetPassword from './pages/login/resetpassword';
import Review from './pages/review/review';
import PlaylistDetail from './pages/playlist/PlaylistDetail';
import Upload from './pages/upload/Upload';
import UploadProgression from './pages/upload/UploadProgression';
import SSOLogin from './pages/login/ssologin';


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<SSOLogin />} />
        <Route exact path='/login' element={<SSOLogin />} />
        <Route exact path='/devlogin' element={<Login />} />
        <Route exact path='/reset' element={<ResetPassword />} />
        <Route exact path='/register' element={<Register />} />
        <Route exact path='/review/:id' element={<Review />} />
        <Route exact path='/playlist' element={<PlaylistDetail />} />
        <Route exact path='/upload' element={<Upload />} />
        <Route
          exact
          // path='/upload_progression/:id/:type'
          path='/upload_progression/:id/:type'
          // path='/upload_progression/:id/'
          element={<UploadProgression />}
        />
      </Routes>
    </Router>
  );
}

export default App;
