import React, { useState, useEffect } from 'react';
import Button from '../button/Button';
import ConfirmedModal from './Confirmed';
import { GoX } from 'react-icons/go';

const MarkReviewModal = ({ isOpen, onClose, onConfirm }) => {

  const [is2xlViewport, setIs2xlViewport] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Determine if the viewport size is 2xl based on Tailwind CSS breakpoints
      const is2xl = window.innerWidth >= 1536; // Tailwind CSS 2xl breakpoint

      // Update state based on viewport size
      setIs2xlViewport(is2xl);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Initial check for viewport size on component mount
    handleResize();

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  };

  const modal = {
    background: '#fff',
    width: '30%',
    height: '30%',
    padding: '50px',
    borderRadius: '6px',
  };

  const ConfirmStyle = {
    width: '208px',
    height: '50px',
    backgroundColor: '#07860C',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
    marginRight: '10px',
    fontSize: '20px'
  };

  const smallConfirmStyle = {
    width: '180px',
    height: '30px',
    backgroundColor: '#07860C',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    marginRight: '10px',
    fontSize: '14px'
  }

  const closeStyle = {
    width: '208px',
    height: '50px',
    backgroundColor: '#D9D9D9',
    color: 'black',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
    fontSize: '20px'
  };

  const smallcloseStyle = {
    width: '180px',
    height: '30px',
    backgroundColor: '#D9D9D9',
    color: 'black',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    fontSize: '14px'
  };

  return (
    isOpen && (
      <div style={modalOverlay}>
        <div className='w-[35%] h-[40%] 2xl:w-[35%] 2xl:h-[40%] rounded-[6px] p-[50px] bg-[#fff]'>
          <div className='flex px-[30px] items-center justify-center flex-col'>
            <p className='text-[16px] 2xl:text-[20px]'>
              Are you ready to mark your review for this video as complete? This will not impact the review status for other reviewers.
            </p>
            <p className='text-[12px] mt-[15px] 2xl:text-[16px]'>
              <i>(<b>NOTE</b>:Approval here does not equate to APPROVE system approval. A separate action is required.)</i>
            </p>
            <div className='flex'>
              <button onClick={onConfirm} style={is2xlViewport ? ConfirmStyle : smallConfirmStyle}>Yes, Mark Complete</button>
              <button
                onClick={onClose}
                style={is2xlViewport ? closeStyle : smallcloseStyle}
              >
              Cancel
              </button>
            </div>
            {/* <Button onClick={onConfirm} style={downloadStyle} text={'Edit Video Title & Description'} />
            <Button
              onClick={onClose}
              style={downloadStyle}
              text={'Download Report'}
            />
            <Button
              onClick={onClose}
              style={downloadStyle}
              text={'Download Subtitle (.vtt)'}
            /> */}
          </div>
        </div>
      </div>
    )
  );
};

export default MarkReviewModal;
