import React,{useState,useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Select from '../../components/atoms/select/Select';
import Header from '../../components/atoms/header/Header';
import Avatar from '../../components/atoms/avatar/Avatar';
import Button from '../../components/atoms/button/Button';
import ModalTextButton from '../../components/atoms/modal/ModalTextButton';
import { GetAllDivisions } from '../../axios/get/divisions';
import {axios} from '../../axios/config.js'
import { GetSSOUser } from '../../axios/get/get_sso_user.js';
// import axios from 'axios';

const UploadPage = () => {
  const BrowseButtonStyle = {
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    // display:'inline-block',
    paddingLeft: '4px',
    float:'left',
    fontFamily: 'Arial, sans-serif',
    textAlign:'center',
    width: '140px',
    height: '30px',
    fontSize: '15px',
  }

  const EmptyFormModal = {
    width: '140px',
    height: '40px',
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '8px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize:'16px',
    marginTop:'20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
  }
  const hiddenFileInput = useRef(null);

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


  const [file, setFile] = useState();
  const [errorText, setErrorText] = useState();
  const [division, setDivision] = useState();
  const [CurrentUser, SetCurrentUser] = useState([]);
  const [isEmptyFormModalOpen, setEmptyFormModalOpen] = useState(false);
  const navigate = useNavigate();
  // const user_id = localStorage.getItem('user_id');

  function handeChange(event){
    
    var elem = document.getElementById("display_filename");
    setFile(event.target.files[0])
    elem.innerHTML = 'Selected file : ' + event.target.files[0].name
  };

  async function handleUploadSubmit(event){
    if(file && division){
      // submitBtnElem.style.backgroundColor = "#003c71"
      event.preventDefault();
  
      // checkFormCompletion().then((returnData) => {
      // })
      const data =  {
        chosenDivision : division,
        uploadedFile : file,
        fileName : file.name.replace(/\.[^/.]+$/, '')
      };
      const res_id = await axios.post('/api/v1/generate-video',{
      // const res_id = await axios.post('http://54.254.132.160:5000/api/v1/generate-video',{
        uploader_id: user_id, // Include the CurrentUser.id in the request body
        division_id:division 
      },{headers: {'Content-Type':'application/json'}})
      
      navigate('/upload_progression/'+res_id.data.response + '/'+"1",{ state : data});
      
    }
  };

  function handleUpload(event){
    hiddenFileInput.current.click()
  }

  function handleDivision(event){
    setDivision(event.nativeEvent.target.value)
  }

  const handleEmptyModalClose = () => {
    setEmptyFormModalOpen(false);
  }
  

  const [DivisionsDB, setDivisionsDB] = useState({ rows: [] });
  
  const FetchSSOUser = async () => {
    try{
      const response = await GetSSOUser();
      const { user_id, user_name, email } = response.data;
      // Store tokens in localStorage
      sessionStorage.setItem('user_id', user_id);
      sessionStorage.setItem('user_name', user_name);
      sessionStorage.setItem('email', email);
      if(user_id != "undefined" || is_sso != null){
        sessionStorage.setItem('sso_user', true)
      }
      return { user_id, user_name, email };
    }
    catch(err){
      console.log("Error In Fetching SSO User", err)
    }
  }

  const user_id = sessionStorage.getItem('user_id');
  const user_name = sessionStorage.getItem('user_name');
  const email = sessionStorage.getItem('email');
  const is_sso = sessionStorage.getItem('sso_user')

  const fetchDivisionsData = async () => {
    try {
      if(is_sso == "false" || is_sso == null ){
        console.log("NO SSO")
        console.log("User ID:", user_id);
        const response = await GetAllDivisions(user_id);
        console.log("the response",response)
        response.data.rows = response.data.rows.sort((a,b)=> a.name.localeCompare(b.name))
        setDivisionsDB(response.data);
      }
      else{
        console.log("YES SSO")
        const { user_id } = await FetchSSOUser();
        console.log("User ID:", user_id);
        const response = await GetAllDivisions(user_id);
        console.log("the response",response)
        response.data.rows = response.data.rows.sort((a,b)=> a.name.localeCompare(b.name))
        setDivisionsDB(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 302) {
        // Redirect received, handle it
        sessionStorage.clear();
        window.location.href = error.response.data.redirecturl;
      } else {
        // Handle other errors
        console.error('Error fetching video:', error);
        throw error;
      }
    }
  }

  useEffect(() => {
    if(is_sso == null){
      FetchSSOUser();
    }
    fetchDivisionsData();
  }, []);
  // useEffect(() => {
  //   // Fetch data from your API or backend
  //   GetAllDivisions()  // Update the endpoint
  //     .then(response => {
  //       setDivisionsDB(response.data);
  //       console.log("Divisions",response.data)
  //     })
  //     .catch(error => {
  //       console.error('Error fetching categories:', error);
  //     });
  // }, []);
  // const user_name = localStorage.getItem('user_name');
  const username = user_name; 

  const initials = username && username
    .split(' ')
    .slice(0, 2)
    .map(word => word[0])
    .join('') 

  useEffect(()=> {
    var submitBtnElem = document.getElementById("submit-btn")
    if(file && division){
      submitBtnElem.style.backgroundColor = "#003c71"
    }else{
      submitBtnElem.style.backgroundColor = "#A9A9A9"
    }
  },[division,file])


  return (
  <>
    <div className="container mx-auto px-1 h-screen">
      <form>
        <Header type={'Upload'} logout={<Avatar initials={initials} />}/>
        <div className="pt-[76.59px] 2xl:pt-[115px] flex flex-col justify-center">
          <div className='text-center text-2xl font-bold font-["Arial"] text-[#003c71]'>Upload New Video</div>
          <div className='mx-auto justify-center content-evenly pt-[64.73px] 2xl:pt-[92.2px]'>
            <div className="container w-[445px] 2xl:w-[621.71px]">
              <div className="row after:table text-lg">
                <div className="col-2 font-bold font-['Arial'] inline-block p-[15px] text-[#003c71]">
                  Step 1
                </div>
                <div className="col-10 font-['Arial'] inline-block p-[15px]">
                  Locate the video file from your computer
                </div>
              </div>
              <div className="row after:table flex">
                <div className="col-2 opacity-0 font-['Arial'] inline-block px-[15px] pb-[15px] pt-[0px] 2xl:pt-[15px] text-[#003c71]">
                  Step 1
                </div>
                <div className="col-10 font-['Arial'] inline-block flex">
                  <Button class='w-[140px] h-[30px] text-[15px] justify-center items-center mr-4' onClick={handleUpload} style={BrowseButtonStyle} text={'Choose File'}/>
                  <div className="w-[252.76px] h-[25px] 2xl:w-[379.57px] 2xl:h-[30px] border-none rounded-md"><span id="display_filename" className="text-[12px] overflow-hidden">Selected file : </span></div>
                  <input type='file' id="formId" onChange={handeChange} ref={hiddenFileInput} className ="inline-block hidden" accept=".mp4, .webm"/>
                </div>
              </div>
              <div className="row after:table flex">
                <div className="col-2 opacity-0 font-['Arial'] inline-block px-[15px] pb-[15px] pt-[0px] 2xl:pt-[15px] text-[#003c71]">
                  Step 1
                </div>
                <div className="col-10 font-['Arial'] inline-block flex">
                  <div class="text-red-600 text-[12px]">
                    <span><b>Upload guideline:</b></span>
                    <ul class="list-decimal ml-[15px]">
                      <li>
                          Ensure your video has audible audio and is in mp4 and webm format.
                      </li>
                      <li>
                          Confirm the video file is not corrupted and can be played on your computer.
                      </li>
                      <li>
                          Maximum size limit is 5GB (Recommended: &lt; 2GB)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row after:table text-lg">
                <div className="col-2 font-bold font-['Arial'] inline-block p-[15px] text-[#003c71]">
                  Step 2
                </div>
                <div className="col-10 font-['Arial'] inline-block pl-[15px] pt-[15px]">
                  Choose the division for this video
                </div>
              </div>
              <div className="row after:table flex">
                <div className="col-2 opacity-0 text-base font-['Arial'] inline-block px-[15px] text-[#003c71]">
                  Step 1
                </div>
                <div className="col-10 font-['Arial'] inline-block">
                  <Select className='w-[150px] h-[30px] 2xl:w-[189.785px] 2xl:h-[30px] bg-[white] border border-gray-400 rounded-[8px] text-xs'
                          placeholder='Choose Division'
                          options={DivisionsDB}
                          isUpload={true}
                          onChange={handleDivision}
                        />
                </div>
              </div>
            </div>
          </div>
          <div className='text-center mx-auto justify-center content-evenly text-center pt-[60.31px] font-["Arial"] w-[245px]'>
            <Button id="submit-btn" class='mx-auto text-[15px] cursor-pointer bg-[#003c71] text-white rounded h-[37.06px] w-[157px] 2xl:h-[38px]' text={'START UPLOAD'} onClick={handleUploadSubmit}/>
            {/* <div className='content-evenly items-center mx-auto' >
            </div> */}
            {/* <input
              id="submit-btn"
              className='text-[15px] cursor-pointer bg-[#003c71] text-white rounded h-[37.06px] w-[157px] 2xl:h-[38px]'
              value='START UPLOAD'
              type='submit'
            /> */}
            <ModalTextButton
              isOpen={isEmptyFormModalOpen}
              ModalStyle={{
                background: '#fff',
                width: '511.59px',
                height: '261.59px',
                padding: '60px',
                borderRadius: '8px',
                textAlign: 'center',
                display: 'flex',
                flexDirection:'column',
              }}
              text={errorText}
              showFaCheck = {false}
              text_style = {{fontSize:'24px'}}
              Button={<Button onClick={handleEmptyModalClose} style={EmptyFormModal} text={'Return'}/>}
            />
            <br />
            <div className="text-xs mt-[10px] pb-[0px]">You will be able to assign reviewers and update keyword list at the next step</div>
          </div>
        </div>
      </form>
    </div>
  </>
  );
};

export default UploadPage;
