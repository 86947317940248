import {axios} from "../config";

export const RegisterUser = async (email, user_name, password) => {
  const login = await axios.post('/auth/register',{
    email: email.toLowerCase(),
    user_name : user_name,
    password: password,
    active : true
  }).then((a) => {
    return a;
  })

  return login;
}