import {axios} from "../config";

export const GetVideoWithReviewStatus = async (user_id, video_id) => {
    try {
        console.log('user_iddd:', user_id, 'video_id:', video_id);
        const video = await axios.get(`/api/review_status/${video_id}/${user_id}`, {
            // params: {
            //     user_id: parseInt(user_id),
            //     video_id: parseInt(video_id),
            // },
        });
        console.log('masukk siniii');
        return video.data;
    } catch (error) {
        console.error('Error fetching review status:', error);
        throw error; // Re-throw the error to handle it elsewhere if needed
    }
};
