import {axios} from "../config";

export const AddKeywords = async (category_id, keyword_name, division_id, user_id) => {
  const addkeyword = await axios.post('/api/AddKeywords',{
    category_id: parseInt(category_id),
    keyword_name : keyword_name,
    division_id: division_id,
    user_id: user_id,
    active : true
  }).then((a) => {
    return a;
  })

  const updatedKeywords = addkeyword.data; 

  return updatedKeywords;
}