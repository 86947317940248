import React,{useState, useEffect} from 'react';
import Select from '../../atoms/select/Select';
import Button from '../../atoms/button/Button';
import '../../../components/molecules/video-detail/VideoDetail.css';
import { GetAllKeywords } from '../../../axios/get/keyword';
import { AddKeywords } from '../../../axios/post/AddKeyword';
import { GetAllCategories } from '../../../axios/get/category';

const Keywordlist = (props) => {

  const [keywordadd, setkeywordadd] = useState('');
  const [preAssignedTrue, setPreAssignedTrue] = useState(false);
  const [keywords, setKeywords] = useState({ rows: [] });
	const [FilteredKeywords, setFilteredKeywords ] = new useState();
  // const [masterCategoryKeyword, setMasterCategoryKeyword] = useState([]);
  // const [chosenCategory, setChosenCategory] = useState()
  const [categoriesDB, setCategoryDB] = useState({ rows: [] });
  // const [masterCategoryKeyword, setMasterCategoryKeyword] = useState({chosenCategory:{},selectAll:false, selectedKeywords:{rows:[]}});
  // const [checkedKeywords, setCheckedKeywords] = useState([]);
  // const { onCheckedKeywordChanges, updateCheckedKeywords} = props;

  const filterFunction = (searchInput) => {
    const search = searchInput;
    var keywordList = props.masterCategoryKeyword.filter((state) => {return state.masterState.category.id == props.chosenCategory.id })[0].masterState.keywords;
    var idList = []

    const updatedList = keywordList.filter((item) => {
      if(item.keywordItem.keyword_name.toLowerCase().indexOf(search.toLowerCase()) !== -1){
        idList.push(item.keywordItem.id)
        return item;
      }

    });

    keywordList.forEach((item) => {
      if(idList.includes(item.keywordItem.id)){
        item.show = true
      }else{
        item.show = false
      }
    });
    var newMasterCategoryKeyword = []
    props.masterCategoryKeyword.forEach((stateItem) => {
      if(stateItem.masterState.category.id == props.chosenCategory.id){
        newMasterCategoryKeyword.push({masterState : {category: stateItem.masterState.category,selectAllState: stateItem.masterState.selectAllState,keywords: keywordList}})
      }else{
        newMasterCategoryKeyword.push(stateItem)
      }
    })

    props.setMasterCategoryKeyword(newMasterCategoryKeyword)
    if (Array.isArray(keywords)) {
      setFilteredKeywords(updatedList);
    } else {
      setFilteredKeywords({ rows: updatedList });
    }

  };

  const handleSearch = (event) => {
    filterFunction(event.target.value);
    setkeywordadd(event.target.value);
  };


  function handleCategory(event){

    const newCategoryData = categoriesDB.rows.filter((category) => {
      return category.id == event.nativeEvent.target.value ? category : false
    });
    const previousCategoryData = categoriesDB.rows.filter((data) => {
      return data.id == Number(props.chosenCategory.id) ? data : false
    });

    props.setChosenCategory(newCategoryData[0]);
    filterFunction(keywordadd);
    // props.onChangeCategory(newCategoryData,previousCategoryData)
  }
  const handleAddKeyword = async (e) => {
    props.setUIBlock(true)
    e.preventDefault();
    try {
      const existingKeywords = await GetAllKeywords(props.video_id, props.division_id);
      var toNotAddMoreKeyword = false
      existingKeywords.data.rows.map((item) => {
        if(item.keyword_name==keywordadd){
          toNotAddMoreKeyword = true
          props.openDuplicateKeywordModal(keywordadd)

        }
      });

      if(!toNotAddMoreKeyword){
        var newDataKeyword = []
        console.log("category", props.chosenCategory.id)
        console.log("keywordadd",keywordadd)
        console.log("division",props.division_id)
        console.log("user",props.user_id)
        const keywordAdded = await AddKeywords(props.chosenCategory.id,keywordadd,props.division_id,props.user_id)
        console.log("added keyword",keywordAdded)
        const updatedKeywords = await GetAllKeywords(props.video_id, props.division_id).then((keywords) => {
          var newMasterCategoryKeyword = []
          props.masterCategoryKeyword.forEach((categoryItem) => {
            if(props.chosenCategory.id == categoryItem.masterState.category.id){
              const newKeywordData = keywords.data.rows.filter((keywordDatas) => {return keywordDatas.id==keywordAdded.id})
              categoryItem.masterState.keywords.push({select:true,show:true,keywordItem:newKeywordData[0]});
              categoryItem.masterState.keywords.sort((a, b) => a.keywordItem.keyword_name.localeCompare(b.keywordItem.keyword_name));
              const allSelectState = categoryItem.masterState.keywords.map((keyword) => keyword.select)
              let checker = allSelectState.every(state => state !=false)
              newMasterCategoryKeyword.push({masterState : {category : categoryItem.masterState.category, selectAllState : checker, keywords : newDataKeyword}});
            }else{
              newMasterCategoryKeyword.push(categoryItem)
            }
          });
          // setCheckedKeywords([...checkedKeywords, response.id]);
          setKeywords(keywords.data);
          props.setMasterCategoryKeyword(newMasterCategoryKeyword);
        });
      };
      setkeywordadd('')

      props.setUIBlock(false)

    } catch (error) {
      setkeywordadd('')
      props.setUIBlock(false)
      console.error('Error creating user:', error);
    }
  };  


  const handleCheckboxChange = (keywordId) => {
    if(props.chosenCategory){
      var newMasterCategoryKeyword = []
      props.masterCategoryKeyword.forEach((item)=>{
        if(props.chosenCategory.id==item.masterState.category.id){
          // var keywordsAffected = item.masterState.keywords.filter((keyword) => {return keyword.show==true});
          var keywordsAffected = item.masterState.keywords;
          keywordsAffected.forEach((itemKeyword) => {
            if(itemKeyword.keywordItem.id==keywordId){
              itemKeyword.select=(!itemKeyword.select)
            }
          });

          const allSelectState = keywordsAffected.map((keyword) => keyword.select)
          let checker = allSelectState.every(state => state !=false)


          newMasterCategoryKeyword.push({masterState:{category:item.masterState.category, selectAllState:checker, keywords:keywordsAffected}})
        }else{
          newMasterCategoryKeyword.push(item)
        }
      })

      props.setMasterCategoryKeyword(newMasterCategoryKeyword)
    }
  };

  const handleOnChangeSelectAll = (event) => {
    var newMasterCategoryKeyword = []
    props.masterCategoryKeyword.forEach((item)=>{
      if(item.masterState.category.id == props.chosenCategory.id){
        // var keywordsAffected = item.masterState.keywords.filter((itemAffected) => {return itemAffected.show==true});
        var keywordsAffected = item.masterState.keywords;
          keywordsAffected.forEach((itemKeyword) => {
            itemKeyword.select = event.target.checked
          });

          const allSelectState = keywordsAffected.map((keyword) => keyword.select)
          let checker = allSelectState.every(state => state !=false)


          newMasterCategoryKeyword.push({masterState:{category:item.masterState.category, selectAllState:checker, keywords:keywordsAffected}})
        }else{
          newMasterCategoryKeyword.push(item)
        }
    });
    props.setMasterCategoryKeyword(newMasterCategoryKeyword)
  };

  const handleFirstValueAssignment = () => {
    const keywords = props.masterCategoryKeyword.map(masterState => masterState.masterState.keywords)
    var existingLengthValue = []
    keywords.forEach((keywordList) => {
      keywordList.forEach((keyword) => {
        existingLengthValue.push(keyword)
      });
    });
    if(props.keywordList && existingLengthValue.length > 0){
      props.setUIBlock(true)

      var newMasterCategoryKeyword = [];
      const keywordByKeywordList = props.keywordList.rows.map(keyword => (keyword.keyword_id));

      props.masterCategoryKeyword.forEach((masterData) => {

        var keywordUpdate = [];
        masterData.masterState.keywords.forEach((keywordData)=> {
          if(keywordByKeywordList.includes(keywordData.keywordItem.id)){
            keywordUpdate.push({select:true,show:keywordData.show, keywordItem:keywordData.keywordItem});
          }else{
            keywordUpdate.push({select:false,show:keywordData.show, keywordItem:keywordData.keywordItem});
          }
        })
        newMasterCategoryKeyword.push({masterState: {category:masterData.masterState.category , selectAllState:masterData.masterState.selectAllState, keywords : keywordUpdate}});
      })
      props.setMasterCategoryKeyword(newMasterCategoryKeyword)
      props.setUIBlock(false)
      setPreAssignedTrue(true)
    }else if (props.intitialEmpty && existingLengthValue.length > 0){
      props.setUIBlock(true)

      var newMasterCategoryKeyword = [];
      // const keywordByKeywordList = props.keywordList.rows.map(keyword => (keyword.keyword_id));

      props.masterCategoryKeyword.forEach((masterData) => {

        var keywordUpdate = [];
        masterData.masterState.keywords.forEach((keywordData)=> {
          if(keywordData.keywordItem.psst_category.category_code=='RA'){
            keywordUpdate.push({select:true,show:keywordData.show, keywordItem:keywordData.keywordItem});
          }else{
            keywordUpdate.push({select:false,show:keywordData.show, keywordItem:keywordData.keywordItem});
          }
        })
        if(masterData.masterState.category.category_code=='RA'){
          newMasterCategoryKeyword.push({masterState: {category:masterData.masterState.category , selectAllState:true, keywords : keywordUpdate}});
        }else{
          newMasterCategoryKeyword.push({masterState: {category:masterData.masterState.category , selectAllState:masterData.masterState.selectAllState, keywords : keywordUpdate}});
        }
      })
      props.setMasterCategoryKeyword(newMasterCategoryKeyword)
      props.setUIBlock(false)
      setPreAssignedTrue(true)
    }
  }


  useEffect(() => {
    GetAllKeywords(props.video_id, props.division_id)  // Update the endpoint
      .then(response => {
        setKeywords(response.data);

        const initialCheckedKeywords = response.data.rows.filter(
          (keyword) => keyword.keyword_list.length > 0
        );

        // updateCheckedKeywords(initialCheckedKeywords.map((keyword) => keyword.id));
        // onCheckedKeywordChanges(initialCheckedKeywords.map((keyword) => keyword.id));
        // setCheckedKeywords(initialCheckedKeywords.map((keyword) => keyword.id));
      })
      .catch(error => {
        console.error('Error fetching keywords:', error);
      });
    GetAllCategories()  // Update the endpoint
      .then(response => {
        var defaultCategory = []
        var nonDefaultCategory = []
        response.data.rows.forEach((category)=>{
          if(category.name=='BSC Products'){
            defaultCategory.push(category)
          }else{
            nonDefaultCategory.push(category)
          }
        })

        response.data.rows = [...defaultCategory,...nonDefaultCategory]

        setCategoryDB(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  useEffect(() => {
    if(categoriesDB.rows.length > 0 && !preAssignedTrue){
      var categoryKeyword = []
      categoriesDB.rows.forEach((categories)=> {
        categoryKeyword.push({masterState:{category:categories, selectAllState:false,keywords:[]}})
      })
      props.setMasterCategoryKeyword(categoryKeyword)
      
      console.log(categoryKeyword)
      console.log(categoriesDB.rows[0])

      // console.log()
      props.setChosenCategory(categoriesDB.rows[0])
    };
  },[categoriesDB]);

  useEffect(() => {
    if(props.chosenCategory){
      const search = keywordadd;
      filterFunction(search);
      if(!preAssignedTrue){
        handleFirstValueAssignment();
      }

    var valuesOfMasterState = props.masterCategoryKeyword.map((masterStateItem) => masterStateItem.masterState.keywords)
    var existingLengthValue = []
    valuesOfMasterState.forEach((keywordList) => {
      existingLengthValue.push(keywordList.length > 0)
    });
    let checker = existingLengthValue.every(state => state ==false)

    if(checker && keywords){
        var sortedKeywords = []
        var newMasterCategoryKeyword = []
        props.masterCategoryKeyword.forEach((categoryItem) => {
          var currentCategory = categoryItem.masterState.category
          var newDataKeyword = []
          console.log(keywords)
          if(keywords.rows.length != 0){
            sortedKeywords = [...keywords.rows].sort((a, b) => a.keyword_name.localeCompare(b.keyword_name));
            sortedKeywords.forEach((keyword) => {
              if(keyword.category_id == currentCategory.id){
                newDataKeyword.push({select:false, show:true,keywordItem:keyword})
              }
            });
          }
          else{
            sortedKeywords = keywords
            sortedKeywords.rows.forEach((keyword) => {
              if(keyword.category_id == currentCategory.id){
                newDataKeyword.push({select:false, show:true,keywordItem:keyword})
              }
            });
          }
          newMasterCategoryKeyword.push({masterState : {category:currentCategory, selectAllState:false, keywords : newDataKeyword}})
        })
      props.setMasterCategoryKeyword(newMasterCategoryKeyword)
      // if(keywords && Object.values(masterCategoryKeyword)){
      // }
    }

    
      // props.setMasterCategoryKeyword(newMasterCategoryKeyword)
    }
  },[props.chosenCategory, keywords, keywordadd, props.keywordList]);

  useEffect(()=> {
    if(keywordadd && props.chosenCategory){
      const search = keywordadd;
      filterFunction(search);
    }
    var valuesOfMasterState = props.masterCategoryKeyword.map((masterStateItem) => masterStateItem.masterState.keywords)
    var existingLengthValue = []
    valuesOfMasterState.forEach((keywordList) => {
      existingLengthValue.push(keywordList.length > 0)
    });
    let checker = existingLengthValue.some(state => state ==true)
    if(!preAssignedTrue && checker){
      handleFirstValueAssignment();
    }

  },[props.masterCategoryKeyword])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddKeyword(event)
      // console.log('do validate')
    }
  }
                      // <input type="checkbox" className='mr-[10px]' name={keyword.id} value={keyword.keyword_name} checked={checkedKeywords.includes(keyword.id)} onChange={() => handleCheckboxChange(keyword.id)}/>
                      // <input type="checkbox" className='mr-[10px]' name={keyword.id} value={keyword.keyword_name} checked={checkedKeywords.includes(keyword.id)} onChange={() => handleCheckboxChange(keyword.id)}/>

                      // {!FilteredKeywords && (Array.isArray(keywords) ? keywords : keywords.rows).map(keyword => (
                      //   <div key={keyword.id} className="flex mt-[20.5px]">
                      //     <input type="checkbox" className='mr-[10px]' id={keyword.id} name={keyword.id} value={keyword.keyword_name} checked={props.checkedKeywords && props.checkedKeywords.includes(keyword.id)} onChange={() => handleCheckboxChange(keyword.id)}/>
                      //     <span id={keyword.id}>{keyword.keyword_name}</span>
                      //   </div>
                      // ))}
                      // {FilteredKeywords && (Array.isArray(FilteredKeywords) ? FilteredKeywords : FilteredKeywords.rows).map(keyword => (
                      //   <div key={keyword.id} className="flex mt-[20.5px]">
                      //     <input type="checkbox" className='mr-[10px]' id={keyword.id} name={keyword.id} value={keyword.keyword_name} checked={props.checkedKeywords && props.checkedKeywords.includes(keyword.id)} onChange={() => handleCheckboxChange(keyword.id)}/>
                      //     <span id={keyword.id}>{keyword.keyword_name}</span>
                      //   </div>
                      // ))}

                      // default={categoriesDB.rows.length > 0 ? categoriesDB.rows[0].id : 0}
  return (
    <div className='w-[100%] h-[100%] p-[10px]'>
    	<span className="font-bold text-lg text-[#003c71]">{props.title}</span>
      <div>
        <table className='w-[100%]'>
          {props.notes &&(
            <tr>
              <td className='flex'>
                  <div className='notesbox bg-[#F2F2F2] font-["Arial"] p-[16px] text-[#7A7A7A]'
                  style={{
                    width: '100%',
                    height: '100%',
                    fontSize: '12px',
                  }}>
                    <p><b>Note: </b>Keywords displayed for reference only. Adding new keywords is not permitted here.</p>
                  </div>
              </td>
            </tr>
          )}
          <br />
          <br />
          <tr>
            {/* <td className='w-[8%]'/> */}
            <td className="flex" colSpan={"3"}>
              <div className="gap-[10px] mr-[20px]">
                <span>Choose a category:</span>
              </div>
              <Select className='p-[5px] pl-[10px] pr-[10px] bg-[white] border border-gray-400 rounded-[8px]'
                  options={categoriesDB}
                  isKeyword={true}
                  onChange={handleCategory}
              />
            </td>
          </tr>
          <br />
          <tr>
            <td colSpan={"3"}>
              <div className="overflow-y-scroll h-[550px] scrollbar font-roboto_mono">
                <div className='flex'>
                  <input id='select-all' type='checkbox' className={`mr-[10px] w-[20px] h-[20px] ${props.is_review !== undefined && props.is_review ? 'text-gray-500' : ''}`} checked={props.masterCategoryKeyword.length > 0 ? props.masterCategoryKeyword.filter((state)=>{return state.masterState.category.id == props.chosenCategory.id})[0].masterState.selectAllState : false} onChange={handleOnChangeSelectAll} disabled={props.is_review !== undefined && props.is_review}/>
                  <span className="font-semibold">SELECT ALL</span>
                </div>
                  {props.masterCategoryKeyword.length > 0 && (props.masterCategoryKeyword.filter((state) => {return state.masterState.category.id == props.chosenCategory.id })[0].masterState.keywords.filter((key) => {return key.show==true}).map(keyword => (
                    <div key={keyword.keywordItem.id} className="flex mt-[20.5px]">
                        <input type="checkbox" className={`mr-[10px] ${props.is_review !== undefined && props.is_review ? 'text-gray-500' : ''}`} id={keyword.keywordItem.id} name={keyword.keywordItem.id} value={keyword.keywordItem.keyword_name} checked={keyword.select} onChange={() => handleCheckboxChange(keyword.keywordItem.id)} disabled={props.is_review !== undefined && props.is_review}/>
                        <span id={keyword.keywordItem.id}>{keyword.keywordItem.keyword_name}</span>
                      </div>
                  )))}
              </div>
            </td>
          </tr>
        </table>
        <br />
        <table className='w-[100%]'>
          <tr>
            <td colSpan={"3"}>
              <div className="mt-[30px] w-[100%]">
                <div className="gap-[10px]">
                  <input className="border border-[#D9D9D9] rounded-[8px] p-[3px] pl-[20px] w-[100%]" placeholder={"Search or add a keyword (case insensitive)"} onChange={handleSearch} value={keywordadd} onKeyDown={handleKeyDown}/>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={"3"} className='text-[12px] text-[#7A7A7A] pl-[23px]'>
              {keywordadd && (<span>Keyword not found?</span>)} {keywordadd && (<span className='cursor-pointer' onClick={handleAddKeyword}><u>Add “{keywordadd}” to the keyword list</u></span>)}
            </td>
          </tr>
        </table>
        
      </div>

    </div>
  );
};
            // {keywordadd && (<td colSpan={"3"} className='text-[10px] text-[#7A7A7A] pl-[23px]'><span>Keyword not found?</span> <span className='cursor-pointer' onClick={handleAddKeyword}><u>Add “{keywordadd}” to the keyword list</u></span></td>)}

export default Keywordlist;


