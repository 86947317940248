import {axios} from "../config";

export const AddReviewers = async (user_id , video_id) => {
  const TranscriptComment = await axios.post('/api/addreviewers',{
    user_id: parseInt(user_id),
    video_id: parseInt(video_id),
  }).then((a) => {
    return a;
  })

  return TranscriptComment;
}