import React, {useCallback,useEffect, useState} from 'react';
import '../../../components/molecules/video-detail/VideoDetail.css';
import { GetAllUsers } from '../../../axios/get/users';

const VideoDet = (props) => {
  const { onVideoDetailChange, onReviewersDataChanges, updateCheckedKeywords} = props;
  const {onChangeTitle} = props;
  const {onChangeDetail} = props;


  const handleInputChange = (field, e) => {
    // Update the state in EditVideoDetailReviewModal
    if(props.isModal){
      onVideoDetailChange(field, e.target.value);
    }else{
      if(field=='video_title'){
        onChangeTitle(e.target.value);
      }
      else{
        onChangeDetail(e.target.value);
      }
    }
  };
  const handleCheckboxChange = (userId) => {
    onReviewersDataChanges(userId);
  };

  
  const [users, SetUsers] = useState({ rows: []});
  const [filteredUsers, setFilteredUsers ] = new useState();
  
  
  const handleSortCheckedReviewers = (searchUpdate) => {
    var newList = []
    if(searchUpdate){
      const checkedList = searchUpdate.filter(user => props.checkedKeywords.includes(user.id))
      const uncheckedList = searchUpdate.filter(user => !props.checkedKeywords.includes(user.id))
      newList = [...checkedList,...uncheckedList]
    } 
    else if(filteredUsers){
      const checkedList = filteredUsers.rows.filter(user => props.checkedKeywords.includes(user.id))
      const uncheckedList = filteredUsers.rows.filter(user => !props.checkedKeywords.includes(user.id))
      newList = [...checkedList,...uncheckedList]
    }else{
      const checkedList = users.rows.filter(user => props.checkedKeywords.includes(user.id))
      const uncheckedList = users.rows.filter(user => !props.checkedKeywords.includes(user.id))
      newList = [...checkedList,...uncheckedList]
    }
    if (Array.isArray(users)) {
      setFilteredUsers(newList);
    } else {
      setFilteredUsers({ rows: newList });
    }
  }

  useEffect(()=>{
    if(users.rows.length > 0){
      handleSortCheckedReviewers()
    }
  },[props.checkedKeywords, users])

  useEffect(() => {
    // Fetch data from your API or backend
    // GetAllUsers(props.video_id, props.division_id)  // Update the endpoint
    GetAllUsers(props.video_id)  // Update the endpoint
      .then(response => {
        SetUsers(response.data);

        const reviewersWithReviews = response.data.rows.filter(user => user.reviewers.length > 0);
        const reviewerIds = reviewersWithReviews.map(user => user.id);
        if (reviewerIds.length > 0) {
          const reviewersToChecked = reviewersWithReviews.map(user => onReviewersDataChanges(user)); // Update checkedKeywords in EditVideoDetailReview.js
          updateCheckedKeywords(reviewerIds); // Update checkedKeywords in VideoDetail.js
        }
      })
      .catch(error => {
        console.error('Error fetching video:', error);
      });
  }, []);

  const [checkedUser, setCheckedUser] = useState([]);
  // const [triggerSort, settriggerSort] = useState();

  const handleSearch = (event) => {
    const search = event.target.value;
    // setkeywordadd(event.target.value)
    const keywordList = Array.isArray(users) ? users : users.rows;

    const updatedList = keywordList.filter((item) => {
      return item.user_name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    if (Array.isArray(users)) {
      setFilteredUsers(updatedList);
    } else {
      setFilteredUsers({ rows: updatedList });
    }
    handleSortCheckedReviewers(updatedList)
  };

  return(
    <div>
      <form className='w-[100%]'>
        <div className='flex flex-row  h-[100%]' >
          <div className={`flex flex-col ${props.isModal ? 'w-[100%]' : 'w-[70%]'} mr-[30px]`}>
            <label className={`${props.isModal ? 'text-[18px]' : 'text-[15px]'} text-[#003C71]`}><b>* Video Title</b></label>

            <textarea className='scrollbar h-[50px] border p-[10px] text-[15px] rounded border-[#D9D9D9]' style={{resize:'none'}} onChange={(e) => handleInputChange('video_title', e)}>
              {props.video_data ? props.video_data.video_title : ''} 

            </textarea>
            <label className='text-[15px] mt-[10px] text-[#003C71]'><b>Description</b></label>

            <textarea className='scrollbar border p-[10px] text-[13px] rounded border-[#D9D9D9] h-[108.5px]' style={{resize:'none'}} onChange={(e) => handleInputChange('description', e)}>
              {props.video_data ? props.video_data.description : ''}
            </textarea>
          </div>
          {!props.isModal ? (
          <div className='w-[30%]'>
            <label for='checkboxes' className='mb-[5px] text-[#003C71]'><b>Reviewers</b></label>
            <input className='mt-[8px] w-[95%] h-[30px] border rounded-[8px] border-[#D9D9D9] p-[5px] text-[15px]' type="text" placeholder='Search For Reviewer' onChange={handleSearch}></input>
            <div id="checkboxes" className='flex h-[145px] mt-[8px] flex-col scrollbar overflow-ellipsis overflow-auto whitespace-nowrap text-[15px]'>
              {!filteredUsers && users.rows.map(user => (
                <div key={user.id}>
                  <input type="checkbox" className='mr-[10px]' id={user.id} name={user.id} value={user.id} checked={props.checkedKeywords && props.checkedKeywords.includes(user.id)} onChange={() => handleCheckboxChange(user)}/>
                  <label for="vehicle1" className='align-text-top'><span>{user.user_name}</span>

                  {user.reviewers.length > 0 && user.reviewers[0].review_status && (
                    <i>
                      <span className="text-[12px] text-[#003C71] ml-[10px] font-['Arial']">
                        Reviewed
                      </span>
                    </i>
                  )}
                  </label>
                </div>
              ))}

              {filteredUsers && filteredUsers.rows.map(user => (
                <div key={user.id}>
                  <input type="checkbox" className='mr-[10px]' id={user.id} name={user.id} value={user.id} checked={props.checkedKeywords && props.checkedKeywords.includes(user.id)} onChange={() => handleCheckboxChange(user)}/>
                  <label for="vehicle1" className='align-text-top'><span>{user.user_name}</span>

                  {user.reviewers.length > 0 && user.reviewers[0].review_status && (
                    <i>
                      <span className="text-[12px] text-[#003C71] ml-[10px] font-['Arial']">
                        Reviewed
                      </span>
                    </i>
                  )}
                  </label>
                </div>
              ))}
            </div>
          </div>
          ): null}
        </div>
      </form>
    </div>
  ) ;
};

export default VideoDet;
