import React, { useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from '../button/Button';
import ConfirmedModal from './Confirmed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeleteModal = ({ isOpen, onCancel, onConfirm }) => {
  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const modal = {
    background: '#fff',
    position: 'absolute',
    // width: '30%',
    padding: '20px',
    borderRadius: '6px',
    textAlign: 'center',
  };

  const warning = {
    color: 'red',
    fontSize: '44px',
    marginBottom: '10px',
  };

  const [isHovered, setIsHovered] = useState(false);

  const confirmStyle = {
    width: '180px',
    height: '40px',
    backgroundColor: '#ba0000',
    transition: 'background-color 0.3s ease',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '80px',
    fontFamily: 'Arial, Helvetica, sans-serif',
  };
  const deleteStyle = {
    width: '180px',
    height: '40px',
    backgroundColor: 'lightgray',
    color: 'black',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
    fontFamily: 'Arial, Helvetica, sans-serif',
  };

  const [isConfirmedModalOpen, setConfirmedModalOpen] = useState(false);

  const handleConfirmReminderModal = () => {
    setConfirmedModalOpen(true);
    onConfirm();
  };

  const handleCloseConfirmedModal = () => {
    setConfirmedModalOpen(false);
    onCancel();
  };

  return (
    isOpen && (
      <div style={modalOverlay}>
        <div style={modal} className='w-[35%] 2xl:w-[25%]'>
          <div className='columns-2 flex justify-between mt-5 mx-2 px-2'>
            <div style={warning}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
            <div style={{ textAlign: 'left', marginLeft: '25px' }}>
              <p
                className='w-[100%] text-xl font-light '
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Are you sure you want to delete the selected videos? <br/><br/> This action
                cannot be undone
              </p>
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex justify-between confirm'>
              <Button
                onClick={handleConfirmReminderModal}
                text={'Yes, Delete'}
                style={{ backgroundColor: 'inherit'}}
              />
            </div>
            <Button onClick={onCancel} style={deleteStyle} text={'Cancel'} />

            <ConfirmedModal
              isOpen={isConfirmedModalOpen}
              onClose={handleCloseConfirmedModal}
              content={
                <p
                  className='w-[238.78px] text-xl font-light '
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  The selected videos are now deleted
                </p>
              }
              isPlaylist={true}
              isComplete={true}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default DeleteModal;
