import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAssistiveListeningSystems,
  faGear,
  faL,
} from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/atoms/header/Header';
import { GoX } from 'react-icons/go';
import Avatar from '../../components/atoms/avatar/Avatar';
import '../../pages/review/review.css';
import ReactPlayer from 'react-player';
import DownloadModal from '../../components/atoms/modal/Downloads';
import ActionsModal from '../../components/atoms/modal/Actions';
import ConfirmedModal from '../../components/atoms/modal/Confirmed';
import MarkReviewModal from '../../components/atoms/modal/Markreview';
import VideoDet from '../../components/molecules/video-detail/VideoDetail';
import EditVideoDetailReviewModal from '../../components/atoms/modal/EditVideoDetailReview';
import Keywordlist from '../../components/molecules/keyword/Keywordlist';
import { Popover, ArrowContainer} from 'react-tiny-popover'
import Button from '../../components/atoms/button/Button';
import ReportReviewModal from '../../components/atoms/modal/ReportPreview';
import Transcript from '../../pages/review/TranscriptData.csv';
import papa from 'papaparse';
import { Tooltip } from 'react-tooltip'
import { useNavigate, useParams } from 'react-router-dom';
import { GetTranscriptByVideoID } from '../../axios/get/get_transcript_based_on_video_id';
import { AddTranscriptAuditLog } from '../../axios/post/AddTranscriptAuditLog';
import { Update_Transcript_Data } from '../../axios/put/update_transcript_data';
import axios from 'axios';
import { GetVideoByID } from '../../axios/get/video_by_id';
import ModalTextButton from '../../components/atoms/modal/ModalTextButton';
import { AddTranscriptComment } from '../../axios/post/AddTranscriptComment';
import { GetKeywordListByVideoID } from '../../axios/get/GetKeywordListBasedOnVideoID';
import { Update_Reviewer } from '../../axios/put/update_reviewer';
import { getReviewStatusForVideo } from '../../axios/get/review_status';
import { Update_Video_Status } from '../../axios/put/update_video_status';
import { GetScreenTextByVideoID } from '../../axios/get/GetScreentextBasedOnVideoID';
import debounce from 'lodash/debounce';
import { AddScreenTextComment } from '../../axios/post/AddScreenTextComment';
import { GetVideoWithReviewStatus } from '../../axios/get/videowithreviewstatus';
import { GetTranscriptHighlight } from '../../axios/get/GetTranscriptHighlight';
import { Update_Status_Undo_Reviewer } from '../../axios/put/update_status_undo_reviewer';
// import { GetVideo } from '../../axios/get/getVideo';
import { GetTranscriptByID } from '../../axios/get/GetTranscriptByID';
import { GetAllUsers } from '../../axios/get/users';
import { GetReport } from '../../axios/get/getreportpreview';
import { jsPDF } from "jspdf";
import { AddReviewers } from '../../axios/post/AddReviewer';
import { DeleteReviewers } from '../../axios/delete/Deletereviewers';
import { GetVttDownload } from '../../axios/get/vttdownload';
import {axios as MyAxios} from '../../axios/config.js';
import { GetSSOUser } from '../../axios/get/get_sso_user.js';




function convertTimestampToSeconds(timestamp) {
  const [hours, minutes, seconds] = timestamp.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

function convertSecondsToTimestamp(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

function formatDate(date){
  const dateObject = new Date(date);
    return dateObject.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
}
function VideoPlayer({ videoRef, handleTimeUpdate, onPlay, video_id }) {
  const [videoUrl, setVideoUrl] = useState('');
  const [isVideoLoading, setVideoLoading] = useState(true);

  // const handleVideoReady = () => {
  //   setVideoLoading(false);
  // };

  // const handleVideoBuffer = () => {
  //   setVideoLoading(true);
  // };

  useEffect(() => {
    // try {
    //   const res = await GetVideo(video_id);
    //   setVideoUrl(URL.createObjectURL(res.data));
    // } catch (error) {
    //   console.error('Error fetching video:', error);
    // }
    // const loadvideo = async () => {
    //   const res = await GetVideo(video_id);
    //   setVideoUrl(URL.createObjectURL(res.data));
    // };
    // loadvideo();
    // setVideoUrl(`https://boston-backend.sgeede.com/webhook/download-video/${video_id}`)
    try{
      setVideoUrl(process.env.REACT_APP_BACKEND+`/webhook/download-video/${video_id}`)
    }
    catch(err){
      console.log("error", err)
    }


  }, []);

  return (
    <>
      <div className='border border-black' style={{width:'inherit'}}>
        <div className='video-wrapper' style={{ height: 'inherit', width:'inherit' }}>
          {/* {isVideoLoading &&
          <div className='flex justify-center items-center'  style={{ height: 'inherit', width:'inherit' }}>
            <p>Loading...</p>
          </div>
          } */}
          <ReactPlayer
            className="camera_video_discover"
            ref={videoRef}
            url={videoUrl}
            controls={true}
            width='inherit'
            height='inherit'
            // onReady={handleVideoReady}
            // onBuffer={handleVideoBuffer}
            onTimeUpdate={handleTimeUpdate}
            onPlay={onPlay} // Add this line
          />
        </div>
      </div>
    </>
  );
}

function EditTranscript({ onButtonClick, currentTimestamp, onTimestampClick, isManualHighlighting, video_id, current_user, KeywordLists, isFilterHighlight, isFilterComment}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [commentText, setCommentText] = useState(false);
  const [isEditing, setIsEditing] = useState([]);
  const [TranscriptDatas, setTranscriptDatas] = useState({ rows: [] });
  const [isCheckmarked, setIsCheckmarked] = useState([]);
  const containerRef = useRef(); // Add this line to create the ref
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentFilteredIndex, setCurrentFilteredIndex] = useState(0);
  const [editedText, setEditedText] = useState({});
  const [isEditedText, setisEditedText] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [FilteredTranscript, setFilteredTranscript] = useState(); // Use useState instead of new
  const [debouncedUpdate, setDebouncedUpdate] = useState(null)
  const [highlightedPositions, setHighlightedPositions] = useState([]);
  const [filteredTranscripts, setFilteredTranscripts] = useState({ rows: [] });
  const [filteredIndices, setFilteredIndices] = useState([]);
  const [TimestampClick, setTimestampClick] = useState(false);
  const [editingTranscriptId, setEditingTranscriptId] = useState(null);
  const [checkmarksClickedFor, setCheckmarksClickedFor] = useState([]);

  useEffect(() => {
    setFilteredIndices([]);
    if (KeywordLists && Array.isArray(KeywordLists.rows)) {
      // Extract category IDs from the KeywordLists
      const updatedCategoryIds = KeywordLists.rows.map(keyword => keyword.category_id);

      const TranscriptList = Array.isArray(TranscriptDatas) ? TranscriptDatas : TranscriptDatas.rows;
        
      if (Array.isArray(TranscriptList)) {
        // If TranscriptList is an array, create a shallow copy using the spread operator
          setFilteredTranscript([...TranscriptList]);
      } else {
        // If TranscriptList is not an array, assume it has a 'rows' property and create a shallow copy of the array
        setFilteredTranscript({ rows: [...TranscriptList.rows] });
      }

      // Filter TranscriptDatas based on the updatedCategoryIds
      if(isFilterHighlight && isFilterComment){
        const filteredCommentedTranscriptDatas = TranscriptDatas.rows.filter(transcript => {
          // Assuming each transcript has a highlight property
          return transcript.comments && transcript.comments.length > 0;
        });
        var filteredHighlightTranscriptDatas = []
        if(updatedCategoryIds.length > 0){
          const filteredTranscriptDatas = TranscriptDatas.rows.filter(transcript => {
            // Assuming each transcript has a highlight property
            return transcript.highlight && transcript.highlight.some(highlight => updatedCategoryIds.includes(highlight.psst_category.id));
          });
          filteredHighlightTranscriptDatas = filteredTranscriptDatas
          // console.log(filteredTranscriptDatas)
          // setFilteredTranscript(filteredTranscriptDatas) 
          // // Update the state with the filtered data
          // TranscriptDatas.rows.forEach((transcript, index) => {
          //   const isTranscriptInFilter = FilteredTranscript.some(
          //     filteredTranscript => filteredTranscript.id === transcript.id
          //   );
          
          //   // If the transcript is in filteredTranscripts and the index is not already in the array, store the index
          //   if (isTranscriptInFilter && !filteredIndices.includes(index)) {
          //     setFilteredIndices(prevIndices => [...prevIndices, index]);
          //   }
          // });
          // setFilteredIndices(prevIndices => [...prevIndices].sort((a, b) => a - b));
        }
        var c = filteredHighlightTranscriptDatas.concat(filteredCommentedTranscriptDatas)
        var d = c.filter((item, pos) => c.indexOf(item) === pos)

        var sortedDatas = d.sort((a,b)=> a.id - b.id)


        setFilteredTranscript(sortedDatas) 
        // Update the state with the filtered data
        TranscriptDatas.rows.forEach((transcript, index) => {
          const isTranscriptInFilter = FilteredTranscript.some(
            filteredTranscript => filteredTranscript.id === transcript.id
          );
        
          // If the transcript is in filteredTranscripts and the index is not already in the array, store the index
          if (isTranscriptInFilter && !filteredIndices.includes(index)) {
            setFilteredIndices(prevIndices => [...prevIndices, index]);
          }
        });
        setFilteredIndices(prevIndices => [...prevIndices].sort((a, b) => a - b));
        // if(updatedCategoryIds.length > 0){
        // }
        // else{
        //   setFilteredIndices(Array.from({ length: TranscriptDatas.rows.length }, (_, index) => index));
        // }
      }else if(isFilterComment){
        const filteredTranscriptDatas = TranscriptDatas.rows.filter(transcript => {
          // Assuming each transcript has a highlight property
          return transcript.comments && transcript.comments.length > 0;
        });
        var sortedDatas = filteredTranscriptDatas.sort((a,b)=> a.id - b.id)

        setFilteredTranscript(sortedDatas) 
        // Update the state with the filtered data
        TranscriptDatas.rows.forEach((transcript, index) => {
          const isTranscriptInFilter = FilteredTranscript.some(
            filteredTranscript => filteredTranscript.id === transcript.id
          );
        
          // If the transcript is in filteredTranscripts and the index is not already in the array, store the index
          if (isTranscriptInFilter && !filteredIndices.includes(index)) {
            setFilteredIndices(prevIndices => [...prevIndices, index]);
          }
        });
        setFilteredIndices(prevIndices => [...prevIndices].sort((a, b) => a - b));
      }
      else if(isFilterHighlight){
        if(updatedCategoryIds.length > 0){
          const filteredTranscriptDatas = TranscriptDatas.rows.filter(transcript => {
            // Assuming each transcript has a highlight property
            return transcript.highlight && transcript.highlight.some(highlight => updatedCategoryIds.includes(highlight.psst_category.id));
          });
        var sortedDatas = filteredTranscriptDatas.sort((a,b)=> a.id - b.id)
        setFilteredTranscript(sortedDatas) 
          // Update the state with the filtered data
          TranscriptDatas.rows.forEach((transcript, index) => {
            const isTranscriptInFilter = FilteredTranscript.some(
              filteredTranscript => filteredTranscript.id === transcript.id
            );
          
            // If the transcript is in filteredTranscripts and the index is not already in the array, store the index
            if (isTranscriptInFilter && !filteredIndices.includes(index)) {
              setFilteredIndices(prevIndices => [...prevIndices, index]);
            }
          });
          setFilteredIndices(prevIndices => [...prevIndices].sort((a, b) => a - b));
        }
        else{
          setFilteredIndices(Array.from({ length: TranscriptDatas.rows.length }, (_, index) => index));
        }
        
      }
      else{
        if(updatedCategoryIds.length > 0){
          const filteredTranscriptDatas = TranscriptDatas.rows.filter(transcript => {
            // Assuming each transcript has a highlight property
            return transcript.highlight && transcript.highlight.some(highlight => updatedCategoryIds.includes(highlight.psst_category.id));
          });
          // Update the state with the filtered data
          TranscriptDatas.rows.forEach((transcript, index) => {
            const isTranscriptInFilter = filteredTranscriptDatas.some(
              filteredTranscript => filteredTranscript.id === transcript.id
            );
          
            // If the transcript is in filteredTranscripts and the index is not already in the array, store the index
            if (isTranscriptInFilter && !filteredIndices.includes(index)) {
              setFilteredIndices(prevIndices => [...prevIndices, index]);
            }
          });
          setFilteredIndices(prevIndices => [...prevIndices].sort((a, b) => a - b));
        }
        else{
          setFilteredIndices(Array.from({ length: TranscriptDatas.rows.length }, (_, index) => index));
        }
      }

      // if(isFilterComment){
        
      //   // if(updatedCategoryIds.length > 0){
      //   // }
      //   // else{
      //   //   setFilteredIndices(Array.from({ length: TranscriptDatas.rows.length }, (_, index) => index));
      //   // }
      // }
      
    } else {
      console.error('KeywordLists.rows is not an array or KeywordLists is falsy');
    }
  }, [KeywordLists, TranscriptDatas, isFilterHighlight, isFilterComment]);

  useEffect(() => {
    // Fetch data from your API or backend
    GetTranscriptByVideoID(video_id)  // Update the endpoint
    .then(response => {
      setTranscriptDatas(response.data);
    })
    .catch(error => {
      console.error('Error fetching transcript:', error);
    });
  }, [KeywordLists, isFilterHighlight, isFilterComment]);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const highlightedRow = container.querySelector('.highlited');
  
      // Check if a highlighted row is found
      if (highlightedRow && !TimestampClick) {
        // Calculate the offset based on the container height
        const offset = container.offsetHeight - highlightedRow.offsetHeight;
        container.scrollTop = highlightedRow.offsetTop - offset;
      }
      setTimestampClick(false)
    }
  }, [currentTimestamp]);
  
  // Reset manual highlighting state when isManualHighlighting changes


  const togglePopover = (key) => {
    setIsPopoverOpen((prevstates) => ({
      [key]: !prevstates[key]
    }));
  };

  const HandleCommentOnchange = debounce((key, comment) => {
    setCommentText((prevstates) => ({ ...prevstates, [key]: comment}));
  }, 300);

  const handleToggleEdit = (key) => {
    // Check if the user has clicked the checkmarks for the current transcript
    if (checkmarksClickedFor.includes(key) && editingTranscriptId) {
      setCheckmarksClickedFor((prevCheckmarks) => prevCheckmarks.filter(id => id !== key));
      // Allow editing only for the transcript with the clicked checkmarks
      setIsEditing((prevStates) => ({
        ...prevStates,
        [key]: !prevStates[key]
      }));

      // Set the editing transcript ID when starting to edit
      setEditingTranscriptId((prevId) => (prevId === key ? null : key));
    }
    else if(editingTranscriptId === null){
      setIsEditing((prevStates) => ({
        ...prevStates,
        [key]: !prevStates[key]
      }));

      // Set the editing transcript ID when starting to edit
      setEditingTranscriptId((prevId) => (prevId === key ? null : key));
    } 
    else {
      // Display a message or perform any other action to inform the user
      console.log("Please click the checkmarks for the current transcript first.");
      // Alternatively, you can show a notification or disable editing in a different way.
    }
  };

  const handleCheckmarkClick = async (key, user, is_filter) => {
    try {
      setIsCheckmarked((prevStates) => ({
        ...prevStates,
        [key]: true,
      }));
      let original_text = ''
      if(is_filter){
        original_text = TranscriptDatas.rows.find((t) => t.id === key)?.transcript_text;
      }
      else{
        original_text = TranscriptDatas.rows.find((t) => t.id === key)?.transcript_text;
      }
      if (original_text !== editedText[key]) {
        await AddTranscriptAuditLog(key, original_text, editedText[key], user);
        await Update_Transcript_Data(key, editedText[key])
        await GetTranscriptByVideoID(video_id)
        .then((response) => {
            setTranscriptDatas(response.data);
        })
        .catch((error) => {
          console.error('Error fetching transcript:', error);
        });
      } else {
        console.error("No changes to transcript.");
      }
      setCheckmarksClickedFor((prevCheckmarks) => [...prevCheckmarks, key]);
      setEditingTranscriptId(null)
      
    } catch (error) {
      console.error('Error creating transcript audit log:', error);
    }

  };

  const handleCheckmarkRevert = async (key) => {
    // // Create a copy of the state object
    // const updatedEditedText = { ...editedText };

    // // Remove the key from the copied object
    // delete updatedEditedText[key];

    // // Update the state with the modified object
    // setEditedText(updatedEditedText);
    setisEditedText((prev) => ({...prev, [key]: true}));

    const transcript =  await GetTranscriptByID(key)
    .then((response) => {
        setEditedText((prev) => ({ ...prev, [key]: response.data.transcript_text }));
      })
      .catch((error) => {
        console.error('Error fetching transcript:', error);
      });

    // Other logic...
    setCheckmarksClickedFor((prevCheckmarks) => [...prevCheckmarks, key]);
    setEditingTranscriptId(null);

    // const transcript =  await GetTranscriptByVideoID(video_id)
    // .then((response) => {
    //     setTranscriptDatas(response.data);
    // })
    // .catch((error) => {
    //   console.error('Error fetching transcript:', error);
    // });
    
  };

  const handleTimestampClick = (timestamp, index, ismanual) => {
    // Call the callback function to handle timestamp click
    if (onTimestampClick) {
      onTimestampClick(timestamp);
    }
    if (ismanual){
      setTimestampClick(true);
    }
    setCurrentIndex(index);
  };

  const handleNextTimestamp = () => {
    if (filteredIndices.length > 0 && currentFilteredIndex < filteredIndices.length - 1) {
      // Increment the current index to get the next filtered transcript index
      const nextIndex = currentFilteredIndex + 1;
  
      // Get the next transcript based on the filtered index
      const nextTranscriptIndex = filteredIndices[nextIndex];
      const nextTranscript = TranscriptDatas.rows[nextTranscriptIndex];
  
      // Use the nextTranscript's start_time and index in your logic
      const nextTimestamp = nextTranscript.start_time;
      const Secondsnexttimestamp = convertTimestampToSeconds(nextTimestamp)
      const actualnexttimestamp = convertSecondsToTimestamp(Secondsnexttimestamp + 0.001)
      setCurrentFilteredIndex(nextIndex)
      handleTimestampClick(actualnexttimestamp, nextTranscriptIndex, false);
    }
  }
  const handlePreviousTimestamp = () => {
    if (filteredIndices.length > 0 && currentFilteredIndex > 0) {
      // Decrement the current index to get the previous filtered transcript index
      const prevIndex = currentFilteredIndex - 1;
  
      // Get the previous transcript based on the filtered index
      const prevTranscriptIndex = filteredIndices[prevIndex];
      const prevTranscript = TranscriptDatas.rows[prevTranscriptIndex];
  
      // Use the prevTranscript's start_time and index in your logic
      const prevTimestamp = prevTranscript.start_time;
      const Secondsprevtimestamp = convertTimestampToSeconds(prevTimestamp)
      const actualprevtimestamp = convertSecondsToTimestamp(Secondsprevtimestamp + 0.001)
      setCurrentFilteredIndex(prevIndex)
      handleTimestampClick(actualprevtimestamp, prevTranscriptIndex, false);
    }
  };


  const handleSearch = (event) => {
    const search = event.target.value;
    setSearchTerm(search);
    const TranscriptList = Array.isArray(TranscriptDatas) ? TranscriptDatas : TranscriptDatas.rows;
    const updatedList = TranscriptList.filter((item) => {
      return item.transcript_text.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    if (Array.isArray(TranscriptList)) {
      setFilteredTranscript(updatedList);
    } else {
      setFilteredTranscript({ rows: updatedList });
    }
  };

  const handleCommentSubmit = async (key) => {
    // await AddTranscriptComment(key, current_user, commentText[key])
    try {
      const textarea = document.getElementById(`${key}ta`).value = '';

      await AddTranscriptComment(
        key,
        current_user,
        commentText[key]
      );
      const updatedTranscriptData = await GetTranscriptByVideoID(video_id);
      setTranscriptDatas(updatedTranscriptData.data)

      const TranscriptList = Array.isArray(updatedTranscriptData) ? updatedTranscriptData.data : updatedTranscriptData.data.rows;
      // const updatedList = TranscriptList.filter((item) => {
      //   return item.transcript_text.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      // });
  
      if (Array.isArray(TranscriptList)) {
        setFilteredTranscript(TranscriptList);
      } else {
        setFilteredTranscript({ rows: TranscriptList });
      }

    } catch (error) {
      console.error('Error submitting comment:', error);
      // Handle the error (e.g., show a user-friendly message)
    }
  };

  // const boldSearchTerm = (text, searchTerm, Keywords) => {
  //   // Check if searchTerm is provided
  //   if (searchTerm) {
  //     // Split the text into parts using searchTerm as a delimiter
  //     const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  
  //     // Map over the parts array
  //     return parts.map((part, index) => {
  //       // Check if the current part is the searchTerm
  //       const isSearchTerm = part.toLowerCase() === searchTerm.toLowerCase();
  
  //       // Return a span with the appropriate styling
  //       return (
  //         <span key={index} style={{
  //           fontWeight: isSearchTerm ? 'bold' : 'normal',
  //           backgroundColor: Keywords.some(keyword => part.toLowerCase().includes(keyword.toLowerCase())) ? 'yellow' : 'transparent'
  //         }}>
  //           {isSearchTerm ? <strong>{part}</strong> : part}
  //         </span>
  //       );
  //     });
  //   }
  
  //   // If searchTerm is not provided, check if any keyword matches any part of the text
  //   if (Keywords.length > 0) {
  //     const isKeywordFound = Keywords.some(keyword => new RegExp(`\\b${keyword}\\b`, 'i').test(text));
  
  //     if (isKeywordFound) {
  //       return (
  //         <span style={{ backgroundColor: 'yellow' }}>
  //           <strong>{text}</strong>
  //         </span>
  //       );
  //     }
  //   }
  
  //   // If no searchTerm or keyword match, return the original text
  //   return <>{text}</>;
  // };

  const boldSearchTerm = (text, searchTerm, Keywords) => {
    // Helper function to wrap the matched text with a tag
    const wrapWithTag = (matchedText, tag) => {
      return `<${tag}>${matchedText}</${tag}>`;
    };
  
    // Check if searchTerm is provided
    if (searchTerm) {
      // Highlight the search term by wrapping it in a <strong> tag
      text = text.replace(new RegExp(`\\b(${searchTerm})\\b`, 'gi'), (match, p1) => {
        return wrapWithTag(p1, 'strong');
      });
    }
    // Loop through each keyword and highlight its occurrences with the corresponding color
    Keywords.forEach(keyword => {
      const categoryColor = keyword.psst_category.color;
      const keywordRegex = new RegExp(`\\b(${keyword.highlighted_text})\\b`, 'gi');
      text = text.replace(keywordRegex, (match, p1) => {
        return wrapWithTag(p1, 'span style="background-color: ' + categoryColor + ';"');
      });
    });
  
    // Return the formatted text
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  };

  const highlightKeywords = (text, keywords, index) => {
    if (!keywords.length) {
      return <>{text}</>;
    }
    
    // Memoization to cache results
    const memoizedParts = new Map();
  
    const memoizedHighlight = (part, keywordColors) => {
      if (!memoizedParts.has(part)) {
        // Create a regular expression pattern for all keywords
        const pattern = new RegExp(`(${keywords.map(keyword => `\\b${keyword.highlighted_text}\\b`).join('|')})`, 'gi');
    
        // Split the text into parts using the regex pattern
        const parts = part.split(pattern);
        
        memoizedParts.set(part, parts.map((p, index) => {
          const isKeyword = keywords.some(keyword => new RegExp(`\\b${keyword.highlighted_text}\\b`, 'i').test(p));
          const backgroundColor = isKeyword ? keywordColors[p.toLowerCase()] || 'yellow' : 'transparent';
        
          return isKeyword ? (
            <span key={index} style={{ fontWeight: 'bold', backgroundColor }}>{p}</span>
          ) : (
            <span key={index}>{p}</span>
          );
        }));
      }
      return memoizedParts.get(part);
    };
    
    // Map each keyword to its corresponding category color
    const keywordColors = {};
    keywords.forEach(keyword => {
      if (
        keyword &&
        keyword.psst_category &&
        keyword.psst_category.color
      ) {
        const categoryColor = keyword.psst_category.color;
        keywordColors[keyword.highlighted_text.toLowerCase()] = categoryColor;
      }
    });

    const result = memoizedHighlight(text, keywordColors)
    // if (result.length > 1) {
    //   // Use the functional form of setHighlightedPositions to access the previous state
    //   setHighlightedPositions(prev => [...prev, index]);
    // }
    // Apply memoized highlighting to the entire text
    return <>{result}</>;
  };  

  const handleInputDebounced = debounce((transcriptId, content) => {
    setisEditedText((prev) =>({...prev, [transcriptId]: false}));
    setEditedText((prev) => ({ ...prev, [transcriptId]: content }));
    // ... (any additional logic you want to perform on input)
    // ... (any additional logic you want to perform on input)
  }, 300); // Adjust the debounce delay as needed

  const highlightKeywordsMemoized = useMemo(() => {
    return (text, keywords, index) => highlightKeywords(text, keywords, index);
  }, []);

  return (
    <div className='h-[100%] mb-[20px] p-[10px]'>
      <div className='text-right'>
        
      </div>

      <div>
        {/* <i class="fa fa-assistive-listening-systems" aria-hidden="true"></i> */}
        <table className='w-[100%]'>
          <tr>
            <td className='w-[7%] text-[#003C71]'>
              <FontAwesomeIcon icon={faAssistiveListeningSystems} />
            </td>
            <td className='flex items-center'>
              <strong>
                <span className='text-[#003C71]'>Edit Transcript</span> 
              </strong>
              <svg className='transcript_info ml-[5px]' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.71484 12.1641H9.29818V7.41409H7.71484V12.1641ZM8.50651 5.83076C8.73082 5.83076 8.91897 5.75476 9.07097 5.60276C9.22297 5.45076 9.29871 5.26287 9.29818 5.03909C9.29818 4.81479 9.22218 4.6269 9.07018 4.47543C8.91818 4.32395 8.73029 4.24795 8.50651 4.24743C8.28221 4.24743 8.09432 4.32343 7.94284 4.47543C7.79137 4.62743 7.71537 4.81532 7.71484 5.03909C7.71484 5.2634 7.79084 5.45155 7.94284 5.60355C8.09484 5.75555 8.28273 5.83129 8.50651 5.83076ZM8.50651 16.1224C7.41137 16.1224 6.38221 15.9145 5.41901 15.4986C4.45582 15.0827 3.61797 14.5188 2.90547 13.8068C2.19297 13.0943 1.62904 12.2565 1.21368 11.2933C0.798316 10.3301 0.590372 9.3009 0.589844 8.20576C0.589844 7.11062 0.797788 6.08145 1.21368 5.11826C1.62957 4.15507 2.1935 3.31722 2.90547 2.60472C3.61797 1.89222 4.45582 1.32829 5.41901 0.912926C6.38221 0.497565 7.41137 0.289621 8.50651 0.289093C9.60165 0.289093 10.6308 0.497038 11.594 0.912926C12.5572 1.32882 13.3951 1.89275 14.1076 2.60472C14.8201 3.31722 15.3842 4.15507 15.8001 5.11826C16.216 6.08145 16.4237 7.11062 16.4232 8.20576C16.4232 9.3009 16.2152 10.3301 15.7993 11.2933C15.3835 12.2565 14.8195 13.0943 14.1076 13.8068C13.3951 14.5193 12.5572 15.0835 11.594 15.4994C10.6308 15.9153 9.60165 16.123 8.50651 16.1224Z" fill="#003C71"/>
              </svg>
              <Tooltip anchorSelect=".transcript_info" place="right" style={{backgroundColor:"#FFFDF0", color:"black", fontSize:"12px"}}>
              <p>Double-click on a transcript line to edit the text.</p>
              <div className='flex'><span className='mr-[5px]'>Click </span> <span><svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.6959 10.0522H10.1959V10.5522V12.3869L6.58547 10.1283L6.46381 10.0522H6.3203H1.94471C1.05987 10.0522 0.5 9.46729 0.5 8.92877V1.62341C0.5 1.0849 1.05987 0.5 1.94471 0.5H13.6129C14.4978 0.5 15.0576 1.0849 15.0576 1.62341V8.92877C15.0576 9.46729 14.4978 10.0522 13.6129 10.0522H10.6959ZM9.73736 9.83463H13.6129C14.0681 9.83463 14.5991 9.50889 14.5991 8.92877V1.62341C14.5991 1.0433 14.0681 0.71756 13.6129 0.71756H1.94471C1.48952 0.71756 0.95853 1.0433 0.95853 1.62341V8.92877C0.95853 9.50889 1.48952 9.83463 1.94471 9.83463H6.66286L8.97202 11.2804L9.73736 11.7595V10.8566V9.83463Z" fill="#1B1B1B" stroke="black"/>
              </svg></span><span className='ml-[5px]'>to add a comment</span></div>
              <p>To browse the transcript, pause the video first</p>

              </Tooltip>
            </td>
          </tr>
          <br />
          <tr>
            <td></td>
            <td><input className="border border-[#D9D9D9] rounded-[8px] p-[3px] pl-[20px] w-[90%]" placeholder={"Search"} onChange={handleSearch}/></td>
            <td></td>
          </tr>
        </table>
      </div>
      <div className='min-[1294px]:flex'>
        <div className='2xl:h-[600px] min-[1294px]:h-[450px] overflow-x-hidden overflow-scroll scrollbar font-roboto_mono text-[#797979] w-[500px]' ref={containerRef}>
          <table className='w-[100%]'>
            <tbody>
              {/* {!FilteredTranscript && TranscriptDatas.rows.length ? (
                TranscriptDatas.rows.map((transcript, index) => (
                  <tr key={transcript.id} className={`${
                    isManualHighlighting
                      ? currentTimestamp >= convertTimestampToSeconds(transcript.start_time) &&
                        currentTimestamp <= convertTimestampToSeconds(transcript.end_time)
                        ? 'highlited text-[#000000]'
                        : ''
                      : currentTimestamp >= convertTimestampToSeconds(transcript.start_time) &&
                        currentTimestamp <= convertTimestampToSeconds(transcript.end_time)
                        ? 'highlited text-[#000000]'
                        : ''
                  }`}
                  >
                    <td className='w-[7%] py-[8px]'>
                    <Popover
                    isOpen={isPopoverOpen[transcript.id] || false}
                    positions={['left']} // preferred positions by priority
                    padding={15}
                    content={({ position, childRect, popoverRect }) => (
                      <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                          position={position}
                          childRect={childRect}
                          popoverRect={popoverRect}
                          arrowColor={'#ffffff'}
                          arrowSize={20}
                          arrowStyle={{ opacity: 1}}
                          className='popover-arrow-container'
                          arrowClassName='popover-arrow'
                        >
                          <div className={`bg-white p-[18px] rounded ${transcript.comments && transcript.comments.length > 0 ? 'h-[200px]' : ''}`} style={{boxShadow: '0px 4px 12px 0px #71090940'}}>
                            {transcript.comments && transcript.comments.length > 0 && (
                              <div className='h-[40%] scrollbar overflow-auto mb-[10px]'>
                                {transcript.comments.map((comment) => (
                                  <div className='flex flex-col' key={comment.id}>
                                    <div className='flex justify-between items-center text-[9px] text-[#878787] font-[Arial]'>
                                      <span><i>{comment?.psst_user?.user_name || 'Loading...'}</i></span>
                                      <span>{comment.createdAt.split('T')[0]}</span>
                                    </div>
                                    <div className='m-[0px] p-[0px] text-[12px] font-[Arial]'>
                                      <button>
                                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M9.73859 1.96742L11.7256 3.9544C11.8093 4.03811 11.8093 4.17468 11.7256 4.25839L6.91452 9.06944L4.87027 9.29633C4.59711 9.32717 4.36581 9.09587 4.39665 8.82272L4.62355 6.77847L9.43459 1.96742C9.5183 1.88371 9.65488 1.88371 9.73859 1.96742ZM13.3072 1.46296L12.2322 0.387967C11.8974 0.0531321 11.3533 0.0531321 11.0162 0.387967L10.2364 1.16778C10.1527 1.25149 10.1527 1.38807 10.2364 1.47177L12.2234 3.45875C12.3071 3.54246 12.4437 3.54246 12.5274 3.45875L13.3072 2.67894C13.6421 2.3419 13.6421 1.7978 13.3072 1.46296ZM9.32886 7.76094V10.0035H2.2797V2.9543H7.34188C7.41237 2.9543 7.47845 2.92566 7.52912 2.8772L8.41026 1.99606C8.57768 1.82864 8.45873 1.54447 8.22302 1.54447H1.92725C1.34349 1.54447 0.869873 2.01808 0.869873 2.60184V10.3559C0.869873 10.9397 1.34349 11.4133 1.92725 11.4133H9.68131C10.2651 11.4133 10.7387 10.9397 10.7387 10.3559V6.8798C10.7387 6.64409 10.4545 6.52734 10.2871 6.69255L9.40596 7.5737C9.35749 7.62436 9.32886 7.69045 9.32886 7.76094Z" fill="#CECACA"/>
                                        </svg>
                                      </button>
                                      <span>{comment.comment_text}</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            <textarea className={`border scrollbar text-[12px] ${transcript.comments && transcript.comments.length > 0 ? 'h-[35%]' : ''}`} style={{resize:'none'}} onChange={(e) => HandleCommentOnchange(transcript.id, e.target.value)}></textarea>
                            <div
                              style={{ 
                                background: '#ffffff', 
                                boxShadow: '0px 4px 12px 0px #71090940',
                              }}
                              onClick={() => togglePopover(transcript.id)}
                            >
                            </div>

                            <div className='flex justify-end pt-[5px] text-[13px]'>
                              <Button text={'Comment'} class={'justify-center w-[70px] mr-[10px] bg-[#003c71] text-white p-[2px] cursor-pointer'} onClick={(e) => handleCommentSubmit(transcript.id)}/>
                              <Button text={'Cancel'} class={'justify-center w-[70px] bg-[#D9D9D9] p-[2px] cursor-pointer'} onClick={() => togglePopover(transcript.id)}/>
                            </div>
                          </div>
                        </ArrowContainer>
                      )}
                    >
                   
                    <div onClick={() => togglePopover(transcript.id)}>
                    <svg
                        width='17'
                        height='14'
                        viewBox='0 0 17 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        {' '}
                        <path
                          d='M2.93103 0.121277H14.5993C15.6719 0.121277 16.544 0.849276 16.544 1.74469V9.05005C16.544 9.94546 15.6719 10.6735 14.5993 10.6735H11.6822V12.8042C11.6822 12.9843 11.506 13.1086 11.3176 13.1086C11.2447 13.1086 11.1687 13.0908 11.1018 13.0477L7.30662 10.6735H2.93103C1.85841 10.6735 0.986328 9.94546 0.986328 9.05005V1.74469C0.986328 0.849276 1.85841 0.121277 2.93103 0.121277ZM2.44486 9.05005C2.44486 9.27327 2.66364 9.4559 2.93103 9.4559H7.7928L10.2237 10.9779V9.4559H14.5993C14.8667 9.4559 15.0854 9.27327 15.0854 9.05005V1.74469C15.0854 1.52147 14.8667 1.33884 14.5993 1.33884H2.93103C2.66364 1.33884 2.44486 1.52147 2.44486 1.74469V9.05005Z'
                          fill='#EAE9E9'
                        />
                      </svg>
                    </div>
                 </Popover> </td>
                  <td className='w-[20%] py-[8px] align-baseline cursor-pointer' onClick={() => handleTimestampClick(transcript.start_time)}>{transcript.start_time}</td>
                  <td className={`cursor-pointer py-[8px] ${transcript.is_changed ? 'text-green-500' : ''}`} 
                  onClick={() => handleToggleEdit(transcript.id)} >
                    <div
                    contentEditable={true} 
                    suppressContentEditableWarning={true}
                    onInput={(e) => handleInputDebounced(transcript.id, e.target.textContent)}>
                    {highlightKeywordsMemoized(transcript.transcript_text, KeywordLists.rows, index)}
                    </div>
                    {isEditing[transcript.id] && (
                      <div className="flex flex-row-reverse edit-controls">
                        <button onClick={() => handleCheckmarkRevert(transcript.id)} >
                        <svg style={{marginLeft:'10px', marginRight:'10px'}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.24652 0.388245L5.06427 3.56975L1.88277 0.388245L0.822266 1.44874L4.00377 4.63024L0.822266 7.81175L1.88277 8.87224L5.06427 5.69074L8.24652 8.87224L9.30702 7.81175L6.12552 4.63024L9.30702 1.44874L8.24652 0.388245Z" fill="#E00E0E"/>
                        </svg>
                        </button>
                        <button onClick={() => handleCheckmarkClick(transcript.id, current_user, false)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.8525 1.88025L4.85254 10.8803L0.727539 6.75525L1.78504 5.69775L4.85254 8.75775L12.795 0.822754L13.8525 1.88025Z" fill="#0B8800"/>
                        </svg>
                        </button>
                      </div>
                    )}
                  </td>
                  </tr>
                ))
              ) : null} */}
              {FilteredTranscript && FilteredTranscript.length ? (
                FilteredTranscript.map((transcript, index) => (
                  <tr key={transcript.id} className={`${
                    isManualHighlighting
                      ? currentTimestamp > convertTimestampToSeconds(transcript.start_time) &&
                        currentTimestamp < convertTimestampToSeconds(transcript.end_time)
                        ? 'highlited text-[#000000] text-[14px]'
                        : ''
                      : currentTimestamp > convertTimestampToSeconds(transcript.start_time) &&
                        currentTimestamp < convertTimestampToSeconds(transcript.end_time)
                        ? 'highlited text-[#000000] text-[14px]'
                        : ''
                  }`}
                  >
                    <td className='w-[7%] py-[8px]'>
                    <Popover
                    isOpen={isPopoverOpen[transcript.id] || false}
                    positions={['left']} // preferred positions by priority
                    padding={15}
                    content={({ position, childRect, popoverRect }) => (
                      <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                          position={position}
                          childRect={childRect}
                          popoverRect={popoverRect}
                          arrowColor={'#ffffff'}
                          arrowSize={20}
                          arrowStyle={{ opacity: 1}}
                          className='popover-arrow-container'
                          arrowClassName='popover-arrow'
                        >            
                          <div className={`bg-white p-[18px] rounded ${transcript.comments && transcript.comments.length > 0 ? 'h-[230px]' : ''}`} style={{boxShadow: '0px 4px 12px 0px #71090940'}}>
                            <div className='flex flex-row-reverse'>
                              <button onClick={() => togglePopover(transcript.id)}>
                                <GoX />
                              </button>
                            </div>
                            {transcript.comments && transcript.comments.length > 0 && (
                              <div className='h-[70px] scrollbar overflow-y-auto mb-[10px] w-[180px]'>
                                {transcript.comments.map((comment) => (
                                  <div className='flex flex-col' key={comment.id}>
                                    <div className='flex justify-between items-center text-[9px] text-[#878787] font-[Arial]'>
                                      <span><i>{comment?.psst_user?.user_name || 'Loading...'}</i></span>
                                      <span>{comment.createdAt.split('T')[0]}</span>
                                    </div>
                                    <div className='m-[0px] p-[0px] text-[12px] font-[Arial] break-all'>
                                      <button>
                                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M9.73859 1.96742L11.7256 3.9544C11.8093 4.03811 11.8093 4.17468 11.7256 4.25839L6.91452 9.06944L4.87027 9.29633C4.59711 9.32717 4.36581 9.09587 4.39665 8.82272L4.62355 6.77847L9.43459 1.96742C9.5183 1.88371 9.65488 1.88371 9.73859 1.96742ZM13.3072 1.46296L12.2322 0.387967C11.8974 0.0531321 11.3533 0.0531321 11.0162 0.387967L10.2364 1.16778C10.1527 1.25149 10.1527 1.38807 10.2364 1.47177L12.2234 3.45875C12.3071 3.54246 12.4437 3.54246 12.5274 3.45875L13.3072 2.67894C13.6421 2.3419 13.6421 1.7978 13.3072 1.46296ZM9.32886 7.76094V10.0035H2.2797V2.9543H7.34188C7.41237 2.9543 7.47845 2.92566 7.52912 2.8772L8.41026 1.99606C8.57768 1.82864 8.45873 1.54447 8.22302 1.54447H1.92725C1.34349 1.54447 0.869873 2.01808 0.869873 2.60184V10.3559C0.869873 10.9397 1.34349 11.4133 1.92725 11.4133H9.68131C10.2651 11.4133 10.7387 10.9397 10.7387 10.3559V6.8798C10.7387 6.64409 10.4545 6.52734 10.2871 6.69255L9.40596 7.5737C9.35749 7.62436 9.32886 7.69045 9.32886 7.76094Z" fill="#CECACA"/>
                                        </svg>
                                      </button>
                                      <span>{comment.comment_text}</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            <textarea id={`${transcript.id}ta`}className={`border scrollbar text-[12px] ${transcript.comments && transcript.comments.length > 0 ? 'h-[35%]' : ''}`} style={{resize:'none'}} onChange={(e) => HandleCommentOnchange(transcript.id, e.target.value)}></textarea>
                            <div
                              style={{ 
                                background: '#ffffff', 
                                boxShadow: '0px 4px 12px 0px #71090940',
                              }}
                              onClick={() => togglePopover(transcript.id)}
                            >
                            </div>

                            <div className='flex justify-end pt-[5px] text-[13px] my-[auto]'>
                              <button className={'justify-center w-[70px] mr-[10px] bg-[#003c71] text-white p-[2px] cursor-pointer'} onClick={(e) => handleCommentSubmit(transcript.id)}>Comment</button>
                              <button className={'justify-center w-[70px] bg-gray-400 p-[2px] cursor-pointer'} style={{backgroundColor: 'darkgray'}} onClick={() => togglePopover(transcript.id)}>Cancel</button>
                            </div>
                          </div>
                        </ArrowContainer>
                      )}
                  >
                    <div onClick={() => togglePopover(transcript.id)}>
                      {transcript.comments && transcript.comments.length > 0 ?
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.32125 0.630737H13.9961C15.0693 0.630737 15.9419 1.35939 15.9419 2.2556V9.56747C15.9419 10.4637 15.0693 11.1923 13.9961 11.1923H11.0774V13.325C11.0774 13.5052 10.901 13.6296 10.7125 13.6296C10.6396 13.6296 10.5636 13.6118 10.4967 13.5687L6.69931 11.1923H2.32125C1.24801 11.1923 0.375439 10.4637 0.375439 9.56747V2.2556C0.375439 1.35939 1.24801 0.630737 2.32125 0.630737ZM2.07752 10.3456C2.07752 10.569 2.0537 9.97368 2.32125 9.97368H7.18576L7.57492 10.2174L9.61802 11.497V9.97368H13.9961C14.2636 9.97368 14.4825 9.79089 14.4825 9.56747L14.6193 10.2174C14.6193 9.99399 12.204 10.2174 11.9365 10.2174L9.73095 11.8007C9.4634 11.8007 7.69003 10.2804 7.69003 10.5038L2.07752 10.3456Z" fill="#003C71"/>
                        </svg>
                        : <svg
                        width='17'
                        height='14'
                        viewBox='0 0 17 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        {' '}
                        <path
                          d='M2.93103 0.121277H14.5993C15.6719 0.121277 16.544 0.849276 16.544 1.74469V9.05005C16.544 9.94546 15.6719 10.6735 14.5993 10.6735H11.6822V12.8042C11.6822 12.9843 11.506 13.1086 11.3176 13.1086C11.2447 13.1086 11.1687 13.0908 11.1018 13.0477L7.30662 10.6735H2.93103C1.85841 10.6735 0.986328 9.94546 0.986328 9.05005V1.74469C0.986328 0.849276 1.85841 0.121277 2.93103 0.121277ZM2.44486 9.05005C2.44486 9.27327 2.66364 9.4559 2.93103 9.4559H7.7928L10.2237 10.9779V9.4559H14.5993C14.8667 9.4559 15.0854 9.27327 15.0854 9.05005V1.74469C15.0854 1.52147 14.8667 1.33884 14.5993 1.33884H2.93103C2.66364 1.33884 2.44486 1.52147 2.44486 1.74469V9.05005Z'
                          fill='#EAE9E9'
                        />
                      </svg>
                        }
                    </div>
                  </Popover></td>
                  <td className='w-[20%] py-[8px] align-baseline cursor-pointer' onClick={() => handleTimestampClick(transcript.start_time, index, true)}>{transcript.start_time}</td>
                    <td

                      className={`cursor-pointer py-[8px] ${transcript.is_changed ? 'text-green-500' : ''}`}
                      onClick={() => handleTimestampClick(transcript.start_time, index, true)}
                      onDoubleClick={() => handleToggleEdit(transcript.id)}
                      contentEditable={editingTranscriptId === transcript.id}
                      suppressContentEditableWarning={true}
                      onInput={(e) => handleInputDebounced(transcript.id, e.target.textContent)}
                    >
                      {
                        isEditedText[transcript.id] && editedText && editedText[transcript.id] ?
                          boldSearchTerm(editedText[transcript.id], searchTerm, KeywordLists.rows)
                          : boldSearchTerm(transcript.transcript_text, searchTerm, KeywordLists.rows)
                      }
                      {/* {boldSearchTerm(transcript.transcript_text, searchTerm, KeywordLists.rows)} */}
                      {editingTranscriptId === transcript.id && (
                        <div className="flex flex-row-reverse edit-controls">
                          <button onClick={() => handleCheckmarkRevert(transcript.id)}>
                            <svg style={{ marginLeft: '10px', marginRight: '10px' }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.24652 0.388245L5.06427 3.56975L1.88277 0.388245L0.822266 1.44874L4.00377 4.63024L0.822266 7.81175L1.88277 8.87224L5.06427 5.69074L8.24652 8.87224L9.30702 7.81175L6.12552 4.63024L9.30702 1.44874L8.24652 0.388245Z" fill="#E00E0E" />
                            </svg>
                          </button>
                          <button onClick={() => handleCheckmarkClick(transcript.id, current_user, true)}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.8525 1.88025L4.85254 10.8803L0.727539 6.75525L1.78504 5.69775L4.85254 8.75775L12.795 0.822754L13.8525 1.88025Z" fill="#0B8800" />
                            </svg>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : null}
              </tbody>
          </table>
        </div>
        <div class='flex flex-col ml-[5px]'>
          <button onClick={handlePreviousTimestamp}>
            <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.1494 8.73552C16.4015 9.04651 16.2263 9.43436 15.7582 9.60181C15.2901 9.76926 14.7064 9.65291 14.4543 9.34192L8.56356 2.05322L2.67279 9.34192C2.42078 9.65291 1.83705 9.76925 1.36883 9.60181C0.900813 9.43436 0.725616 9.04651 0.97782 8.73552L7.71602 0.421722C7.88392 0.214515 8.20944 0.0853306 8.56356 0.0853307C8.91771 0.0853307 9.24322 0.214515 9.41112 0.421722L16.1494 8.73552Z" fill="#929292"/>
            </svg>
          </button>
          <button className='mt-[10px]' onClick={handleNextTimestamp}>
            <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.1494 1.28542C16.4015 0.974425 16.2263 0.586576 15.7582 0.419121C15.2901 0.251679 14.7063 0.368022 14.4543 0.679013L8.56353 7.96774L2.67274 0.679013C2.42072 0.368022 1.83699 0.251679 1.36878 0.41912C0.900755 0.586575 0.725557 0.974425 0.977762 1.28542L7.71599 9.59925C7.88389 9.80646 8.20941 9.93564 8.56353 9.93564C8.91768 9.93564 9.2432 9.80646 9.4111 9.59925L16.1494 1.28542Z" fill="#929292"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

function EditScreenText({videoid, currentTimestamp, current_user, chosenCategory, OnScreentexttimestampclick, isFilterHighlight, isFilterComment}) {
  const [ScreenTextDatas, setScreenTextDatas] = useState({ rows: [] });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [commentText, setCommentText] = useState(false);
  const [TimestampClick, setTimestampClick] = useState(false);
  const containerRef = useRef(); // Add this line to create the ref
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch data from your API or backend
    GetScreenTextByVideoID(videoid)  // Update the endpoint
      .then(response => {
        // Filter the data based on the conditions
        const filteredData = response.data.rows.filter(item => {
          const hasHighlight = item.screen_text_highlight_text.length > 0;
          const hasComment = item.comments.length > 0;
          const matchesCategory = item.screen_text_highlight_text.some(highlight => 
            chosenCategory.includes(highlight.category_id)
          );

          if (isFilterHighlight && isFilterComment) {
            return hasHighlight && hasComment && matchesCategory;
          } else if (chosenCategory && isFilterHighlight && !isFilterComment) {
            return hasHighlight && matchesCategory;
          } else if (!isFilterHighlight && isFilterComment) {
            return hasComment;
          } else {
            return true; // Show all records
          }

        });
        setScreenTextDatas(filteredData);
      })
      .catch(error => {
        console.error('Error fetching screentext:', error);
      });
  }, [isFilterHighlight,isFilterComment, chosenCategory]);


  const togglePopover = (key) => {
    setIsPopoverOpen((prevstates) => ({
      [key]: !prevstates[key]
    }));
  };

  const HandleCommentOnchange = debounce((key, comment) => {
    setCommentText((prevstates) => ({ ...prevstates, [key]: comment}));
  }, 300);

  const handleCommentSubmit = async (key) => {
    try {
      const textarea = document.getElementById(`${key}scta`).value = '';

      await AddScreenTextComment(
        key,
        current_user,
        commentText[key]
      );
      const updatedTranscriptData = await GetScreenTextByVideoID(videoid);
      const filteredData = updatedTranscriptData.data.rows.filter(item => {
        const hasHighlight = item.screen_text_highlight_text.length > 0;
        const hasComment = item.comments.length > 0;
        const matchesCategory = item.screen_text_highlight_text.some(highlight => 
          chosenCategory.includes(highlight.category_id)
        );

        if (isFilterHighlight && isFilterComment) {
          return hasHighlight && hasComment && matchesCategory;
        } else if (chosenCategory && isFilterHighlight && !isFilterComment) {
          return hasHighlight && matchesCategory;
        } else if (!isFilterHighlight && isFilterComment) {
          return hasComment;
        } else {
          return true; // Show all records
        }

      });
      setScreenTextDatas(filteredData);
      // setScreenTextDatas(updatedTranscriptData.data);

    } catch (error) {
      console.error('Error submitting comment:', error);
      // Handle the error (e.g., show a user-friendly message)
    }
  };

  const handleTimestampClick = (timestamp, index, ismanual) => {
    // Call the callback function to handle timestamp click
    if (OnScreentexttimestampclick) {
      OnScreentexttimestampclick(timestamp);
    }
    if(ismanual){
      setTimestampClick(true)
    }
    // setCurrentIndex(index);
  };

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const highlightedRow = container.querySelector('.highlighted');
  
      // Check if a highlighted row is found
      if (highlightedRow && !TimestampClick) {
        // Calculate the offset based on the container height
        const offset = container.offsetHeight - highlightedRow.offsetHeight;
        container.scrollTop = highlightedRow.offsetTop - offset;
      }

      setTimestampClick(false)
    }
  }, [currentTimestamp]);

  // const handleNextTimestamp = () => {
  //   const nextIndex = (currentIndex + 1) % totalTimestamps;
  //   const nextTimestamp = datacsv[nextIndex].StartTime;
  //   handleTimestampClick(nextTimestamp, nextIndex);
  // };
  
  // const handlePreviousTimestamp = () => {
  //   const prevIndex = (currentIndex - 1 + totalTimestamps) % totalTimestamps;
  //   const prevTimestamp = datacsv[prevIndex].StartTime;
  //   handleTimestampClick(prevTimestamp, prevIndex);
  // };

  // const handleCommentSubmit = async (key) => {
  //   // await AddTranscriptComment(key, current_user, commentText[key])
  //   try {
  //     await AddTranscriptComment(
  //       key,
  //       current_user,
  //       commentText[key]
  //     );
  //     const updatedTranscriptData = await GetTranscriptByVideoID(video_id);
  //     setTranscriptDatas(updatedTranscriptData.data)

  //     const TranscriptList = Array.isArray(updatedTranscriptData) ? updatedTranscriptData.data : updatedTranscriptData.data.rows;
  
  //     if (Array.isArray(TranscriptList)) {
  //       setFilteredTranscript(TranscriptList);
  //     } else {
  //       setFilteredTranscript({ rows: TranscriptList });
  //     }

  //   } catch (error) {
  //     console.error('Error submitting comment:', error);
  //     // Handle the error (e.g., show a user-friendly message)
  //   }
  // };

  const highlightKeywords = (text, highlights) => {

    var filteredHighlights = []
    highlights.forEach((highlight) => {
      if(chosenCategory.includes(highlight.category_id)){
        filteredHighlights.push(highlight)
      }
    });
    if (!filteredHighlights.length){
      return <>{text}</>
    }
    const memoizedParts = new Map();

    const memoizedHighlight = (part, screen_text_Colors) =>{
      if(!memoizedParts.has(part)){
        const pattern = new RegExp(
          `(${filteredHighlights
            .map((highlight) =>
              `\\b${highlight.highlighted_text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`
            )
            .join('|')})`,
          'gi'
        );
      
        const parts = part.split(pattern)

        memoizedParts.set(part, parts.map((p, index) => {
          const isKeyword = filteredHighlights.some((highlight) =>
          new RegExp(`\\b${highlight.highlighted_text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'i').test(p)
        );
          const backgroundColor = isKeyword ? screen_text_Colors[p.toLowerCase()] || 'yellow' : 'transparent';
        
          return isKeyword ? (
            <span key={index} style={{ fontWeight: 'bold', backgroundColor }}>{p}</span>
          ) : (
            <span key={index}>{p}</span>
          );
        }));
      }
      return memoizedParts.get(part);
    }
    // Map each keyword to its corresponding category color
    const screen_text_Colors = {};
    filteredHighlights.forEach(highlight => {
      if (
        highlight &&
        highlight.psst_category &&
        highlight.psst_category.color
      ) {
        const categoryColor = highlight.psst_category.color;
        screen_text_Colors[highlight.highlighted_text.toLowerCase()] = categoryColor;
      }
    });
  
    // Apply memoized highlighting to the entire text
    return <>{memoizedHighlight(text, screen_text_Colors)}</>;
  };
  return (
    <div className='h-[200px] mt-[10px] p-[10px] w-[700px]'>
      <div>
        <table className='w-[100%]'>
          <tr>
            <td className='w-[7%]'>
                <svg
                  style={{ color: '#003C71' }}
                  height='20'
                  viewBox='0 0 20 20'
                  width='20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='m7.50001 4c.18022286 0 .34393714.09663429.43228128.24868023l.03761872.08044977 3.99999 10.99997c.0944.2595-.0395.5464-.299.6408-.2306667.0839111-.4829827-.012563-.6025053-.2172927l-.0382947-.0817073-1.33485-3.6709h-4.39048l-1.33486 3.6709c-.09437.2595-.38125.3934-.64077.299-.23067556-.0839111-.3620879-.319921-.32216373-.5535868l.02314373-.0872132 4-10.99997c.07185-.1976.25964-.32913.46989-.32913zm0 1.96309-1.8316 5.03691h3.66321zm5.24279-3.90017.384.21332c2.7214 1.51194 4.5294 4.26139 4.8392 7.35921l.0315.3148c.0275.27475-.173.51975-.4478.54725s-.5198-.173-.5472-.4477l-.0315-.31485c-.2772-2.77173-1.8949-5.23177-4.3299-6.58456l-.384-.21331c-.2413-.13411-.3283-.43851-.1942-.6799s.4385-.32837.6799-.19426zm-1.1715 2.67985c.1262222-.21048.3829728-.29454025.6047824-.21083446l.0812176.03933446.1241.07447c1.5552381.93317143 2.6462585 2.47124082 3.0171061 4.2383697l.0501939.2667403.0445.26696c.0454.27239-.1386.53-.411.5754-.2421333.0403467-.4725728-.10055309-.5526782-.32367045l-.0227218-.08732955-.0445-.26696c-.2501833-1.50123222-1.1102994-2.82750349-2.3691532-3.66867646l-.2262468-.14334354-.1241-.07447c-.2368-.14207-.3136-.4492-.1715-.68599z'
                    fill='#003C71'
                  />
                </svg>
              </td>
              <td className='ml-[50px]'>
                <strong>
                  <span className='text-[#003C71]'>Screen Text Highlights</span>
                </strong>
              </td>
          </tr>
        </table>
      </div>
      <div className='h-[160px] overflow-x-hidden overflow-scroll scrollbar font-roboto_mono text-[#797979]' ref={containerRef}>
        <table className='w-[100%]'>
          <tr>
            {ScreenTextDatas.length ? (
                ScreenTextDatas.map((screen_text, index, array) => (
                  <tr key={screen_text.id} className={`${
                    currentTimestamp >= convertTimestampToSeconds(screen_text.start_time) &&
                    (!array[index + 1] || currentTimestamp <= convertTimestampToSeconds(array[index + 1].start_time))
                      ? 'highlighted text-[#000000]'
                      : ''
                  }`}
                  >
                    <td className='w-[7%] py-[8px]'>
                    <Popover
                    isOpen={isPopoverOpen[screen_text.id] || false}
                    positions={['left']} // preferred positions by priority
                    padding={15}
                    content={({ position, childRect, popoverRect }) => (
                      <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                          position={position}
                          childRect={childRect}
                          popoverRect={popoverRect}
                          arrowColor={'#ffffff'}
                          arrowSize={20}
                          arrowStyle={{ opacity: 1}}
                          className='popover-arrow-container'
                          arrowClassName='popover-arrow'
                        >
                          <div className={`h-[auto] bg-white p-[18px] rounded ${screen_text.comments && screen_text.comments.length > 0 ? 'h-[230px]' : ''}`} style={{boxShadow: '0px 4px 12px 0px #71090940'}}>
                            <div className='flex flex-row-reverse'>
                              <button onClick={() => togglePopover(screen_text.id)}>
                                <GoX />
                              </button>
                            </div>
                            {screen_text.comments && screen_text.comments.length > 0 && (
                              <div className='h-[40%] scrollbar overflow-auto mb-[10px]'>
                                {screen_text.comments.map((comment) => (
                                  <div className='flex flex-col' key={comment.id}>
                                    <div className='flex justify-between items-center text-[9px] text-[#878787] font-[Arial]'>
                                      <span><i>{comment?.psst_user?.user_name || 'Loading...'}</i></span>
                                      <span>{comment.createdAt.split('T')[0]}</span>
                                    </div>
                                    <div className='m-[0px] p-[0px] text-[12px] font-[Arial] break-all'>
                                      <button>
                                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M9.73859 1.96742L11.7256 3.9544C11.8093 4.03811 11.8093 4.17468 11.7256 4.25839L6.91452 9.06944L4.87027 9.29633C4.59711 9.32717 4.36581 9.09587 4.39665 8.82272L4.62355 6.77847L9.43459 1.96742C9.5183 1.88371 9.65488 1.88371 9.73859 1.96742ZM13.3072 1.46296L12.2322 0.387967C11.8974 0.0531321 11.3533 0.0531321 11.0162 0.387967L10.2364 1.16778C10.1527 1.25149 10.1527 1.38807 10.2364 1.47177L12.2234 3.45875C12.3071 3.54246 12.4437 3.54246 12.5274 3.45875L13.3072 2.67894C13.6421 2.3419 13.6421 1.7978 13.3072 1.46296ZM9.32886 7.76094V10.0035H2.2797V2.9543H7.34188C7.41237 2.9543 7.47845 2.92566 7.52912 2.8772L8.41026 1.99606C8.57768 1.82864 8.45873 1.54447 8.22302 1.54447H1.92725C1.34349 1.54447 0.869873 2.01808 0.869873 2.60184V10.3559C0.869873 10.9397 1.34349 11.4133 1.92725 11.4133H9.68131C10.2651 11.4133 10.7387 10.9397 10.7387 10.3559V6.8798C10.7387 6.64409 10.4545 6.52734 10.2871 6.69255L9.40596 7.5737C9.35749 7.62436 9.32886 7.69045 9.32886 7.76094Z" fill="#CECACA"/>
                                        </svg>
                                      </button>
                                      <span>{comment.comment_text}</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            <textarea id={`${screen_text.id}scta`} className={`border scrollbar text-[12px] ${screen_text.comments && screen_text.comments.length > 0 ? 'h-[35%]' : ''}`} style={{resize:'none'}} onChange={(e) => HandleCommentOnchange(screen_text.id, e.target.value)}></textarea>
                            <div
                              style={{ 
                                background: '#ffffff', 
                                boxShadow: '0px 4px 12px 0px #71090940',
                              }}
                              onClick={() => togglePopover(screen_text.id)}
                            >
                            </div>

                            <div className='flex justify-end pt-[5px] text-[13px]'>
                              <button className={'justify-center w-[70px] mr-[10px] bg-[#003c71] text-white p-[2px] cursor-pointer'} onClick={(e) => handleCommentSubmit(screen_text.id)}>Comment</button>
                              <button className={'justify-center w-[70px] bg-[#D9D9D9] p-[2px] cursor-pointer'} style={{backgroundColor: 'darkgray'}} onClick={() => togglePopover(screen_text.id)}>Cancel</button>
                            </div>
                          </div>
                        </ArrowContainer>
                      )}
                    >
                   
                    <div onClick={() => togglePopover(screen_text.id)}>
                    {screen_text.comments && screen_text.comments.length > 0 ?
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.32125 0.630737H13.9961C15.0693 0.630737 15.9419 1.35939 15.9419 2.2556V9.56747C15.9419 10.4637 15.0693 11.1923 13.9961 11.1923H11.0774V13.325C11.0774 13.5052 10.901 13.6296 10.7125 13.6296C10.6396 13.6296 10.5636 13.6118 10.4967 13.5687L6.69931 11.1923H2.32125C1.24801 11.1923 0.375439 10.4637 0.375439 9.56747V2.2556C0.375439 1.35939 1.24801 0.630737 2.32125 0.630737ZM2.07752 10.3456C2.07752 10.569 2.0537 9.97368 2.32125 9.97368H7.18576L7.57492 10.2174L9.61802 11.497V9.97368H13.9961C14.2636 9.97368 14.4825 9.79089 14.4825 9.56747L14.6193 10.2174C14.6193 9.99399 12.204 10.2174 11.9365 10.2174L9.73095 11.8007C9.4634 11.8007 7.69003 10.2804 7.69003 10.5038L2.07752 10.3456Z" fill="#003C71"/>
                        </svg>
                        : <svg
                        width='17'
                        height='14'
                        viewBox='0 0 17 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        {' '}
                        <path
                          d='M2.93103 0.121277H14.5993C15.6719 0.121277 16.544 0.849276 16.544 1.74469V9.05005C16.544 9.94546 15.6719 10.6735 14.5993 10.6735H11.6822V12.8042C11.6822 12.9843 11.506 13.1086 11.3176 13.1086C11.2447 13.1086 11.1687 13.0908 11.1018 13.0477L7.30662 10.6735H2.93103C1.85841 10.6735 0.986328 9.94546 0.986328 9.05005V1.74469C0.986328 0.849276 1.85841 0.121277 2.93103 0.121277ZM2.44486 9.05005C2.44486 9.27327 2.66364 9.4559 2.93103 9.4559H7.7928L10.2237 10.9779V9.4559H14.5993C14.8667 9.4559 15.0854 9.27327 15.0854 9.05005V1.74469C15.0854 1.52147 14.8667 1.33884 14.5993 1.33884H2.93103C2.66364 1.33884 2.44486 1.52147 2.44486 1.74469V9.05005Z'
                          fill='#EAE9E9'
                        />
                      </svg>
                        }
                    </div>
                 </Popover> </td>
                  <td className='w-[20%] py-[8px] align-baseline cursor-pointer' onClick={() => handleTimestampClick(screen_text.start_time, true, true)}>{screen_text.start_time}</td>
                  <td className={`py-[8px]`} >
                    <div>
                    {/* {highlightKeywordsMemoized(screen_text.screen_text_text, KeywordLists.rows)} */}
                    {/* {.screen_text} */}
                    {highlightKeywords(screen_text.screen_text, screen_text.screen_text_highlight_text)}
                    </div>
                  </td>
                  </tr>
                ))
              ) : null}
            </tr>
        </table>
      </div>
    </div>
  );
}

function ButtonPills(props) {
  const ref = useRef(null);
  const [Overflowed, SetIsOverflowed] = useState(false);

  const checkOverflow = () => {
    const container = ref.current; // assuming ref is defined
    const buttons = container.querySelectorAll('button');
  
    let totalWidth = 0;
  
    buttons.forEach(button => {
      const styles = window.getComputedStyle(button);
      totalWidth += button.offsetWidth + parseFloat(styles.marginLeft) + parseFloat(styles.marginRight);
    });
  
    const isOverflowing = totalWidth > container.offsetWidth;

    SetIsOverflowed(isOverflowing)
  };
  
  // Call the function after rendering (e.g., in useEffect)
  useEffect(() => {
    checkOverflow();
    console.log("the unique categories inside", props.chosenCateg)
  }, [props.Categories]);

  const scroll = (scrollDirection) => {
    const container = ref.current;
    const buttons = container.querySelectorAll('button');
    let currentPosition = container.scrollLeft;
    let targetPosition;
  
    // Calculate the width of the container
    const containerWidth = container.offsetWidth;
  
    // Find the next button position based on the current scroll position
    if (scrollDirection === 'right') {
      for (let button of buttons) {
        const buttonWidth = button.offsetWidth;
        currentPosition += buttonWidth;
        if (currentPosition > containerWidth || !button.nextElementSibling) {
          targetPosition = Math.min(currentPosition, container.scrollWidth - containerWidth);
          break;
        }
      }
    } else if (scrollDirection === 'left') {
      let previousButtonWidth = 0;
      for (let i = buttons.length - 1; i >= 0; i--) {
        const buttonWidth = buttons[i].offsetWidth;
        currentPosition -= previousButtonWidth + buttonWidth;
        if (currentPosition < 0 || !buttons[i].previousElementSibling) {
          targetPosition = Math.max(0, currentPosition);
          break;
        }
        previousButtonWidth = buttonWidth;
      }
    }
  
    // Scroll to the calculated target position
    container.scrollTo({
      left: targetPosition,
      behavior: 'smooth' // Optional: Add smooth scrolling effect
    });
  };

  const handleCategoryClick = (clickedCategory) => {
    props.onButtonClickAction(clickedCategory)
  };

  const handleFilterClick = (checkedId) => {
    // console.log(event)
    const isChecked = document.getElementById(checkedId).checked;
    console.log("filter_statusfilter_statusfilter_statusfilter_statusfilter_status")
    console.log(isChecked)
    if(checkedId=='filtercheck-highlight'){
      props.onFilterHighlightClickAction(isChecked);
    }else if(checkedId=='filtercheck-comment'){
      props.onFilterCommentClickAction(isChecked);
    }
  }

  return (
    <div>
      <div className='relative' >
        {Overflowed &&
        <div className='cursor-pointer' onClick={() => scroll('left')} style={{
            // background:'linear-gradient(180deg, rgba(242,242,242,0) 10%, rgba(242,242,242,0.73) 100%)',
            position: 'absolute',
            bottom:'17px',
            left: '0%',
            width: '1%',
            height: '30%',
            background: 'transparent',
            background: 'linear-gradient(180deg, #FFFFFF 30.21%, rgba(255, 255, 255, 0) 100%)'
            }} >

            <button
              className='opacity-1'
              style={{
                position:'absolute',
                transform: 'translate(-50%, -50%)',
                top: '12px',
                left: '89%',
                background: 'linear-gradient(180deg, #FFFFFF 30.21%, rgba(255, 255, 255, 0) 100%)',
              }}
            >
              <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.80345 0.95752L9.44189 2.97507L2.7382 10.1773L9.44189 17.3795L8.80345 19.397L0.184407 10.1773L8.80345 0.95752Z" fill="#929292"/>
              </svg>
            </button>
        </div>
        }
        <div className='text-[13px] overflow-hidden w-[500px] whitespace-nowrap opacity-60 ml-[10px]' ref={ref} >
        {Object.values(props.Categories).map(category => (
          <>
          <button
            id={category.id.toString() + "CategoryButton"}
            key={category.id}
            style={{backgroundColor:`${category?.color}`}}
            className={`pl-[10px] pr-[10px] ml-[10px] mr-[10px] h-[25px] rounded-full font-roboto_mono text-[13px] ${props.chosenCateg.includes(category.id) ? 'opacity-100' : 'opacity-50'}`}
            onClick={() => handleCategoryClick(category.id)}
          >
            {category.category_name}
          </button>
          </>
        ))}
        </div>
        {Overflowed &&
        <div className='cursor-pointer' onClick={() => scroll('right')} style={{
              // background:'linear-gradient(180deg, rgba(242,242,242,0) 10%, rgba(242,242,242,0.73) 100%)',
              position: 'absolute',
              bottom:'17px',
              left: '94%',
              width: '1%',
              height: '30%',
              background: 'transparent',
              background: 'linear-gradient(180deg, #FFFFFF 30.21%, rgba(255, 255, 255, 0) 100%)'
              }} >

              <button
                className='opacity-1'
                style={{
                  position:'absolute',
                  transform: 'translate(-50%, -50%)',
                  top: '12px',
                  left: '94%',
                  background: 'linear-gradient(180deg, #FFFFFF 30.21%, rgba(255, 255, 255, 0) 100%)',
                }}
                
              >
                <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.597277 0.282593L0.00512695 2.15385L6.22271 8.83378L0.00512695 15.5137L0.597277 17.385L8.59131 8.83378L0.597277 0.282593Z" fill="#929292"/>
                </svg>
              </button>
          </div>
        }
      </div>

      {/* <div className='flex flex-row justify-center items-center mt-[10px] text-[13px]'>
        <span className='mr-[10px]'>Hightlight Only</span>
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" id="filtercheck" value="" class="sr-only peer" onClick={() => handleFilterClick()}/>
          <div class="w-9 h-5 bg-[#C8C8C8] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#BB33FF]"></div>
        </label>
        <span className='ml-[10px]'>Hightlight And Filter</span>
      </div> */}
      <div className='flex flex-row m-[20px]'>
        <span className='mr-[30px] text-[15px]'>Filter:</span>
        <input className='mr-[5px] mt-[4px]' type="checkbox" id="filtercheck-comment" value=""  checked={props.CommentCheckboxStatus} onChange={() => handleFilterClick('filtercheck-comment')}/>
        <span className='mr-[20px] mt-[4px] text-[12px]'>With Comments</span>
        <input className='mr-[5px] mt-[4px]' type="checkbox" id="filtercheck-highlight" value=""  checked={props.FilteredCheckboxStatus} onChange={() => handleFilterClick('filtercheck-highlight')}/>
        <span className='mr-[10px] mt-[4px] text-[12px]'>With Highlights</span>
      </div>
    </div>
  );
}

function VideoDetail({ video_id , checkedKeywords, video_detail, user_id, user_name ,currentTimestamp, onTimestampClick, updateVideoDetail , activeCategory, isFilterHighlight, isFilterComment}) {
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [IsVideoDetailDone, setIsVideoDetailDone] = useState(false);
  const [users, SetUsers] = useState({ rows: []});
  const [filteredUsers, setFilteredUsers ] = new useState();
  const navigate = useNavigate();
  const handleDownloadClick = () => {
    setDownloadModalOpen(true);
  };
  const handleCancelDownload = () => {
    setDownloadModalOpen(false);
  };
  const handleConfirmDownload = () => {
    setDownloadModalOpen(false);
  };
  
  const [isActionsModalOpen, setActionsModalOpen] = useState(false);
  const [isMarkReviewModalOpen, setMarkReviewModalOpen] = useState(false);
  
  const [isEditVideoDetailReviewModalOpen, setEditVideoDetailReviewModalOpen] = useState(false);
  const [isReportReviewModalOpen, setReportReviewModalOpen] = useState(false);
  const [currentUserReviewStatus, setCurrentUserReviewStatus] = useState(false);

  const isCurrentUserReviewStatusTrue = () => {
    if (currentUserReviewStatus === true){
      setConfirmedModalOpen(true);
    }else {
      setMarkReviewModalOpen(true);
    }
    return currentUserReviewStatus;
  };

  const [checkedReviewers, setCheckedReviewers] = useState([]);
  const handleCheckboxChange = async (ReviewerId, value) => {
    if (value == true){
      try{
        await AddReviewers(ReviewerId.id, video_id)
        sendEmailAddReviewer(ReviewerId.email,video_detail.video_title,ReviewerId.user_name)
      }
      catch(err){
        console.error("Error in Adding A Reviewer", err)
      }
    }
    else{
      try{
        await DeleteReviewers(ReviewerId.id,video_id)
      }
      catch(err){
        console.error("Error in Removing A Reviewer", err)
      }
    }
    if(ReviewerId.reviewers.length==0){
      if (!checkedReviewers.includes(ReviewerId.id)) {
        // If not found, add the keywordId to the array
        setCheckedReviewers([...checkedReviewers, ReviewerId.id]);
      } else {
        // If found, remove the ReviewerId from the array
        const newCheckedKeywords = checkedReviewers.filter((id) => id !== ReviewerId.id);
        setCheckedReviewers(newCheckedKeywords);
      }
    }else if(!ReviewerId.reviewers[0].review_status){
      if (!checkedReviewers.includes(ReviewerId.id)) {
        // If not found, add the keywordId to the array
        setCheckedReviewers([...checkedReviewers, ReviewerId.id]);
      } else {
        // If found, remove the ReviewerId from the array
        const newcheckedReviewers = checkedReviewers.filter((id) => id !== ReviewerId.id);
        setCheckedReviewers(newcheckedReviewers);
      }
    }
  };

  const handleSortCheckedReviewers = (searchUpdate) => {
    var newList = []
    console.log("im in handle sort")
    if(searchUpdate){
      console.log("inside searhc update?")
      const checkedList = searchUpdate.filter(user => checkedReviewers.includes(user.id))
      const uncheckedList = searchUpdate.filter(user => !checkedReviewers.includes(user.id))
      newList = [...checkedList,...uncheckedList]
    } 
    else if(filteredUsers){
      console.log("inside filteredUsers")
      const checkedList = filteredUsers.rows.filter(user => checkedReviewers.includes(user.id))
      const uncheckedList = filteredUsers.rows.filter(user => !checkedReviewers.includes(user.id))
      newList = [...checkedList,...uncheckedList]
    }else{
      console.log("or inside the no sel")
      const checkedList = users.rows.filter(user => checkedReviewers.includes(user.id))
      const uncheckedList = users.rows.filter(user => !checkedReviewers.includes(user.id))
      newList = [...checkedList,...uncheckedList]
    }
    console.log("newlist", newList)
    if (Array.isArray(users)) {
      console.log("inside array")
      setFilteredUsers(newList);
    } else {
      console.log("inside nonarray")
      setFilteredUsers({ rows: newList });
    }
  }

  useEffect(()=>{
    console.log("hahahahaha")
    if(Object.keys(video_detail).length !== 0){
      console.log("user effect video detail", typeof video_detail)
      setIsVideoDetailDone(true)
    }
  },[video_detail])

  useEffect(()=>{
    if(users.rows.length > 0){
      handleSortCheckedReviewers()
    }
  },[checkedReviewers, users])

  const handleSearch = (event) => {
    const search = event.target.value;
    // setkeywordadd(event.target.value)
    const keywordList = Array.isArray(users) ? users : users.rows;

    const updatedList = keywordList.filter((item) => {
      return item.user_name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    if (Array.isArray(users)) {
      setFilteredUsers(updatedList);
    } else {
      setFilteredUsers({ rows: updatedList });
    }
    handleSortCheckedReviewers(updatedList);
  };

  useEffect(() => {
    // let video_details = false
    // const videoresponse = GetVideoByID(video_id, user_id)  // Update the endpoint
    // video_details = videoresponse.data;

    // console.log("the video details", video_details)
    // Fetch data from your API or backend
    GetAllUsers(video_id)  // Update the endpoint
      .then(response => {
        SetUsers(response.data);

        const reviewersWithReviews = response.data.rows.filter(user => user.reviewers.length > 0);
        
        const reviewerIds = reviewersWithReviews.map(user => user.id);
        console.log("reviewersids", reviewerIds)
        
        // console.log("video detail", video_detail?.uploader?.user_name)
        if(IsVideoDetailDone){
          console.log("uplode_id",video_detail.uploader.id)
          console.log(" type of uplode_id", typeof video_detail.uploader.id)
          if (!reviewerIds.includes(parseInt(user_id))) {
            if(video_detail.uploader.id != parseInt(user_id)){
              window.location.href = `${process.env.REACT_APP_CLIENT}/playlist`;
            }
          }
        }
        if(!IsVideoDetailDone){
          if (reviewerIds.length > 0) {
            setCheckedReviewers(prevState => [...prevState, ...reviewerIds]);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching video:', error);
      });
  }, [IsVideoDetailDone]);

  useEffect(() => {
    const fetchCurrentUserReviewStatus = async () => {
      try {
        // const get_video = await getReviewStatusForVideo(video_id)
        const response = await GetVideoWithReviewStatus(user_id, video_id);
  
        const isReviewStatusTrue = response.review_status;

        if (isReviewStatusTrue) {
          setCurrentUserReviewStatus(true);
        } else {
          setCurrentUserReviewStatus(false);
        }
    
        } catch (error) {
          console.error('Error fetching review status:', error);
        }
      };

    fetchCurrentUserReviewStatus();
  }, [user_id, video_id]);
  
  const [isConfirmedModalOpen, setConfirmedModalOpen] = useState(false);
  const [confirmedModalContent, setConfirmedModalContent] = useState(null);

  const handleOpenActionsModal = () => {
    setActionsModalOpen(true);
  };
  const handleConfirmedActionsModal = () => {
    setConfirmedModalOpen(true);
    // setConfirmedModalContent(content);
  };

  const handleCloseActionsModal = () => {
    setActionsModalOpen(false);
    // setCurrentUserReviewStatus(false);
  };

  const handleCloseMarkReviewModal = () => {
    setMarkReviewModalOpen(false);
  };

  const handleConfirmMarkReviewModal = async () => {
    try {
      await Update_Reviewer(user_id,video_id); 
      await checkAndUpdateVideoStatus(video_id);
      await GetAllUsers(video_id)  // Update the endpoint
      .then(response => {
        setFilteredUsers(response.data);
        console.log("inside the get user for mark review")

        const reviewersWithReviews = response.data.rows.filter(user => user.reviewers.length > 0);
        const reviewerIds = reviewersWithReviews.map(user => user.id);
        console.log("reviewersids", reviewerIds)
        if (reviewerIds.length > 0) {
          setCheckedReviewers(prevState => [...prevState, ...reviewerIds]);
        }
        console.log("done inside the get user for mark review")
      })
      .catch(error => {
        console.error('Error fetching video:', error);
      });
      setCurrentUserReviewStatus(true);
      setMarkReviewModalOpen(false);
      setConfirmedModalOpen(true);
    } catch (error) {
      console.log('Error updating reviewer:', error);
    }
  };
  // const user_id = localStorage.getItem('user_id');

  const checkAndUpdateVideoStatus = async (video_id) => {
    console.log("im in checkAndUpdateVideoStatus ")
    try {
      console.log('11111');
      const reviewStatuses = await getReviewStatusForVideo(video_id);
      console.log('2222');
  
      const hasFalseStatus = reviewStatuses.reviewer && Array.isArray(reviewStatuses.reviewer) &&
      reviewStatuses.reviewer.some(reviewer => reviewer.review_status === false);
      if (!hasFalseStatus) {
        await Update_Video_Status(video_id);
        console.log('33333');
        
        const uploaderEmail = reviewStatuses.uploader.email;
        const title = reviewStatuses.video_title;
        const uploaderName = reviewStatuses.uploader.user_name;
        console.log('333555');
        await sendEmail(uploaderEmail, title, uploaderName);
        console.log('444444');

      }
    } catch (error) {
      console.error('Error checking and updating video status:', error);
    }
  };

  const sendEmail = async (uploaderEmail, title, uploaderName) => {
      const message = `
      Dear ${uploaderName},\n\n <br/><br/>` +
        `Great news! All reviews for your video titled "${title}" have been successfully completed. <br/>\n\n` +
        `You can now view the final feedback and download the reports by logging into to the PSST AI platform <a href="`+process.env.REACT_APP_CLIENT+`/login">HERE</a> <br/>\n\n` +
        `Thank you. \n\n <br/><br/>`;

      let dataSend = {
        email: uploaderEmail,
        subject: 'PSST AI | Reviews completed for your video!',
        message: message,
      };
      console.log('testtt before try');
      try {
        console.log("im inside the try", MyAxios)
        const res = await MyAxios.post('/email/send-email', dataSend, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('thissss');

        if (res.status >= 200 && res.status < 300) {
          console.log('Email sent successfully');
        } else {
          console.error('Error sending email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
        console.log('elseeee');
      }
  };
  
  const sendEmailAddReviewer = async (uploaderEmail, title, uploaderName) => {
    const message = `
    Dear ${uploaderName},\n\n <br/><br/>` +
      `You have been assigned to review the video titled "${title}"<br/>\n\n` +
      `Please log in to the PSST AI platform <a href="`+process.env.REACT_APP_CLIENT+`/login">HERE</a> to review the video. <br/>\n\n` +
      `Thank you. \n\n <br/><br/>`;

    let dataSend = {
      email: uploaderEmail,
      subject: 'PSST AI | Notification',
      message: message,
    };
    console.log('testtt before try');
    try {
      console.log("im inside the try", MyAxios)
      const res = await MyAxios.post('/email/send-email', dataSend, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('thissss');

      if (res.status >= 200 && res.status < 300) {
        console.log('Email sent successfully');
      } else {
        console.error('Error sending email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      console.log('elseeee');
    }
};

  const handleConfirmActionsModal = async () => {
    try {
      await Update_Reviewer(user_id,video_id);  // Update the endpoint
      await checkAndUpdateVideoStatus(video_id);
      // const videoData = await GetVideoByID(video_id);
      // const allCompleted = videoData.reviewer.review_status.every(status => status.review_status === true);

      // if (allCompleted) {
      //   await axios.put(`http://localhost:5000/api/v1/update-video-data/${video_id}`, {
      //     status: 'All Reviews Completed',
      //   });
      // }
    } catch (error) {
      console.error('Error updating reviewer:', error);
    }
    setActionsModalOpen(false);
    setConfirmedModalOpen(true);
    // if (!isCurrentUserReviewStatusTrue) {

    //   // handleCloseActionsModal().then((unrequired) => {
    //   //   setConfirmedModalOpen(true);
    //   // })
    // }
  };

  const handleUndoReview = async () => {
    try{
      console.log('masuk sini lagi');
      await Update_Status_Undo_Reviewer(user_id,video_id);
      await GetAllUsers(video_id)  // Update the endpoint
      .then(response => {
        console.log("inside the get user for undo review")
        setFilteredUsers(response.data);

        const reviewersWithReviews = response.data.rows.filter(user => user.reviewers.length > 0);
        const reviewerIds = reviewersWithReviews.map(user => user.id);
        console.log("reviewersids", reviewerIds)
        if (reviewerIds.length > 0) {
          setCheckedReviewers(prevState => [...prevState, ...reviewerIds]);
        }
        console.log("done inside the get user for undo review")
      })
      .catch(error => {
        console.error('Error fetching video:', error);
      });
      setCurrentUserReviewStatus(false);
      setConfirmedModalOpen(false);
      setMarkReviewModalOpen(true)
    } catch (error) {
      console.error('Error updating reviewer:', error);
    }
    // var formData = new FormData();
    // var json_to_string = {}
    
    // json_to_string["video-keywords"] = checkedKeywords
    // var string_json = JSON.stringify(json_to_string)
    // formData.append("video-details",string_json)
    // const res_json = await axios.post('http://52.77.219.147:5000/api/v1/update-video-data', formData, {params: {video_id:video_id}},{headers: {'content-type':'multipart/form-data'}})
    //setConfirmedModalOpen(true);
  };

  //   updateUserReviewStatus();
  //   var formData = new FormData();
  //   var json_to_string = {}
    
  //   json_to_string["video-keywords"] = checkedKeywords
  //   var string_json = JSON.stringify(json_to_string)
  //   formData.append("video-details",string_json)
  //   const res_json = await axios.post('http://localhost:5000/api/v1/update-video-data', formData, {params: {video_id:video_id}},{headers: {'content-type':'multipart/form-data'}})
    
  //   // checkAllReviewCompleted();
  // };
  // const updateUserReviewStatus = async () => {
  //   try {
  //     const response = await axios.post('http://localhost:5000/api/v1/update-user-review-status', {
  //       user_id: user_id,
  //       review_status: true,
  //     });
  //   } catch (error) {
  //     console.error('Error updating user review status:', error);
  //   }
  // };
  
  // const updateUserReviewStatus = () => {
  //   const updatedUser = { ...user_id, review_status: true };
  //   // updateUserData(updatedUser);
  // };

  const handleCloseConfirmedModal = () => {
    setCurrentUserReviewStatus(true);
    setConfirmedModalOpen(false);
  };

  const handleOpenEditVideoDetailReviewModal = () => {
    setEditVideoDetailReviewModalOpen(true);
    setActionsModalOpen(false);
  };

  const handleCloseEditVideoDetailReviewModal = () => {
    setEditVideoDetailReviewModalOpen(false);
  };

  const handleOpenReportReviewModal = () => {
    setReportReviewModalOpen(true);
  };

  const handleCloseReportReviewModal = () => {
    setReportReviewModalOpen(false);
  };

  const handlevideodetailtimestampclick = (timestamp) => {
    onTimestampClick(timestamp)
  }

  const DownloadReportPreview =  async () => {
    try{
      GetReport(video_id)
        .then(response =>{
          const currentDate = new Date();

          // Convert the date object to a string
          const currentDateTimeString = currentDate.toISOString();

          // Alternatively, you can format the date and time as desired
          const formattedDateTime = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;
          const video = response.data.video
          const reviewers = response.data.reviewers.rows.filter(reviewer => reviewer.reviewers.length >= 1);
          const keyword_list = response.data.keyword.rows
          const transcript = response.data.comments.rows
          const screentext = response.data.screentext.rows
          const transcript_comments = response.data.comments.rows.filter(transcript => transcript.comments.length >= 1)

          
          console.log("screentext", screentext)
          let reviewersInfo = "";
          reviewers.forEach((reviewer, index) => {
            console.log(reviewer.reviewers[0].review_status)
            reviewersInfo += `-${reviewer.user_name} (${reviewer.reviewers[0].review_status ? "Reviewed" : "Not Reviewed"})\n`;
          });

          const currentUser = reviewers.find(reviewer => reviewer.id === parseInt(user_id));

          // Check review statuses
          const allReviewed = reviewers.every(reviewer => reviewer.reviewers.every(reviewer => reviewer.review_status));
          const currentUserReviewStatus = currentUser ? currentUser.reviewers[0].review_status : false;

          
          if (allReviewed) {
            reviewersInfo += "Video Review Status : All Reviews Completed\n"
          } else if (currentUserReviewStatus === false) {
              reviewersInfo += "Video Review Status : Pending My Review\n"
          } else {
              const waitingForOthers = reviewers.some(reviewer => reviewer.id !== user_id && reviewer.reviewers[0].review_status === false);
              if (waitingForOthers) {
                  reviewersInfo += "Video Review Status : Pending Others Review\n"
              } else {
                  console.log("Unexpected state: No other users with review_status false");
              }
          }


          const uniqueCategories = new Set();

          // Iterate over each transcript and extract unique categories from their highlights
          transcript.forEach(transcript => {
            transcript.highlight.forEach(highlight => {
              uniqueCategories.add(highlight.psst_category.category_name);
            });
          });
          
          let highlightinfo = "";

          uniqueCategories.forEach(categoryName => {
            // Filter transcripts to only include those containing highlights with the current category name
            highlightinfo += `-${categoryName}\n`
            const filteredTranscripts = transcript.filter(transcript => {
              return transcript.highlight.some(highlight => highlight.psst_category.category_name === categoryName);
            });
            const filteredscreentext = screentext.filter(st => {
              return st.screen_text_highlight_text.some(st_highlight => st_highlight.psst_category.category_name === categoryName);
            });

            // Store the filtered transcripts for the current category name
            // filteredTranscriptsByCategory[categoryName] = filteredTranscripts;
            filteredTranscripts.forEach((transcript,index) => {
              transcript.highlight.forEach((highlight, index) => {
                console.log("transcript",highlight)
                highlightinfo += `-- ${transcript.start_time} : "${highlight.highlighted_text}" in "${transcript.transcript_text}"\n`
              })
            })
            filteredscreentext.forEach((screentext,index) => {
              screentext.screen_text_highlight_text.forEach((highlight, index) => {
                console.log("screen text",highlight)
                highlightinfo += `-- ${screentext.start_time} : "${highlight.highlighted_text}" in "${screentext.screen_text}"\n`
              })
            })
            highlightinfo += `\n`
          });
          

          const uniqueCategorieskeyword = new Set();

          // Iterate over each transcript and extract unique categories from their highlights
          keyword_list.forEach(keyword => {
            // keyword.psst_keyword.forEach(highlight => {
            //   uniqueCategorieskeyword.add(highlight.psst_category.category_name);
            // });
            uniqueCategorieskeyword.add(keyword.psst_keyword.psst_category.category_name)
          });
          
          let KeywordListInfo = "";

          uniqueCategorieskeyword.forEach(categoryName => {
            // Filter transcripts to only include those containing highlights with the current category name
            KeywordListInfo += `${categoryName}: `
            const filteredkeyword = keyword_list.filter(keyword => (keyword.psst_keyword.psst_category.category_name === categoryName));
            
            // Store the filtered transcripts for the current category name
            // filteredkeyword.forEach((keyword,index) => {
            //   KeywordListInfo += `${keyword.psst_keyword.keyword_name}`
            // })
            KeywordListInfo += filteredkeyword.map(keyword => keyword.psst_keyword.keyword_name).join(',');
            KeywordListInfo += `\n`
          });

          // keyword_list.forEach((keyword, index) => {
          //   KeywordListInfo += `${keyword.psst_keyword.keyword_name} - ${keyword.psst_keyword.psst_category.category_name}\n`
          // })

          let transcript_comment_info = ""
          
          transcript_comments.forEach((transcript, index) =>{
            transcript_comment_info += `-${transcript.start_time}\n`
            transcript.comments.forEach((comment,index) => {
              const dateOnly = comment.createdAt.substring(0, 10);
              transcript_comment_info += `--Comment Created At ${dateOnly} By ${comment.psst_user.user_name}: ${comment.comment_text}\n`
            })
          })
          
          const uploaddateOnly = video.createdAt.substring(0, 10);

          let text_result = `Report Downloaded on: ${formattedDateTime}\nReport Downloaded by: ${user_name}\n\nVideo Title: ${video.video_title}\nVideo Description: ${video.description}\nDivision: ${video.division.division_name}\nUploader: ${video.uploader.user_name}\nUploaded On: ${uploaddateOnly}\n\nReviewers\n${reviewersInfo}\nSelected Keywords\n${KeywordListInfo}\nHighlights\n${highlightinfo}\nComments\n${transcript_comment_info}`;
          // const element = document.createElement("a");
          // const file = new Blob([text_result], {type: 'text/plain'});
          // element.href = URL.createObjectURL(file);
          // element.download = `${video.video_title}-Report Preview.pdf`;
          // document.body.appendChild(element); // Required for this to work in FireFox
          // element.click();
          const doc = new jsPDF();

          // Set the font size
          const fontSize = 10; // Adjust this value to change the font size
          doc.setFontSize(fontSize);

          // Add text content to the PDF document
          let yPosition = 10; // Initial y-coordinate position
          const pageHeight = doc.internal.pageSize.height; // Get the height of the page

          text_result.split('\n').forEach(function(line) {
              let lines = doc.splitTextToSize(line, doc.internal.pageSize.width - 20); // Split the line into multiple lines if it exceeds the page width
              lines.forEach(function(textLine) {
                  if (yPosition + fontSize > pageHeight - 10) { // If adding the next line exceeds the page height
                      doc.addPage(); // Add a new page
                      yPosition = 10; // Reset y-coordinate position
                  }
                  doc.text(10, yPosition, textLine); // Add the line of text
                  yPosition += fontSize; // Move to the next line
              });
          });

          // Save the PDF document
          doc.save(`${video.video_title}-Report Preview.pdf`);
        })
    }catch(error){
      console.log(error)
      console.log("error get report")
    }
  }

  const handleVttDownloads = async () => {
    try {
      const response = await GetVttDownload(video_id) // Replace '/your-api-endpoint' with your actual API endpoint
      // const response = ""
      const blob = new Blob([response.data], { type: 'text/vtt' }); // Create a Blob from the response data
      const url = window.URL.createObjectURL(blob); // Create a URL for the Blob
      const a = document.createElement('a'); // Create a temporary anchor element
      a.href = url;
      a.download = `${video_detail.video_title}` + '.vtt'; // Specify the filename for the download
      document.body.appendChild(a); // Append the anchor element to the document body
      a.click(); // Simulate a click on the anchor element
      window.URL.revokeObjectURL(url); // Release the URL resource
      document.body.removeChild(a); // Remove the anchor element from the document body
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }
  return (
    <div className='mt-[10px]' style={{width:'inherit'}}>
      <div className='flex flex-col xl:flex-row' style={{width:'inherit'}}>
        <div className='w-[100%] xl:w-[70%]'>
          <div className='overflow-ellipsis overflow-hidden whitespace-nowrap w-[95%]'>
            <span className='vid-title text-[24px]'>
              {video_detail.video_title}
            </span>
            <Tooltip anchorSelect=".vid-title" place="top">
              {video_detail.video_title}
            </Tooltip>
          </div>
          <div className='flex flex-row'>
            <span className='w-[auto] text-[12px] text-[#3F3E3E] mr-[10px]'>
              {formatDate(video_detail.createdAt)}
            </span>
            <div className='w-[1%] flex justify-center items-center mr-[10px]'>
              <span className='w-[5.5px] rounded-full bg-black h-[6px]'></span>
            </div>
            <span className='text-[12px] text-[#3F3E3E] mr-[10px]'>
              {video_detail?.uploader?.user_name || 'Loading...'}
            </span>
            <div className='w-[1%] flex justify-center items-center mr-[10px]'>
              <span className='w-[5.5px] rounded-full bg-black h-[6px]'></span>
            </div>
            <span className='text-[12px] text-[#3F3E3E] mr-[10px]'>
              Division: {video_detail?.division?.division_name || 'Loading...'}
            </span>
            {/* <button className='text-[12px] text-[#3F3E3E] underline' onClick={handleOpenEditVideoDetailReviewModal}>Edit video details and reviewers</button> */}
            <EditVideoDetailReviewModal
            isOpen={isEditVideoDetailReviewModalOpen}
            onCancel={handleCloseEditVideoDetailReviewModal}
            onSave={(updatedVideo) => {
              updateVideoDetail(updatedVideo);
            }}
            video_id={video_id}
          />
          </div>
        </div>
        <div className='flex flex-row w-[35%]'>
          <button
            className='flex w-[121px] xl:w-[40%] flex-row p-[10px] justify-around items-center h-[65%]'
            style={{
              backgroundColor: '#d1cfcf',
              borderRadius: '5px',
              marginRight: '10px',
            }}
            onClick={handleOpenActionsModal}
          >
            
            <FontAwesomeIcon icon={faGear} className='h-[22px] w-[22px]' />
            <span className='text-[18px]'>Action</span>
          </button>
          <DownloadModal
            isOpen={isDownloadModalOpen}
            onCancel={handleCancelDownload}
            onConfirm={handleConfirmDownload}
          />
          <button
            className='flex w-[121px] xl:w-[60%] flex-row p-[10px] justify-around items-center h-[65%] mark-review-button'
            style={{
              backgroundColor: '#d1cfcf',
              borderRadius: '5px',
              transition: 'background-color 0.3s ease',
            }}
            onClick={isCurrentUserReviewStatusTrue}
            // onClick={isCurrentUserReviewStatusTrue ? handleConfirmedActionsModal : handleOpenActionsModal}
          >
            {/* <FontAwesomeIcon icon={faGear} className='h-[22px] w-[22px]' />
             */}
            {currentUserReviewStatus ? (
              <>
                <svg width="20" height="20" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.8125 0.125014H1.1875C0.905707 0.125014 0.635456 0.236955 0.436199 0.436213C0.236942 0.63547 0.125 0.905721 0.125 1.18751V11.8125C0.125 12.0943 0.236942 12.3646 0.436199 12.5638C0.635456 12.7631 0.905707 12.875 1.1875 12.875H11.8125C12.0943 12.875 12.3645 12.7631 12.5638 12.5638C12.7631 12.3646 12.875 12.0943 12.875 11.8125V1.18751C12.875 0.905721 12.7631 0.63547 12.5638 0.436213C12.3645 0.236955 12.0943 0.125014 11.8125 0.125014ZM1.1875 11.8125V1.18751H11.8125V11.8125H1.1875Z" fill="#999999"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9968 0.0886804C15.1445 -0.000180036 15.3209 -0.0283631 15.489 0.0100461C15.657 0.0484553 15.8037 0.15047 15.8981 0.29468L16.5581 1.30135C16.6441 1.43285 16.681 1.59053 16.6621 1.74654C16.6431 1.90255 16.5697 2.04686 16.4548 2.15401L16.4528 2.15668L16.4054 2.20068L16.2554 2.34401C15.4254 3.14908 14.6206 3.9797 13.8421 4.83468C12.3774 6.44535 10.6381 8.55335 9.46744 10.5987C9.14077 11.1693 8.34277 11.292 7.86944 10.7993L3.5461 6.30735C3.48415 6.24295 3.43577 6.16675 3.40385 6.08328C3.37193 5.99982 3.35711 5.91078 3.36028 5.82148C3.36346 5.73217 3.38455 5.64441 3.42231 5.56342C3.46008 5.48243 3.51374 5.40985 3.5801 5.35001L4.88677 4.17135C5.0016 4.06782 5.14884 4.00742 5.30329 4.00049C5.45774 3.99356 5.6098 4.04052 5.73344 4.13335L7.93944 5.78735C11.3854 2.38935 13.3394 1.08535 14.9968 0.0886804Z" fill="#999999"/>
                </svg>
                <span className='text-[16px]'>Review Complete</span>
              </>
            ) : (
              <>
                <svg width="20" height="20" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0833 0H0.916667C0.673552 0 0.440394 0.0965771 0.268485 0.268485C0.0965771 0.440394 0 0.673552 0 0.916667V10.0833C0 10.3264 0.0965771 10.5596 0.268485 10.7315C0.440394 10.9034 0.673552 11 0.916667 11H10.0833C10.3264 11 10.5596 10.9034 10.7315 10.7315C10.9034 10.5596 11 10.3264 11 10.0833V0.916667C11 0.673552 10.9034 0.440394 10.7315 0.268485C10.5596 0.0965771 10.3264 0 10.0833 0ZM0.916667 10.0833V0.916667H10.0833V10.0833H0.916667Z" fill="#333333"/>
                </svg>
                <span className='text-[16px]'>Mark Complete</span>
              </>
            )
          }
          </button>
          <MarkReviewModal
            isOpen={isMarkReviewModalOpen}
            onConfirm={handleConfirmMarkReviewModal}
            onClose={handleCloseMarkReviewModal}
          />
          <ActionsModal
            isOpen={isActionsModalOpen}
            onClose={handleCloseActionsModal}
            onConfirm={handleConfirmActionsModal}
            onOpenVideoDetail={handleOpenEditVideoDetailReviewModal}
            onVttDownload={handleVttDownloads}
            showConfirmedModal={handleConfirmActionsModal}
            DownloadReportPreview={DownloadReportPreview}
            text={'testt'}
            onUndoReview={handleUndoReview}
          />
          <ConfirmedModal
            isOpen={isConfirmedModalOpen}
            onClose={handleCloseActionsModal}
            text={'Confirmed Actions'}
            activeOnConfirm={handleConfirmActionsModal}
            onUndoReview={handleUndoReview}
          />
        </div>
      </div>
      <div className='flex bg-[#F2F2F2] p-[8px]'>
        <div
          className='mt-[10px] h-[200px] flex flex-col p-[10px] mr-[30px] w-[270px]'
          style={{
            position: 'relative',
          }}
        >
          <label for='checkboxes' className='mb-[5px] text-[#003C71]'><b>Reviewers</b></label>
            <input className='mt-[8px] w-[95%] h-[30px] border rounded-[8px] border-[#D9D9D9] p-[5px] text-[15px]' type="text" placeholder='Search For Reviewer' onChange={handleSearch}></input>
            <div id="checkboxes" className='flex h-[145px] mt-[8px] flex-col scrollbar overflow-ellipsis overflow-auto whitespace-nowrap text-[15px]'>
              {!filteredUsers && users.rows.map(user => (
                <div key={user.id}>
                  <input type="checkbox" className='mr-[10px]' id={user.id} name={user.id} value={user.id} checked={checkedReviewers && checkedReviewers.includes(user.id)} onChange={(event) => handleCheckboxChange(user, event.target.checked)}/>
                  <label for="vehicle1" className='align-text-top'><span>{user.user_name}</span>

                  {user.reviewers.length > 0 && user.reviewers[0].review_status && (
                    <i>
                      <span className="text-[12px] text-[#003C71] ml-[10px] font-['Arial']">
                        Reviewed
                      </span>
                    </i>
                  )}
                  </label>
                </div>
              ))}

              {filteredUsers && filteredUsers.rows.map(user => (
                <div key={user.id}>
                  <input type="checkbox" className='mr-[10px]' id={user.id} name={user.id} value={user.id} checked={checkedReviewers && checkedReviewers.includes(user.id)} onChange={(event) => handleCheckboxChange(user, event.target.checked)}/>
                  <label for="vehicle1" className='align-text-top'><span>{user.user_name}</span>

                  {user.reviewers.length > 0 && user.reviewers[0].review_status && (
                    <i>
                      <span className="text-[12px] text-[#003C71] ml-[10px] font-['Arial']">
                        Reviewed
                      </span>
                    </i>
                  )}
                  </label>
                </div>
              ))}
          </div>
        </div>
        <EditScreenText videoid={video_id} currentTimestamp={currentTimestamp} current_user={user_id} chosenCategory={activeCategory} OnScreentexttimestampclick={handlevideodetailtimestampclick} isFilterHighlight={isFilterHighlight} isFilterComment={isFilterComment} />
      </div>
    </div>
  );
}

export default function Review() {
  const [showTranscript, setShowTranscript] = useState(true);
  const [showKeywordList, setShowKeywordList] = useState(false);
  const [isManualHighlighting, setIsManualHighlighting] = useState(false);
  const [CurrentUser, SetCurrentUser] = useState([]);
  const [video, setVideo] = useState([]);
  const [keywordList, setKeywordList] = useState({ rows:[] });
  const [TranscriptHighlight, setTranscriptHighlight] = useState({ rows:[] });
  // const token = localStorage.getItem('token');
  // const user_id = localStorage.getItem('user_id')
  // const user_name = localStorage.getItem('user_name')
  const { id } = useParams();
  const [uniqueCategories, SetUniqueCategories] = useState([]);
  const [activeKeywordList, setActiveKeywordList] = useState({ rows:[] });
  const [highlightFiltered, setIsFilterHighlight] = useState(false);
  const [commentFiltered, setIsFilterComment] = useState(false);
  const [chosenKeywordCategory, setChosenKeywordCategory] = useState()
  const [masterCategoryKeyword, setMasterCategoryKeyword] = useState([]);
  const [isBlockUIOpen, setIsBlockUIOpen] = useState(false);
  const [chosenCategory, setChosenCategory] = useState([]);
  const DuplicateKeywordStyle = {
    width: '140px',
    height: '40px',
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '8px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize:'16px',
    marginTop:'20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
  }

  const SmallStyle = {
    width: '0px',
    height: '0px',
    // backgroundColor: '#003c71',
    // color: 'white',
    // cursor: 'pointer',
    borderRadius: '0px',
    // border: 'none',
    // alignItems: 'center',
    // justifyContent: 'center',
    fontSize:'0px',
    marginTop:'0px',
    // fontFamily: 'Arial, Helvetica, sans-serif',
  }

  const handleShowKeywordClick = () => {
    const categoryList = masterCategoryKeyword.map((objectstate) => objectstate.masterState.category)
    if(categoryList.length > 0){
      setChosenKeywordCategory(categoryList[0]);
    }
    // setChosenCategory([]);
    // setIsFilterHighlight(false);
    // setIsFilterComment(false);
    // setActiveKeywordList({ rows:[] })
    setShowTranscript(false);
    setShowKeywordList(true);
  };
  const handleShowTranscriptClick = () => {
    setShowTranscript(true);
    setShowKeywordList(false);
  };

  const videoRef = useRef();
  const [currentTimestamp, setCurrentTimestamp] = useState(0);

  const handleTimeUpdate = (e) => {
    setCurrentTimestamp(e.target.currentTime);
  };

  const handleTimestampClick = (timestamp) => {
    // Convert the timestamp to seconds
    const targetTime = convertTimestampToSeconds(timestamp) + 0.001;
    // Use the videoRef to seek to the target time
    if (videoRef.current) {
      videoRef.current.seekTo(targetTime);
    }

    setIsManualHighlighting(true);
  };

  const handlePlay = () => {
    setIsManualHighlighting(false);
    // Additional logic related to playing the video
  };
  
  const [checkedKeywords, setCheckedKeywords] = useState([]);
  const FetchSSOUser = async () => {
    try{
      const response = await GetSSOUser();
      const { user_id, user_name, email } = response.data;
      // Store tokens in localStorage
      sessionStorage.setItem('user_id', user_id);
      sessionStorage.setItem('user_name', user_name);
      sessionStorage.setItem('email', email);
      if(user_id != "undefined" || is_sso != null){
        sessionStorage.setItem('sso_user', true)
      }
      return { user_id, user_name, email };
    }
    catch(err){
      console.log("Error In Fetching SSO User", err)
    }
  }

  const user_id = sessionStorage.getItem('user_id');
  const user_name = sessionStorage.getItem('user_name');
  const email = sessionStorage.getItem('email');
  const is_sso = sessionStorage.getItem('sso_user')

  const fetchvideossData = async () => {
    try {
      if(is_sso == "false" || is_sso == null){
        console.log("im in false and should be redirecting to the login")
        const videoresponse = await GetVideoByID(id, user_id)  // Update the endpoint
        setVideo(videoresponse.data);
        // .then(response => {
        //   setVideo(response.data);
        //   console.log("Responseses.data", response.data)
        // })
        // .catch(error => {
        //   console.error('Error fetching video:', error);
        // });
        const keywordlistresponse = await GetKeywordListByVideoID(id)
        setKeywordList(keywordlistresponse.data)
        // .then(response =>{
        //   const initialCheckedKeywords = response.data.rows
        //   .map(keyword => (keyword.keyword_id));
          
        //   // setCheckedKeywords(initialCheckedKeywords);
  
        // })
        // .catch(error => {
        //   console.error('Error Fetching Keyword List', error)
        // })
        const transcriptresponse = await GetTranscriptByVideoID(id)
        const uniqueCategoryIds = new Set();
        // Filter and create an array of unique categories
        const uniqueCategoriesArray = transcriptresponse.data.rows
        .filter(transcript => transcript.highlight && transcript.highlight.length > 0 && transcript.highlight[0].psst_category)
        .reduce((acc, transcript) => {
          const hasHighlights = transcript.highlight && transcript.highlight.length > 0;
        
          if (hasHighlights) {
            transcript.highlight.forEach(highlight => {
              const categoryId = highlight.category_id;
              const existingCategory = acc.find(category => category.id === categoryId);
        
              if (!existingCategory) {
                acc.push(highlight.psst_category);
              }
            });
          }
        
          return acc;
        }, []);
        SetUniqueCategories(uniqueCategoriesArray);
        // .then(response => {
        //   const uniqueCategoryIds = new Set();
        //   // Filter and create an array of unique categories
        //   const uniqueCategoriesArray = response.data.rows
        //   .filter(transcript => transcript.highlight && transcript.highlight.length > 0 && transcript.highlight[0].psst_category)
        //   .reduce((acc, transcript) => {
        //     const hasHighlights = transcript.highlight && transcript.highlight.length > 0;
          
        //     if (hasHighlights) {
        //       transcript.highlight.forEach(highlight => {
        //         const categoryId = highlight.category_id;
        //         const existingCategory = acc.find(category => category.id === categoryId);
          
        //         if (!existingCategory) {
        //           acc.push(highlight.psst_category);
        //         }
        //       });
        //     }
          
        //     return acc;
        //   }, []);
        //   SetUniqueCategories(uniqueCategoriesArray);
        // })
        // .catch(error => {
        //   console.error('Error fetching transcript:', error);
        // });
        const transcripthighlight = await GetTranscriptHighlight(id)
        setTranscriptHighlight(transcripthighlight.data)

        // .then(response =>{
        //   setTranscriptHighlight(response.data)
        // })
        // .catch(error => {
        //   console.error('Error Fetching Keyword List', error)
        // })
      }
      else{
        console.log("im in true and should be redirecting to the login")

        const { user_id } = await FetchSSOUser();
        // const response = await GetAllDivisions(user_id);
        // console.log("the response",response)
        // response.data.rows = response.data.rows.sort((a,b)=> a.name.localeCompare(b.name))
        // setDivisionsDB(response.data);
      //   GetVideoByID(id, user_id)  // Update the endpoint
      //   .then(response => {
      //     setVideo(response.data);
      //     console.log("Responseses.data", response.data)
      //   })
      //   .catch(error => {
      //     console.error('Error fetching video:', error);
      //   });
      // GetKeywordListByVideoID(id)
      //   .then(response =>{
      //     setKeywordList(response.data)
      //     const initialCheckedKeywords = response.data.rows
      //     .map(keyword => (keyword.keyword_id));
          
      //     // setCheckedKeywords(initialCheckedKeywords);
  
      //   })
      //   .catch(error => {
      //     console.error('Error Fetching Keyword List', error)
      //   })
      //   GetTranscriptByVideoID(id)
      //   .then(response => {
      //     const uniqueCategoryIds = new Set();
      //     // Filter and create an array of unique categories
      //     const uniqueCategoriesArray = response.data.rows
      //     .filter(transcript => transcript.highlight && transcript.highlight.length > 0 && transcript.highlight[0].psst_category)
      //     .reduce((acc, transcript) => {
      //       const hasHighlights = transcript.highlight && transcript.highlight.length > 0;
          
      //       if (hasHighlights) {
      //         transcript.highlight.forEach(highlight => {
      //           const categoryId = highlight.category_id;
      //           const existingCategory = acc.find(category => category.id === categoryId);
          
      //           if (!existingCategory) {
      //             acc.push(highlight.psst_category);
      //           }
      //         });
      //       }
          
      //       return acc;
      //     }, []);
      //     SetUniqueCategories(uniqueCategoriesArray);
      //   })
      //   .catch(error => {
      //     console.error('Error fetching transcript:', error);
      //   });
      //   GetTranscriptHighlight(id)
      //   .then(response =>{
      //     setTranscriptHighlight(response.data)
      //   })
      //   .catch(error => {
      //     console.error('Error Fetching Keyword List', error)
      //   })
      const videoresponse = await GetVideoByID(id, user_id)  // Update the endpoint
        setVideo(videoresponse.data);
        // .then(response => {
        //   setVideo(response.data);
        //   console.log("Responseses.data", response.data)
        // })
        // .catch(error => {
        //   console.error('Error fetching video:', error);
        // });
        const keywordlistresponse = await GetKeywordListByVideoID(id)
        setKeywordList(keywordlistresponse.data)
        // .then(response =>{
        //   const initialCheckedKeywords = response.data.rows
        //   .map(keyword => (keyword.keyword_id));
          
        //   // setCheckedKeywords(initialCheckedKeywords);
  
        // })
        // .catch(error => {
        //   console.error('Error Fetching Keyword List', error)
        // })
        const transcriptresponse = await GetTranscriptByVideoID(id)
        const uniqueCategoryIds = new Set();
        // Filter and create an array of unique categories
        const uniqueCategoriesArray = transcriptresponse.data.rows
        .filter(transcript => transcript.highlight && transcript.highlight.length > 0 && transcript.highlight[0].psst_category)
        .reduce((acc, transcript) => {
          const hasHighlights = transcript.highlight && transcript.highlight.length > 0;
        
          if (hasHighlights) {
            transcript.highlight.forEach(highlight => {
              const categoryId = highlight.category_id;
              const existingCategory = acc.find(category => category.id === categoryId);
        
              if (!existingCategory) {
                acc.push(highlight.psst_category);
              }
            });
          }
        
          return acc;
        }, []);
        SetUniqueCategories(uniqueCategoriesArray);
        // .then(response => {
        //   const uniqueCategoryIds = new Set();
        //   // Filter and create an array of unique categories
        //   const uniqueCategoriesArray = response.data.rows
        //   .filter(transcript => transcript.highlight && transcript.highlight.length > 0 && transcript.highlight[0].psst_category)
        //   .reduce((acc, transcript) => {
        //     const hasHighlights = transcript.highlight && transcript.highlight.length > 0;
          
        //     if (hasHighlights) {
        //       transcript.highlight.forEach(highlight => {
        //         const categoryId = highlight.category_id;
        //         const existingCategory = acc.find(category => category.id === categoryId);
          
        //         if (!existingCategory) {
        //           acc.push(highlight.psst_category);
        //         }
        //       });
        //     }
          
        //     return acc;
        //   }, []);
        //   SetUniqueCategories(uniqueCategoriesArray);
        // })
        // .catch(error => {
        //   console.error('Error fetching transcript:', error);
        // });
        const transcripthighlight = await GetTranscriptHighlight(id)
        setTranscriptHighlight(transcripthighlight.data)

        // .then(response =>{
        //   setTranscriptHighlight(response.data)
        // })
        // .catch(error => {
        //   console.error('Error Fetching Keyword List', error)
        // })
      }
    } catch (error) {
      if (error.response && error.response.status === 302) {
        // Redirect received, handle it
        sessionStorage.clear();
        window.location.href = error.response.data.redirecturl;
      } else {
        // Handle other errors
        console.error('Error fetching video:', error);
        throw error;
      }
    }
  }
  
  useEffect(() => {
    if(is_sso == null){
      FetchSSOUser();
    }
    fetchvideossData();
  }, []);
  // useEffect(() => {
  //   GetVideoByID(id)  // Update the endpoint
  //     .then(response => {
  //       setVideo(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching video:', error);
  //     });
  //   GetKeywordListByVideoID(id)
  //     .then(response =>{
  //       setKeywordList(response.data)
  //       const initialCheckedKeywords = response.data.rows
  //       .map(keyword => (keyword.keyword_id));
        
  //       // setCheckedKeywords(initialCheckedKeywords);

  //     })
  //     .catch(error => {
  //       console.error('Error Fetching Keyword List', error)
  //     })
  //     GetTranscriptByVideoID(id)
  //     .then(response => {
  //       const uniqueCategoryIds = new Set();
  //       // Filter and create an array of unique categories
  //       const uniqueCategoriesArray = response.data.rows
  //       .filter(transcript => transcript.highlight && transcript.highlight.length > 0 && transcript.highlight[0].psst_category)
  //       .reduce((acc, transcript) => {
  //         const hasHighlights = transcript.highlight && transcript.highlight.length > 0;
        
  //         if (hasHighlights) {
  //           transcript.highlight.forEach(highlight => {
  //             const categoryId = highlight.category_id;
  //             const existingCategory = acc.find(category => category.id === categoryId);
        
  //             if (!existingCategory) {
  //               acc.push(highlight.psst_category);
  //             }
  //           });
  //         }
        
  //         return acc;
  //       }, []);
  //       SetUniqueCategories(uniqueCategoriesArray);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching transcript:', error);
  //     });
  //     GetTranscriptHighlight(id)
  //     .then(response =>{
  //       setTranscriptHighlight(response.data)
  //     })
  //     .catch(error => {
  //       console.error('Error Fetching Keyword List', error)
  //     })
  // }, []);
  


  const handleCheckboxChangeKeyword = (KeywordId) => {
    if (!checkedKeywords.includes(KeywordId)) {
      // If not found, add the keywordId to the array
      setCheckedKeywords([...checkedKeywords, KeywordId]);
    } else {
      // If found, remove the KeywordId from the array
      const newCheckedKeywords = checkedKeywords.filter((id) => id !== KeywordId);
      setCheckedKeywords(newCheckedKeywords);
    }
  };

  const [isDuplicateKeywordOpen, setDuplicateKeywordOpen] = useState(false);

  const [errorText, setErrorText] = useState();

  const handelDuplicateKeyword = (keywordAdd) => {
    // setErrorText("The Keyword '"+keywordAdd+"'' is already exist!")    
    setErrorText("The Keyword you are adding is already exist!")    
    setDuplicateKeywordOpen(true);

  };

    const handleDuplicateKeywordClose = () => {
    setDuplicateKeywordOpen(false);
  };


  const filterChosenKeywords = async (allKeywords, ChosenCategory) => {
    var keywordsToUse = []
    allKeywords.rows.forEach((keyword) => {
      if(ChosenCategory.includes(keyword.category_id)){
        keywordsToUse.push(keyword)
      }
      // checkedKeywords.forEach((item) => {
      //   if(keyword.id == item){
      //       keywordsToJson.push(keyword)
      //     }
      //   })
      })

    return keywordsToUse
  } 

  const handleChosenCategory = async (choiceCategory) => {
    var newChosenCategory = []
    var elem = document.getElementById(choiceCategory.toString()+"CategoryButton");
    if(chosenCategory.length > 0){
      newChosenCategory = chosenCategory
      if(chosenCategory.includes(choiceCategory)){
        elem.style.opacity = '50%'
        var index = chosenCategory.indexOf(choiceCategory)
        newChosenCategory.splice(index,1)
      }else{
        elem.style.opacity = '100%'
        newChosenCategory.push(choiceCategory)
      }
    }else{
      elem.style.opacity = '100%'
      newChosenCategory.push(choiceCategory)
    }
    setChosenCategory([...newChosenCategory])
    if(newChosenCategory.length > 0){
      const FilteredKeywords = filterChosenKeywords(TranscriptHighlight, newChosenCategory).then((filterResult) => {
        setActiveKeywordList({rows:filterResult})

      })
    }else{
      setActiveKeywordList({rows:[]})
    }

  };

  const handleFilterHighlightClickAction = (filter_status) => {
    setIsFilterHighlight(filter_status)
  };

  const handleFilterCommentClickAction = (filter_status) => {
    setIsFilterComment(filter_status)
  }
  
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (data,categoryChange) => {
    // Update the selected items for "Select All" checkbox
    // if(categoryChange){
    //   if (selectAll) {
    //     setCheckedKeywords([]);
    //   } else {
    //     setCheckedKeywords(data);
    //   }

    //   // Toggle the "Select All" checkbox state
    //   setSelectAll(false);
    // }else{
    //   if (selectAll) {
    //     setCheckedKeywords([]);
    //   } else {
    //     setCheckedKeywords(data);
    //   }
    setSelectAll(!selectAll);
    setCheckedKeywords(data);
      // Toggle the "Select All" checkbox state
  };

  const encloseSelectAllToFalse = (state) => {
    setSelectAll(false);
    return false
  };

  const handleOnChangeCategory = async (categoryData, previousCategoryData) => {
    handleSelectAllChange(checkedKeywords,true)
    // console.log("111111111111111111111111111111111")
    // console.log(selectAll)
    // if(!selectAll){
    //   console.log("222222222222222222222222222222222")
    //   const selectAllState = await encloseSelectAllToFalse(true)
    //   handleSelectAllChange(checkedKeywords)
    //   // const selectAllState = encloseSelectAllToFalse(true).then((unused) => {
    //   //   handleSelectAllChange(checkedKeywords)
    //   // })
    // }else{
    //   handleSelectAllChange(checkedKeywords)
    // }
  };

  // const handleCategoryChange = (categoryData,previousCategoryData) => {
  // }

  // useEffect(()=>{


  // },[chosenCategory])

  const updateVideoDetail = async (updatedVideo) => {
    await GetVideoByID(id)  // Update the endpoint
      .then(response => {
        setVideo(response.data);
      })
      .catch(error => {
        console.error('Error fetching video:', error);
      });
  };
  // const user_name = localStorage.getItem('user_name');
  const username = user_name; 
  

  const NoHandle = () => {

  }


  const initials = username && username
    .split(' ')
    .slice(0, 2)
    .map(word => word[0])
    .join('') 

  return (
    <>
      <div className='container mx-auto px-1 h-screen font-["Arial"]'>
        <Header
          style={{ display: 'flex' }}
          type={'Review'}
          logout={<Avatar initials={initials} />}
        />
        <div className='flex flex-col xl:flex-row '>
          <div className='mr-[10px] min-[1280px]:w-[720px] min-[1294px]:w-[720px] 2xl:w-[950px] '
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <VideoPlayer videoRef={videoRef} handleTimeUpdate={handleTimeUpdate} onPlay={handlePlay} video_id={id}/>
            <VideoDetail video_id={id} checkedKeywords={checkedKeywords} video_detail={video} user_id={user_id} user_name={user_name} currentTimestamp={currentTimestamp} onTimestampClick={handleTimestampClick} updateVideoDetail={updateVideoDetail} activeCategory={chosenCategory} isFilterHighlight={highlightFiltered} isFilterComment={commentFiltered}/>
          </div>
          {showTranscript &&(
            <div className='div2'
              style={{
                width: '630px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <button className='text-[#6A6A6A] underline text-right' onClick={handleShowKeywordClick}>Switch To Keyword List</button>
              <EditTranscript onButtonClick={handleShowKeywordClick} currentTimestamp={currentTimestamp} onTimestampClick={handleTimestampClick} isManualHighlighting={isManualHighlighting} video_id={id} current_user={user_id} KeywordLists={activeKeywordList} isFilterHighlight={highlightFiltered} isFilterComment={commentFiltered}/>
              <ButtonPills Categories={uniqueCategories} onButtonClickAction={handleChosenCategory} onFilterHighlightClickAction={handleFilterHighlightClickAction} onFilterCommentClickAction={handleFilterCommentClickAction} chosenCateg={chosenCategory} FilteredCheckboxStatus={highlightFiltered} CommentCheckboxStatus={commentFiltered}/>
            </div>
          )}
          {showKeywordList &&(
            <div className='div3'
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <ModalTextButton
                isOpen={isDuplicateKeywordOpen}
                ModalStyle={{
                  background: '#fff',
                  width: '480px',
                  height: '220px',
                  padding: '50px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection:'column',
                }}
                text={errorText}
                showFaCheck = {false}
                text_style = {{fontSize:'24px'}}
                Button={<Button onClick={handleDuplicateKeywordClose} style={DuplicateKeywordStyle} text={'Return'}/>}
              />
              <ModalTextButton
                isOpen={isBlockUIOpen}
                ModalStyle={{
                  background: '#fff',
                  width: '0px',
                  height: '0px',
                  padding: '0px',
                  borderRadius: '0px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection:'column',
                }}
                text={''}
                showFaCheck = {false}
                text_style = {{fontSize:'0px'}}
                Button={<Button onClick={NoHandle} style={SmallStyle} text={''}/>}
              />
              <button className='text-[#6A6A6A] underline text-right' onClick={handleShowTranscriptClick}>Switch To Transcript</button>
              <Keywordlist notes={true} video_id={id} division_id={video.division_id} openDuplicateKeywordModal={handelDuplicateKeyword} masterCategoryKeyword={masterCategoryKeyword} setMasterCategoryKeyword={setMasterCategoryKeyword} setUIBlock={setIsBlockUIOpen} keywordList={keywordList} is_review={true} chosenCategory={chosenKeywordCategory} setChosenCategory={setChosenKeywordCategory}/>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
