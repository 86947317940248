import React, { useState } from 'react';
import Button from '../button/Button';
import ConfirmedModal from './Confirmed';

const ReminderModal = ({ isOpen, onClose, onConfirm, showConfirmedModal, content }) => {
  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const modal = {
    background: '#fff',
    position: 'absolute',
    padding: '20px',
    borderRadius: '6px',
    textAlign: 'center',
  };

  const confirmStyle = {
    width: '180px',
    height: '40px',
    backgroundColor: '#003c71',
    color: '#FDFAFA',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60px',
    position: 'relative',
    flexDirection: 'column',
    fontFamily: 'Arial, Helvetica, sans-serif',
  };
  const cancelStyle = {
    width: '180px',
    height: '40px',
    backgroundColor: '#D9D9D9',
    color: '#1B1B1B',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Arial, Helvetica, sans-serif',
  };

  const [reminderText, setReminderText] = useState('');

  const handleSendReminder = () => {
    onConfirm();
    showConfirmedModal(true);
  };

  const [isReminderModalOpen, setReminderModalOpen] = useState(false);
  const [isConfirmedModalOpen, setConfirmedModalOpen] = useState(false);
  const [confirmedModalContent, setConfirmedModalContent] = useState(null);

  const handleOpenReminderModal = () => {
    setReminderModalOpen(true);
  };

  const handleCloseReminderModal = () => {
    setReminderModalOpen(false);
    setConfirmedModalContent(null);
  };

  const handleConfirmReminderModal = (content) => {
    console.log('apakah kesini?');
    setConfirmedModalOpen(true);
    setConfirmedModalContent(content);
    setReminderModalOpen(false);
  };

  const handleCloseConfirmedModal = () => {
    setConfirmedModalOpen(false);
    setConfirmedModalContent(null);
    onClose();
  };

  return (
    isOpen && (
      <div style={modalOverlay}>
        <div style={modal}>
          <p
            className='text-black w-[363.76px]'
            style={{
              marginTop: '20px',
              textAlign: 'left',
              paddingLeft: '25px',
              fontFamily: 'Arial, Helvetica, sans-serif',
              wordWrap: 'break-word',
              fontWeight: '300',
            }}
          >
            This action will send a one-time email to all reviewers of the selected videos who have not yet completed their review:
          </p>
          <p
            className='my-8 text-black'
            style={{
              fontFamily: 'Arial, Helvetica, sans-serif',
              wordWrap: 'break-word',
              fontWeight: '300',
            }}
          >
            {/* <li>Phua, Chu Kang</li> */}
            {content}
          </p>
          <div className='flex justify-between'>
            <div className='flex items-center button '>
              <Button
                onClick={onConfirm}
                text={'Send Reminder'}
              />
            </div>
            <Button onClick={onClose} style={cancelStyle} text={'Cancel'} />

            <ConfirmedModal
              isOpen={isConfirmedModalOpen}
              onClose={() => {
                handleCloseReminderModal();
                handleCloseConfirmedModal();
              }}
              content={
                <p
                  className='w-[238.78px] text-black text-xl font-light'
                  style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}
                >
                  You have sent out the reminder
                </p>
              }
              isPlaylist={true}
              isComplete={true}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default ReminderModal;
