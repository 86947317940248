import {axios} from "../config";

export const AddScreenTextComment = async (screen_text_id, user_id ,comment_text) => {
  const TranscriptComment = await axios.post('/api/addscreentext_comment',{
    user_id: parseInt(user_id),
    screen_text_id: parseInt(screen_text_id),
    comment_text : comment_text
  }).then((a) => {
    return a;
  })

  return TranscriptComment;
}