import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import VideoDet from '../../components/molecules/video-detail/VideoDetail';
import { LogInUsers } from '../../axios/post/login';
import Swal from 'sweetalert2';


const Login = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to the backend
      const response = await LogInUsers(email, password)

      const { user_id, user_name, token, refreshToken } = response.data;

      // Store tokens in localStorage
      sessionStorage.setItem('user_id', user_id)
      sessionStorage.setItem('user_name', user_name)
      sessionStorage.setItem('email', email)
      sessionStorage.setItem('sso_user', false);
      // localStorage.setItem('token', token);
      // localStorage.setItem('refreshToken', refreshToken);
      // Swal.fire({
      //   title: "Welcome",
      //   text: `Hello, ${user_name} Glad To Have You Here`,
      //   icon: "success",
      //   allowOutsideClick : false,
      //   allowEscapeKey : false,
      //   allowEnterKey : false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     navigate('/playlist');
      //   }
      // });    
      navigate('/playlist');
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Wrong Email Or Password Inputted",
      });
      // console.error('Error log in user:', error);
    }
    
  };

  function HandleRegister(event){
    event.preventDefault();

    navigate('/register');
    // navigate('/upload_progression',{state: formData});

  };

  function HandleReset(event){
    event.preventDefault();

    navigate('/reset');
    // navigate('/upload_progression',{state: formData});

  };
  return (
    <div className='md:container md:mx-auto container mx-auto px-4 h-screen flex justify-center items-center font-["Arial"]'>
      <form
      onSubmit={handleSubmit}
        className='w-[400px] h-[350px] border border-t-0 flex flex-col p-[20px]'
        style={{ boxShadow: '2px 6px 11px 0px grey' }}
      >
        <label className='pb-2.5'>
          <strong>Email</strong>
        </label>
        <input
          className='h-[45px] p-[10px] border-[0.5px] border-gray-500 rounded-[3px]'
          placeholder='Email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label className='pb-[10px] mt-[20px]'>
          <strong>Password</strong>
        </label>
        <input
          className='h-[45px] p-[10px] border-[0.5px] border-gray-500 rounded-[3px]'
          placeholder='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className='h-[45px] bg-[#003366] text-white mt-[40px] border rounded-[3px] border-[#003366]' type="submit">
            <span>Login</span>
        </button>
        <div className='text-[12px] flex flex-row justify-between mt-[10px] text-[#003366]' >
            <a onClick={HandleRegister} href='#'><span >Don't Have an account?</span></a>
            <a onClick={HandleReset} href='#'><span>Reset Password</span></a>
        </div>
      </form>
    </div>
  );
};

export default Login;
