import {useEffect} from 'react';
import { testGetRequest } from '../../axios/services/testService';

const Playlist = () => {
  const test = async () => {
    await testGetRequest().then((a) => {
      console.log(a)
    });
  }

  return (
    <div className='container mx-auto px-4 h-screen bg-gray-300 flex justify-center items-center'>
      <div className='text-4xl'>Playlist</div>
    </div>
  );
};

export default Playlist;