import React, { useState, useEffect } from 'react';
import { GetAllCategories } from '../../../axios/get/category';

const Select = (props) => {

  return (
    <select defaultValue={props.default} onChange={props.onChange} className={props.className}>
      {props.placeholder && (<option value='' disabled selected hidden>
        {props.placeholder}
      </option>)}
      {props.isUpload && props.options.rows && props.options.rows.map((option) => (
        <option className={option.className} value={option.id}>
          {option.name}
        </option>
      ))}
      {props.isKeyword && props.options.rows && props.options.rows.map((option) => (
        <option className={option.className} value={option.id}>
          {option.name}
        </option>
      ))}
      ;
    </select>
  );
};

export default Select;
