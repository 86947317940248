import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { LogOutUsers } from '../../../axios/post/logout';

const Header = (props) => {
  const boldStyle = {
    color: '#003c71',
    fontSize: '36px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontWeight: '800',
    // wordWrap: 'break-word',
    // display: 'flex',
  };
  const lineStyle = {
    color: '#003c71',
    fontSize: '36px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontWeight: '400',
    // wordWrap: 'break-word',
    // display: 'flex',
  };

  const forvideoStyle = {
    color: '#003c71',
    fontSize: '30px',
    fontWeight: '400',
    fontFamily: 'Arial, Helvetica, sans-serif',
    // wordWrap: 'break-word',
    // display: 'flex',
  };

  const spanStyle = {
    color: '#003c71',
    fontSize: '22px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    // marginLeft: '5px',
    // display: 'flex'
  };
  const logoutStyle = {
    color: '#003c71',
    fontSize: '22px',
    fontWeight: '300',
    fontFamily: 'Arial, Helvetica, sans-serif',
    // marginLeft: '1530px',
    display: 'flex',
    alignItems: 'center',
  };

  const [showText, setShowText] = useState(true);
  const location = useLocation();

  const handlelogout = async () =>{
    try {
      if(props.addClickLogout){
        props.onClickLogout()
      }
      sessionStorage.clear();
      const response = await LogOutUsers();
      window.location.href = response.data.redirecturl;
    } catch (error) {
      // if (error.response && error.response.status === 302) {
      //   // Redirect received, handle it
      // } else {
        // Handle other errors
      console.error('Error logging out:', error);
      throw error;
      // }
    }
  }

  // useEffect(() => {
  //   if (location.pathname === '/playlist') {
  //     setShowText(false);
  //   } else {
  //     setShowText(true);
  //   }
  // }, [location.pathname]);

  return (
    <div className='flex justify-between items-center'>
      <div className='flex'>
      {showText && props.invisiblePlaylist && (<span style={boldStyle}>PSST </span>)}
      {showText && props.invisiblePlaylist &&(<span className='pl-[10px] pt-[1px]' style={lineStyle}>
            AI <i style={forvideoStyle}>for video</i> &nbsp; | &nbsp;
          </span>)}
      {showText && !props.invisiblePlaylist && (<span style={boldStyle}><Link to='/playlist'>PSST </Link></span>)}
      {showText && !props.invisiblePlaylist &&(<span className='pl-[10px] pt-[1px]' style={lineStyle}>
        <Link to='/playlist'>
          AI <i style={forvideoStyle}>for video</i> &nbsp; | &nbsp;
        </Link>
        </span>)}
        <span className='pt-[12px]' style={spanStyle}>
          {props.type}{' '}
        </span>
      </div>
      <div>
        <span style={logoutStyle}>
          {showText && !props.invisiblePlaylist && (
            <span style={{ paddingRight: '15px' }}>
              <Link to='/playlist'>Playlist </Link>
            </span>
          )}
          <a href='#' onClick={handlelogout}><span>Logout</span> </a> {props.logout}
        </span>
      </div>
    </div>
  );
};

export default Header;
