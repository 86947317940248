import React, { useState, useEffect } from 'react';
import { DatabaseSlash, Trash } from 'react-bootstrap-icons';
import Button from '../../components/atoms/button/Button';
import Header from '../../components/atoms/header/Header';
import Avatar from '../../components/atoms/avatar/Avatar';
import DeleteModal from '../../components/atoms/modal/Delete';
import ReminderModal from '../../components/atoms/modal/Reminder';
import ConfirmedModal from '../../components/atoms/modal/Confirmed';
import Select from '../../components/atoms/select/Select';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../pages/playlist/Playlist.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import EllipsisText from '../../components/atoms/ellipsis/ellipsis';
import { Tooltip } from 'react-tooltip'
import { GetAllVideo } from '../../axios/get/video';
import axios from 'axios';
// import { GetCurrentUser } from '../../axios/get/getcurrentuser';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Update_Delete_Status } from '../../axios/put/update_delete_status';
// import ValidationModal from '../../components/atoms/modal/Validation';
// import FailedModal from '../../components/atoms/modal/Failed';
// import ProcessingModal from '../../components/atoms/modal/Processing';
// import ValidationDeleteModal from '../../components/atoms/modal/ValidationDelete';
import Swal from 'sweetalert2';
import {axios as MyAxios} from '../../axios/config.js';
import DisclaimerModal from '../../components/atoms/modal/Disclaimer.js';
import { GetSSOUser } from '../../axios/get/get_sso_user.js';

const PlaylistDetail = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // const user_id = localStorage.getItem('user_id');
  // const user_name = localStorage.getItem('user_name');
  // const email = localStorage.getItem('email');
  

  const rows = Array.from({ length: 5 }, (_, index) => index + 1);

  const [isReminderModalOpen, setReminderModalOpen] = useState(false);
  const [isConfirmedModalOpen, setConfirmedModalOpen] = useState(false);
  const [ReminderModalContent, setReminderModalContent] = useState(false);
  const [confirmedModalContent, setConfirmedModalContent] = useState(false);
  const [IsDisclaimerModalOpen, setDisclaimerModalOpen] = useState();

  useEffect(() => {
    const disclaimerShown = localStorage.getItem('disclaimer_shown');
    if (!disclaimerShown) {
      setDisclaimerModalOpen(true);
    }
  }, []);

  const navigate = useNavigate();

  function HandleUpload(event) {
    event.preventDefault();

    navigate('/upload');
  }

  const [selectedValue, setSelectedValue] = useState('');
  
  const table_master = [
    {video_title:'Video title IVUS Pre-stent workflow explained & the title is too...', uploaded_on:'2023-11-10', uploader:'Yeoh, Michelle', reviewed_by:'Yeoh, Michelle', pending_review_by:'Sun, Stephanie', status:'Queueing' },
    {video_title:'Boston Scientific Coronary CTO', uploaded_on:'2023-11-10', uploader:'Yeoh, Michelle', reviewed_by:'Yeoh, Michelle', pending_review_by:'Cheung, Jacky', status:'AI Processing' },
    {video_title:'How Radiofrequency Ablation (RFA) Works', uploaded_on:'2023-11-09', uploader:'Wu, Daniel', reviewed_by:'Wu, Daniel', pending_review_by:'Cheung, Jacky', status:'Pending my review' },
    {video_title:'Precision Charger Patient Instructional Video', uploaded_on:'2023-11-08', uploader:'Sun, Stephanie', reviewed_by:'Sun, Stephanie', pending_review_by:'Phua, Chu Kang', status:'Pending others review' },
    {video_title:'Getting my Sunshine back: Marys Story', uploaded_on:'2023-11-06', uploader:'Yeoh, Michelle', reviewed_by:'Yeoh, Michelle', pending_review_by:'Phua, Chu Kang, Phua, Chu Kang, Phua, Chu Kang, Phua, Chu Kang, Phua, Chu Kang', status:'All reviews completed' },
  ]

  const [video, setVideo] = useState({ rows: [] });
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');

  const getStatus = (item, user_id) => {
    if (item.status !== 'AI Processing' && item.status !== 'Failed') {
      console.log("This is the Status Log")
      console.log("item", item)
      console.log("item uploader", item.uploader.id)
      console.log("User id", user_id)
      const hasPendingReview = item.reviewer && item.reviewer.some(reviewer => !reviewer.review_status);
      const isPendingMyReview = hasPendingReview && item.reviewer.find(reviewer => String(reviewer.user.id) === String(user_id) && !reviewer.review_status);
      const isPendingOtherReview = hasPendingReview && (item.reviewer.find(reviewer => String(reviewer.user.id) === String(user_id) && reviewer.review_status) || String(item.uploader.id) == String(user_id));

      if (isPendingMyReview) {
        return 'Pending My Review';
      }

      if (isPendingOtherReview) {
        return 'Pending Others Review';
      }
    }
    return item.status;
  };

  const FetchSSOUser = async () => {
    try{
      const response = await GetSSOUser();
      const { user_id, user_name, email } = response.data;
      // Store tokens in localStorage
      sessionStorage.setItem('user_id', user_id);
      sessionStorage.setItem('user_name', user_name);
      sessionStorage.setItem('email', email);
      
      if(user_id != "undefined" || is_sso != null){
        sessionStorage.setItem('sso_user', true)
      }
      return { user_id, user_name, email };
    }
    catch(err){
      console.log("Error In Fetching SSO User", err)
    }
  }

  const user_id = sessionStorage.getItem('user_id');
  const user_name = sessionStorage.getItem('user_name');
  const email = sessionStorage.getItem('email');
  const is_sso = sessionStorage.getItem('sso_user')

  const fetchVideoData = async () => {
    try {
      if(is_sso == "false" || is_sso == null){
        console.log("NO SSO")
        console.log("User ID:", user_id);
        const response = await GetAllVideo(user_id);
        console.log("the response",response)
        const updatedVideoRows = response.data.rows.map(item => ({
          ...item,
          status: String(item.status) === 'Pending My Review' ? 'Pending Others Review' : item.status,
        }));
    
        setVideo({ rows: updatedVideoRows });
      }
      else{
        console.log("YES SSO")
        const { user_id } = await FetchSSOUser();
        console.log("User ID:", user_id);
        const response = await GetAllVideo(user_id);
        console.log("the response",response)
        const updatedVideoRows = response.data.rows.map(item => ({
          ...item,
          status: String(item.status) === 'Pending My Review' ? 'Pending Others Review' : item.status,
        }));
    
        setVideo({ rows: updatedVideoRows });
      }
    } catch (error) {
      if (error.response && error.response.status === 302) {
        // Redirect received, handle it
        sessionStorage.clear();
        window.location.href = error.response.data.redirecturl;
      } else {
        // Handle other errors
        console.error('Error fetching video:', error);
        throw error;
      }
    }
  }
  
  useEffect(() => {
    if(is_sso == null){
      FetchSSOUser();
    }
    fetchVideoData();
  }, []);

  useEffect(() => {
    const updatedData = video.rows.map(item => ({
      ...item,
      status: getStatus(item, user_id),
    }));
    const filtered = updatedData.filter(item => {
      const filterMatch =
        selectedFilter === '' ||
        item.status?.toLowerCase() === selectedFilter.toLowerCase();
        
      const searchMatch =
        item.id && item.id?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.video_title && item.video_title?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.uploaded_on && item.uploaded_on?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.uploader && item.uploader?.user_name && item.uploader.user_name.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        (item.reviewer &&
          item.reviewer?.some(
            reviewer =>
              reviewer.user.user_name.toLowerCase().includes(searchQuery.toLowerCase())
          )) ||
        (item.pending_review_by &&
          item.pending_review_by
            ?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (item.status &&
          item.status?.toString().toLowerCase().includes(searchQuery.toLowerCase()));

      return filterMatch && searchMatch;
    });

    setFilteredData(filtered);
  }, [video, selectedFilter, searchQuery, user_id]);

  const handleClearFilters = () => {
    setSearchQuery('');
    setSelectedFilter('');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };  

  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipId, setTooltipId] = useState('');
  const [tooltipContent, setTooltipContent] = useState('');

  const handleMouseEnter = (reviewed_by) => {
    setTooltipVisible(true);
    setTooltipId(`tooltip-${reviewed_by}`);
    setTooltipContent(reviewed_by);
    
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
    setTooltipContent('');
  };

  // const [isFailedModalOpen, setFailedModalOpen] = useState(false);
  // const [isProcessingModalOpen, setProcessingModalOpen] = useState(false);
  // const [isValidationModalOpen, setValidationModalOpen] = useState(false);
  // const [isTbodyOpaque, setTbodyOpaque] = useState(false);

  const handleItemClick = (id, status) => {
    if (status === 'AI Processing') {
      // setProcessingModalOpen(true);
      // setTbodyOpaque(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'You will be able to review the video once the AI processing is completed',
      });
      console.log('Cannot navigate to review for videos with status "AI Processing"');
      
    } else if (status === 'Failed'){
      // setFailedModalOpen(true);
      // setTbodyOpaque(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'AI Processing has failed, please contact Boston Scientific APAC Data Team for details',
      });
      console.log('Cannot navigate to review for videos with status "Failed"');
    } else {
      // setValidationModalOpen(true);
      navigate(`/review/${id}`);
    }
  };
  // const handleCloseFailedModal = () => {
  //   setFailedModalOpen(false);
  //   setTbodyOpaque(false);
  // };


  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  // const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const pageCount = Math.ceil(
    filteredData
      .filter(item => {
        // const isUploaderMatch = user_id && String(item.uploader?.id) === String(user_id);

        const isReviewerMatch = user_id && (item.reviewer.some(reviewer => String(reviewer.user.id) === String(user_id)));
        
        // const isMatch = isUploaderMatch || isReviewerMatch;
        const isMatch = isReviewerMatch;

        return isMatch;
      })
      .length / itemsPerPage
  );
  const [selectAll, setSelectAll] = useState(false);
  const [checkedVideoIds, setCheckedVideoIds] = useState([]);
  const [isSendReminderDisabled, setSendReminderDisabled] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const allVideoIds = video.rows.map(item => item.id);

    if (selectAll) {
      setCheckedVideoIds([]);
    } else {
      setCheckedVideoIds(allVideoIds);
    }
  };

  const handleCheckboxChange = (videoId) => {
    // handleSelectAll()
    setCheckedVideoIds((prevCheckedVideoIds) => {
      if (prevCheckedVideoIds.includes(videoId)) {
        return prevCheckedVideoIds.filter((id) => id !== videoId);
      } else {
        return [...prevCheckedVideoIds, videoId];
      }
    });
    var listChecked = checkedVideoIds
    // const hasFailedVideo = checkedVideoIds.some(item => {
    //   return checkedVideoIds.includes(item.id === videoId) && String(item.status) === 'Failed';
    // });
    // setSendReminderDisabled(hasFailedVideo);
    // console.log(hasFailedVideo, 'hasfailedhasfailedhasfailedhasfailedhasfailedhasfailed');
    var videos = filteredData.filter(item => {
      const isMatch = user_id && item.reviewer?.some(reviewer => String(reviewer.user.id) === String(user_id));
      // console.log('Item:', item, 'Is Match:', isMatch, item.uploader_id);
      return isMatch;
    })
    if(videos.length == listChecked.length + 1){
      setSelectAll(true)
    }else{
      setSelectAll(false)
    }

  };
  useEffect(() => {
    const hasFailedVideo = checkedVideoIds.some(id => {
      const videoItem = filteredData.find(item => item.id === id);
      return videoItem && String(videoItem.status) === 'Failed' || String(videoItem.status) === 'AI Processing';
    });
    setSendReminderDisabled(hasFailedVideo);
    const canDelete = checkedVideoIds.every(videoId => {
      const videoItem = video.rows.find(item => item.id === videoId);
      return videoItem && String(videoItem.uploader_id) === String(user_id);
    });
    setDeleteDisabled(!canDelete);

}, [checkedVideoIds, filteredData]);
  
  
  const handleConfirmDelete = async () => {
    if (checkedVideoIds.length === 0) {
      console.log('Choose at least one video to delete.');
      // return;
    }
    try {
      await Update_Delete_Status(checkedVideoIds);
      setCheckedVideoIds([]);
      await fetchVideoData();
    } catch (error) {
      console.error('Error checking and updating video status:', error);
    }
  };
  
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleteDisabled, setDeleteDisabled] = useState(false);
  // const [canDelete, setCanDelete] = useState(false);
  // const [isValidationDeleteModalOpen, setValidationDeleteModalOpen] = useState(false);
  const handleDeleteClick = () => {
    const canDelete = checkedVideoIds.every(videoId => {
      const videoItem = video.rows.find(item => item.id === videoId);
      return videoItem && String(videoItem.uploader_id) === String(user_id);
    });
    // setCanDelete(canDelete);
    if (checkedVideoIds.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Choose at least one video to delete",
      });
      // setValidationDeleteModalOpen(true)
      return
    }
    if (!canDelete) {
      // setDeleteDisabled(true);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "You can only delete videos which you are the uploader",
      // });
      // setValidationModalOpen(true)
      console.log('You do not have permission to delete one or more selected videos.');
      return;
    } else {
        // try{
          // await fetchVideoData();
          setFilteredData(filteredData);
          setDeleteModalOpen(true);
        // } catch (error) {
        //   console.error('Error checking and updating video status:', error);
        // }
      
    }
};


  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleOpenReminderModal = async () => {
    console.log('masuk open reminder', email);
    // await sendEmail();
    
    const reviewersWithFalseStatus = checkedVideoIds.reduce((result, videoId) => {
        const videoItem = video.rows.find(item => item.id === videoId);

        const reviewersWithFalseStatusForVideo = videoItem.reviewer.filter(reviewer => reviewer.review_status === false);

        // result.push(...reviewersWithFalseStatusForVideo.map(reviewer => reviewer.user.user_name));
        result.push(
          ...reviewersWithFalseStatusForVideo.map(reviewer => ({
            username: reviewer.user.user_name,
            email: reviewer.user.email
          }))
        );
        return result;
    }, []);

    const uniqueReviewers = Array.from(new Set(reviewersWithFalseStatus.map(user => user.email)));

    if (uniqueReviewers.length > 0) {
      const listItems = uniqueReviewers ? (
        uniqueReviewers.map(email => {
            const user = reviewersWithFalseStatus.find(user => user.email === email);
            return (
                <li key={user.email}>{user.username}</li>
            );
        })
    ) : null;
    

        setReminderModalContent(
          <ul className="max-h-24 overflow-y-auto pl-32 list-disc text-left !important">
              {listItems}
          </ul>
      );
      
    setReminderModalOpen(true);
      
    } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: 'Please select a video first',
        })
        setReminderModalOpen(false);
    }

    // setReminderModalOpen(true);
};

  const handleCloseReminderModal = () => {
    setReminderModalOpen(false);
  };

  const handleCloseDisclaimerModal = () => {
    setDisclaimerModalOpen(false);
    localStorage.setItem('disclaimer_shown', 'true');
  };

  const handleConfirmReminderModal = async () => {
    setConfirmedModalContent(
      <p
        className='w-[238.78px] text-black text-xl font-light'
        style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}
      >
        You have sent out the reminder
      </p>
    );
    setConfirmedModalOpen(true);
    await sendEmail();
    // await executeMyLambda();
    console.log('testtt masuk kesini');
    setReminderModalOpen(false);
  };

  // const handleConfirmReminderModal = async () => {
  //   await sendEmail();
  //   setConfirmedModalOpen(true);
  // };
  
  const sendEmail = async () => {
    const reviewersWithFalseStatus = checkedVideoIds.reduce((result, videoId) => {
      const videoItem = video.rows.find(item => item.id === videoId);

      const reviewersWithFalseStatusForVideo = videoItem.reviewer.filter(reviewer => reviewer.review_status === false);

      // result.push(...reviewersWithFalseStatusForVideo.map(reviewer => reviewer.user.user_name));
      result.push(
        ...reviewersWithFalseStatusForVideo.map(reviewer => ({
          username: reviewer.user.user_name,
          email: reviewer.user.email
        }))
      );
      return result;
    }, []);

    const uniqueReviewers = Array.from(new Set(reviewersWithFalseStatus));

      const listItems = uniqueReviewers ? (
        uniqueReviewers.map(user => (
            <li key={user.email}>{user.email}</li>
        ))
    ) : null;

      const extractedEmails = listItems.map(item => item.props.children);

      // const sendEmail = async () => {
        for (const reviewer of uniqueReviewers) {
          const videosForReviewer = checkedVideoIds
            .filter(videoId => {
              const videoItem = video.rows.find(item => item.id === videoId);
              return videoItem.reviewer.some(
                reviewerItem => reviewerItem.user.email === reviewer.email && !reviewerItem.review_status
              );
            })
            .map(videoId => {
              const videoItem = video.rows.find(item => item.id === videoId);
              return `- ${videoItem.video_title}\n`;
            })
            .join('\n');
          
      
          const message = `
          Dear ${reviewer.username},\n\n <br/><br/>` +
            `This is a gentle reminder that there are videos awaiting your review:\n\n <br/>` +
            `${videosForReviewer}\n\n <br/><br/>` +
            `Please log in to the PSST AI platform <a href="`+process.env.REACT_APP_CLIENT+`/login">HERE</a> to conduct your review.\n\n <br/>` +
            `Thank you. \n\n <br/><br/>`;
      
          let dataSend = {
            email: reviewer.email,
            subject: 'PSST AI | Reminder: Outstanding videos awaiting your review',
            message: message,
          };
      
          try {
            // const res = await axios.post('http://localhost:5000/email/send-email', dataSend, {
            // const res = await axios.post('http://54.254.132.160:5000/email/send-email', dataSend, {
            const res = await MyAxios.post('/email/send-email', dataSend, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
      
            if (res.status >= 200 && res.status < 300) {
              console.log('Email sent successfully to:', reviewer.email);
            } else {
              console.error('Error sending email to:', reviewer.email);
            }
          } catch (error) {
            console.error('Error sending email:', error);
          }
        }
      };
      

 
  const handleCloseConfirmedModal = () => {
    setConfirmedModalOpen(false);
    setReminderModalOpen(false);
  };

  // async function HandleSubmit(event){
  //   event.preventDefault();

  //   try {
  //     const res_user_id = await axios.get('http://localhost:5000/auth/me', {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     console.log("Current User:", res_user_id.data);
  //   } catch (error) {
  //     console.error('Error fetching user data:', error.message);
  //   }
  // };
  const username = user_name; 

  const initials = username && username
    .split(' ')
    .slice(0, 2)
    .map(word => word[0])
    .join('') 

  
  return (
    // <div className='mx-3 px-3'>
    <div className='container mx-auto px-1 flex flex-col min-h-screen font-["Arial"]'>
      <Header
        invisiblePlaylist={true}
        className="flex items-center"
        type={'Playlist'}
        logout={<Avatar initials={initials} />}
      />
      <div className='flex items-center button mt-20'>
        <Button text={'Upload New Video'} onClick={() => navigate('/upload')} />
      </div>
      {/* <button onClick={HandleSubmit}>hello</button> */}
      <hr className='hr' />
      <br />

      <div className='row flex items-center justify-between'>
        <DisclaimerModal 
          isOpen={IsDisclaimerModalOpen}
          onClose={handleCloseDisclaimerModal}
          />
        <div>
          <input
            type='text'
            placeholder='Search'
            value={searchQuery}
            onChange={handleSearchChange}
            className="border border-gray-300 rounded-md mb-5 mr-10"
          />
          {/* <Select className='bg-[white] border-gray-300 text-gray-500 rounded-[6px]'
          placeholder='Status Filter'
          options={data}
          onChange={(value) => handleFilterChange(value)}
          /> */}
          <select
            placeholder='Status Filter'
            value={selectedFilter}
            onChange={handleFilterChange}
            style={{
              border: '1px solid #ccc',
              borderRadius: '6px',
              marginBottom: '10px',
              color: selectedFilter ? 'black' : 'darkgrey',
              backgroundColor: 'white',
              width: '220px',
            }}
          >
            <option value="" hidden>Status Filter</option>
            <option value="AI Processing" className="text-black">AI Processing</option>
            <option value="Pending my review" className="text-black">Pending My Review</option>
            <option value="Pending others review" className="text-black">Pending Others Review</option>
            <option value="All reviews completed" className="text-black">All Reviews Completed</option>
            <option value="Failed" className="text-black">Failed</option>
 
          </select>
          {/* <FontAwesomeIcon title="Clear All Filters" icon={faFilterCircleXmark} onClick={handleClearFilters} className='text-gray-400 ml-5'/> */}
          <u className='text-sm text-gray-400 ml-5 cursor-pointer'><span onClick={handleClearFilters}>Clear Filter</span></u>
        </div>
        <div className='flex mt-[-1.5%]'>
          <div className='flex items-center button mr-5'>
              <Button onClick={handleOpenReminderModal} text={'Send Reminder'} disabled={isSendReminderDisabled}/>

              <ReminderModal
                isOpen={isReminderModalOpen}
                onClose={handleCloseReminderModal}
                onConfirm={handleConfirmReminderModal}
                showConfirmedModal={handleConfirmReminderModal}
                content={ReminderModalContent}

              />
              
              <ConfirmedModal
                isOpen={isConfirmedModalOpen}
                onClose={handleCloseConfirmedModal}
                content={confirmedModalContent}
                isComplete={true}
              />
            </div>
          <div className='cursor-pointer'>
            {/* <button onClick={handleDeleteClick} className={`pl-4 delete ${!canDelete ? 'grey-off' : 'delete'}`} > */}
            <Button style={{ width: '100%', backgroundColor: 'transparent', color: isDeleteDisabled ? 'grey' : 'red' }} icon={<Trash className='text-lg mr-2' />} onClick={handleDeleteClick} className={'pl-4 delete cursor-pointer'} text={'Delete'} disabled={isDeleteDisabled} >
              
              <Trash className='text-lg mr-2' />
              Delete
            </Button>
            {/* <ValidationDeleteModal
                isOpen={isValidationDeleteModalOpen}
                onClose={() => setValidationDeleteModalOpen(false)}
                content="Choose at least one video to delete"
              /> */}
            {/* <ValidationModal
                isOpen={isValidationModalOpen}
                onClose={() => setValidationModalOpen(false)}
                content="You can only delete videos which you are the uploader"
              /> */}
            <DeleteModal
              isOpen={isDeleteModalOpen}
              onCancel={handleCancelDelete}
              onConfirm={handleConfirmDelete}
            />
          </div>
          {/* <FailedModal
            isOpen={isFailedModalOpen}
            onClose={() => setFailedModalOpen(false)}
            content="AI Processing has failed, please contact Boston Scientific APAC Data Team for details"
          />
          <ProcessingModal
            isOpen={isProcessingModalOpen}
            onClose={() => setProcessingModalOpen(false)}
            content="You will be able to review the video once the AI processing is completed"
          /> */}
        </div>
      </div>

      <table width='100%' border='1'>
        <thead className='bg-gray-300'>
          <tr className="h-12 text-left">
            <th width='5%' className='px-5'>
            <input
              type='checkbox'
              checked={selectAll}
              onChange={handleSelectAll}
            />

            </th>
            <th width='8%'>Video ID</th>
            <th width='28%'>Video title</th>
            <th width='12%'>Uploaded On</th>
            <th width='12%'>Uploader</th>
            <th width='12%'>Reviewed by</th>
            <th width='12%'>Pending review by</th>
            <th width='12%'>Status</th>
          </tr>
        </thead>
        {/* <tbody className={isTbodyOpaque ? 'opaque-tbody' : ''}> */}
        <tbody>
          {/* {filteredData.map((item, index) => ( */}
          {filteredData
            .filter(item => {
              const isMatch = user_id && (item.reviewer?.some(reviewer => String(reviewer.user.id) === String(user_id)) || String(item.uploader.id)===String(user_id) );
              // console.log('Item:', item, 'Is Match:', isMatch, item.uploader_id);
              return isMatch;
            })
            .sort((a, b) => new Date(b.createdAt.split('T')[0]) - new Date(a.createdAt.split('T')[0]))
            .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
            .map((item, index) => (
              
            // (user_id && item.uploader?.id === user_id) && (
              <tr key={index} id={item.id} className="text-sm mt-10">
                <td className='px-5 mt-10'>
                  <input type='checkbox' checked={checkedVideoIds.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)} className='text-gray-500 my-3' />{' '}
                </td>
                <td id={item.id}>
                  {item.id} 
                </td>
                <td className='mt-10' id={item.id}>
                  {/* <Link to='/review'>
                      <u>
                      {item.video_title}
                    </u>
                  </Link> */}
                  <u><span onClick={() => handleItemClick(item.id, item.status)} style={{ cursor: 'pointer' }}>
                    {item.video_title}
                  </span></u>
                  {/* <FailedModal
                    isOpen={isFailedModalOpen}
                    onClose={() => setFailedModalOpen(false)}
                    content="AI Processing has failed, please contact Boston Scientific APAC Data Team for details"
                  />
                  <ProcessingModal
                    isOpen={isProcessingModalOpen}
                    onClose={() => setProcessingModalOpen(false)}
                    content="You will be able to review the video once the AI processing is completed"
                  /> */}
                {/* {isFailedModalOpen && (
                  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-10">
                  <div className="bg-white rounded p-8">
                  <div className="pl-8 flex my-12 items-center justify-center">
                      <p>Cannot navigate to review for videos with status "Failed".</p>
                      <button onClick={handleCloseFailedModal}>OK</button>
                    </div>
                    </div>
                  </div>
                )} */}
                </td>
                <td>{item.createdAt.split('T')[0]}</td>
                
                <td id={item.id}>
                {/* {user_id && item.uploader?.id === user_id && item.uploader?.user_name} */}
                  {item.uploader?.user_name} 
                </td>
                {/* <td id={item.id}>{Array.isArray(item.reviewer) && item.reviewer.length > 0
                    ? item.reviewer.map((reviewer) => reviewer.user.user_name).join(', ')
                    : 'No Reviewer'} </td> */}
                <td
                  id={item.id}
                  onMouseEnter={() => handleMouseEnter(item.reviewer.filter(reviewer => reviewer.review_status === true).map((reviewer) => reviewer.user.user_name).join(', '))}
                  onMouseLeave={handleMouseLeave}
                >
                  {Array.isArray(item.reviewer) && item.reviewer.length > 0 ? (
                    <>
                      {console.log("review_status:", item.reviewer[0].review_status)} {/* Log nilai review_status */}
                      {item.reviewer.some(reviewer => reviewer.review_status === true) ? (
                        <>
                          <span className='tooltipClass overflow-ellipsis overflow-hidden whitespace-nowrap w-[60%]'>
                            {item.reviewer.filter(reviewer => reviewer.review_status === true).map((reviewer) => reviewer.user.user_name)[0]} 
                            {item.reviewer.filter(reviewer => reviewer.review_status === true).map((reviewer) => reviewer.user.user_name).length > 1 && (
                              <>
                                {',... '}
                                +{item.reviewer.filter(reviewer => reviewer.review_status === true).map((reviewer) => reviewer.user.user_name).length - 1}
                              </>
                            )}
                          </span>
                          {isTooltipVisible && (
                            <Tooltip
                              id={tooltipId}
                              place="top"
                              type="dark"
                              effect="float"
                              anchorSelect='.tooltipClass'
                              event={{ hover: true }}
                            >
                              {tooltipContent}
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        'No Reviewer'
                      )}
                    </>
                  ) : (
                    'No Reviewer'
                  )}
                </td>

                <td
                  id={item.id}
                  onMouseEnter={() => handleMouseEnter(item.reviewer.filter(reviewer => reviewer.review_status === false).map((reviewer) => reviewer.user.user_name).join(', '))}
                  onMouseLeave={handleMouseLeave}
                >
                  {Array.isArray(item.reviewer) && item.reviewer.length > 0 ? (
                    <>
                      {console.log("review_status:", item.reviewer[0].review_status)} {/* Log nilai review_status */}
                      {item.reviewer.some(reviewer => reviewer.review_status === false) ? (
                        <>
                          <span className='tooltipClass overflow-ellipsis overflow-hidden whitespace-nowrap w-[60%]'>
                            {item.reviewer.filter(reviewer => reviewer.review_status === false).map((reviewer) => reviewer.user.user_name)[0]} 
                            {item.reviewer.filter(reviewer => reviewer.review_status === false).map((reviewer) => reviewer.user.user_name).length > 1 && (
                              <>
                                {',... '}
                                +{item.reviewer.filter(reviewer => reviewer.review_status === false).map((reviewer) => reviewer.user.user_name).length - 1}
                              </>
                            )}
                          </span>
                          {isTooltipVisible && (
                            <Tooltip
                              id={tooltipId}
                              place="top"
                              type="dark"
                              effect="float"
                              anchorSelect='.tooltipClass'
                              event={{ hover: true }}
                            >
                              {tooltipContent}
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        'No Reviewer'
                      )}
                    </>
                  ) : (
                    'No Reviewer'
                  )}
                </td>
                {/* <td id={item.id}>{item.uploader.user_name} </td> */}
                {/* <td>{item.status}</td> */}
                <td>{getStatus(item, user_id)}</td>

              </tr>
            // )
          ))}
        </tbody>
      </table>
      {(pageCount > 0)&&(<div className="flex justify-end mt-5">
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
        <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <span className='mx-5'>Page {currentPage + 1} of {pageCount}</span>
        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === pageCount - 1}>
        <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>)}
      {/* <div className="pagination-container">
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </div> */}
    <div className="flex-grow"></div>
      <footer className="text-center text-sm py-2">
        <p>This app is developed by Boston Scientific APAC Data Team | <u><a href="mailto:APACDataTeam@bsci.com">Contact Us</a></u> or <u><a href="mailto:APACDataTeam@bsci.com?subject=Feedback%20for%20PSST%20AI">Send Feedback</a></u></p>
      </footer>
    </div>
    // </div>
    
  );
};

export default PlaylistDetail;
