import React, { useState , CSSProperties} from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import register from './register';
import { RegisterUser } from '../../axios/post/register';
import Swal from 'sweetalert2';
import MoonLoader from "react-spinners/MoonLoader";
import axios from 'axios';
import {axios as MyAxios} from '../../axios/config.js'

const Register = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [ConfirmedPassword, setConfirmedPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // If registration is already in progress, do nothing
    if (isRegistering) {
      return;
    }
    // Set the state to indicate that registration is in progress
    setIsRegistering(true);

    if(password === ConfirmedPassword){
      try {
        // Make a POST request to the backend
        await RegisterUser(email, name , password);
        await sendEmail(email, name);

        Swal.fire({
          title: "Welcome",
          text: `Succefully Registered, ${name}`,
          icon: "success",
          allowOutsideClick : false,
          allowEscapeKey : false,
          allowEnterKey : false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/login');
          }
        });    
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There is a user with the same email address!",
          allowOutsideClick : false,
          allowEscapeKey : false,
          allowEnterKey : false,
        })
        // console.error('Error log in user:', error);

      }
    }
    else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password And Confirmed Password Does not match, please check again",
        allowOutsideClick : false,
        allowEscapeKey : false,
        allowEnterKey : false,
      })
    }
    setIsRegistering(false);
    
  };

  const sendEmail = async (email, name) => {
    const message = `
    Dear ${name},\n\n <br/><br/>` +
        `Welcome to PSST AI! Your account has been successfully created. <br/>\n\n` +
        `Get started by logging into your account <a href="`+process.env.REACT_APP_CLIENT+`/login">HERE</a> <br/><br/>\n\n`;

      let dataSend = {
        email: email,
        subject: 'PSST AI | Welcome!',
        message: message,
      };
      try {
        // const res = await axios.post('http://54.254.132.160:5000/email/send-email', dataSend, {
        const res = await MyAxios.post('/email/send-email', dataSend, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (res.status >= 200 && res.status < 300) {
          console.log('Email sent successfully to:', email);
        } else {
          console.error('Error sending email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
  };

  function HandleLogin(event){
    event.preventDefault();

    navigate('/login');
    // navigate('/upload_progression',{state: formData});

  };
  return (
    <div className='container mx-auto px-4 h-screen flex justify-center items-center font-["Arial"]'>
        <form
          className='w-[400px] h-[525px] border-1 border-gray-500 border-t-0 flex flex-col p-[20px]'
          style={{ boxShadow: '2px 6px 11px 0px grey' }}
          onSubmit={handleSubmit}>
          <label className='pb-[10px]'>
            <strong>Your Email</strong>
          </label>
          <input
            className='border-[0.5px] border-gray-500 h-[45px] rounded-[3px] p-[10px] mb-[10px]'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label className='pb-[10px]'>
            <strong>Your Name</strong>
          </label>
          <input
            className='border-[0.5px] border-gray-500 h-[45px] rounded-[3px] p-[10px]'
            placeholder='e.g John Doe'
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label className='pb-[10px] mt-[20px]'>
            <strong>Password</strong>
          </label>
          <input
            className='border-[0.5px] border-gray-500 h-[45px] rounded-[3px] p-[10px]'
            type='password'
            minLength="6"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <label className='pb-[10px] mt-[20px]'>
            <strong>Confirm Password</strong>
          </label>
          <input
            className='border-[0.5px] border-gray-500 h-[45px] rounded-[3px] p-[10px]'
            type='password'
            minLength="6"
            value={ConfirmedPassword}
            onChange={(e) => setConfirmedPassword(e.target.value)}
          />

          <button className='h-[45px] bg-[#003366] text-white mt-[40px] border rounded-[3px] border-[#003366]' type='submit' disabled={isRegistering}>
            Register
          </button>
          <div className='text-[12px] flex flex-row justify-center mt-[5px] text-[#003366]'>
              <a onClick={HandleLogin} href='#'><span>Already Have an Account?</span></a>
          </div>
        </form>
    </div>
  );
};

export default Register;
