import {axios} from "../config";

export const Update_Delete_Status = async (ids) => {
  const update_delete_status = await axios.put(`/api/update_delete_status/${ids}`,{
    // user_id: parseInt(user_id),
    ids: ids.map(id => parseInt(id)),
  }).then((a) => {
    return a;
  })

  return update_delete_status;
}
