import {axios} from "../config";

export const Resetpassword = async (email, password) => {
  const login = await axios.post('/auth/reset_password',{
    email: email.toLowerCase(),
    password: password,
  }).then((a) => {
    return a;
  })

  return login;
}