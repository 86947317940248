import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { RegisterUser } from '../../axios/post/register';
import { Resetpassword } from '../../axios/post/ResetPassword';
import Swal from 'sweetalert2';

const ResetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Resetpassword(email, password)

      Swal.fire({
        title: "Success",
        text: `Successfully Reset Password`,
        icon: "success",
        allowOutsideClick : false,
        allowEscapeKey : false,
        allowEnterKey : false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });  
    } catch (error) {
      console.error('Error Updating users Password:', error);
    }
    
  };

  function HandleLogin(event){
    event.preventDefault();

    navigate('/login');
    // navigate('/upload_progression',{state: formData});

  };
  return (
    <div className='container mx-auto px-4 h-screen flex justify-center items-center font-["Arial"]'>
      <form
        className='w-[400px] h-[330px] border-1 border-gray-500 border-t-0 flex flex-col p-[20px]'
        style={{ boxShadow: '2px 6px 11px 0px grey' }}
       onSubmit={handleSubmit}>
        <label className='pb-[10px]'>
          <strong>Your Email</strong>
        </label>
        <input
          className='border-[0.5px] border-gray-500 h-[45px] rounded-[3px] p-[10px] mb-[10px]'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label className='pb-[10px]'>
          <strong>Your New Password</strong>
        </label>
        <input
          className='border-[0.5px] border-gray-500 h-[45px] rounded-[3px] p-[10px]'
          type='password'
          value={password}
          minLength="6"
          onChange={(e) => setPassword(e.target.value)}
        />

        <button className='h-[45px] bg-[#003366] text-white mt-[40px] border rounded-[3px] border-[#003366]' type='submit'>
          Update Password
        </button>
        <div className='text-[12px] flex flex-row justify-center mt-[5px] text-[#003366]'>
            <a onClick={HandleLogin} href='#'><span>Already Have an Account?</span></a>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
