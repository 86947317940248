import React,{ useState, useEffect} from 'react';
import {useNavigate,useLocation} from "react-router-dom";
import Header from '../../components/atoms/header/Header';
import Keywordlist from '../../components/molecules/keyword/Keywordlist';
import VideoDet from '../../components/molecules/video-detail/VideoDetail';
import Button from '../../components/atoms/button/Button';
import Avatar from '../../components/atoms/avatar/Avatar';
import ModalTextButton from '../../components/atoms/modal/ModalTextButton';
import { GetAllKeywords } from '../../axios/get/keyword';
// import { trackFileUpload } from '../../xmlhttprequest/trackFileUpload.js';
// import { GetSSOUser } from '../../axios/get/get_sso_user.js';
import axios from 'axios';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {axios as Myaxios} from '../../axios/config.js'
// import AWS from 'aws-sdk'

// import {JSONtoCSVConverter} from 'react-json-csv-convert'
// import { useTable } from "react-table";
// import { CSVLink } from "react-csv";
// import Papa from "papaparse"
import { useParams } from 'react-router-dom';
import { last } from 'lodash';
// import { GetAllUsers } from '../../axios/get/users';
// import ProgressBar from '../../components/atoms/progressBar/ProgressBar';

const UploadProgression = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const user_id = sessionStorage.getItem('user_id');
  const { id } = useParams();

  const controller = new AbortController()
  const CancelToken = axios.CancelToken
  const cancelTokenSource = CancelToken.source()

  // const  WS_URL ="ws://54.254.132.160:8000/progress-websocket"
  // const  WS_URL =process.env.REACT_APP_WEBSOCKET

  // const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
  //   WS_URL,
  //   {
  //     queryParams: {
  //       user_id,
  //       id
  //     },
  //     share: false,
  //     shouldReconnect: () => true,
  //   },
  // )
  // console.log("Done Using Websocket")
  // useEffect(()=>{
  //   try{
  //     console.log(WS_URL)
  //     console.log("WS_URLWS_URLWS_URLWS_URLWS_URLWS_URLWS_URL")

  //     console.log(ReadyState)
  //     console.log("readyStatereadyStatereadyStatereadyStatereadyState")
  //     console.log(readyState)
  //     if(readyState === ReadyState.OPEN){
  //       uploadVideo();
  //     }
  //   }catch(error){
  //     console.log(error)
  //     console.log("useEffectreadyStateerroruseEffectreadyStateerror")
  //   }
  // },[readyState])

  // useEffect(() => {
  //   var data = lastJsonMessage;
  //   console.log(data)
  //   console.log("lastJsonMessagelastJsonMessagelastJsonMessagelastJsonMessage")
  //   if(data){
  //     if(data.progress){
  //       setUploadProgress(Math.round((data.progress.loaded/data.progress.total * 50)+50))
  //     }else if(data.successOfUpload){
  //       setUploadProgress(100)
  //     }else{
  //       console.log(data)
  //       console.log("log message purposes")
  //     }
  //   }
  // }, [lastJsonMessage])


  useEffect(() => {
    // Do Not Delete This Code
    function handleOnBeforeUnload(event){
      var formData = new FormData();
      const cancelcom = Myaxios.post('/api/v1/remove-video-trace', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}})
    }

    window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true});
    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true})
    }
  }, [])

  const ProcessingStyle = {
    width: '287px',
    height: '53px',
    // backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '8px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize:'20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
  }

  const BacktoPLaylistStyle = {
    width: '140px',
    height: '40px',
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '8px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize:'16px',
    marginTop:'20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
  }

  const DuplicateKeywordStyle = {
    width: '140px',
    height: '40px',
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '8px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize:'16px',
    marginTop:'20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
  }

  const SmallStyle = {
    width: '0px',
    height: '0px',
    // backgroundColor: '#003c71',
    // color: 'white',
    // cursor: 'pointer',
    borderRadius: '0px',
    // border: 'none',
    // alignItems: 'center',
    // justifyContent: 'center',
    fontSize:'0px',
    marginTop:'0px',
    // fontFamily: 'Arial, Helvetica, sans-serif',
  }

  var i =0;
  const [videoTitle, setVideoTitle] = useState();
  const [videoDescription, setVideoDescription] = useState();
  const [masterChosenKeywords, setMasterChosenKeywords] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [chosenCategory, setChosenCategory] = useState()
  const [masterCategoryKeyword, setMasterCategoryKeyword] = useState([]);

  const [checkedReviewers, setCheckedReviewers] = useState([]);

  const handleCheckboxChange = (ReviewerId) => {
    if (!checkedReviewers.includes(ReviewerId.id)) {
      // If not found, add the keywordId to the array
      setCheckedReviewers([...checkedReviewers, ReviewerId.id]);
    } else {
      // If found, remove the ReviewerId from the array
      const newCheckedKeywords = checkedReviewers.filter((id) => id !== ReviewerId.id);
      setCheckedReviewers(newCheckedKeywords);
    }
  };

  const [selectAll, setSelectAll] = useState(false);
  // const [checkedKeywords, setCheckedKeywords] = useState([]);

  // const handleCheckboxChangeKeyword = (KeywordId, existingKeywords) => {
  //   if (!checkedKeywords.includes(KeywordId)) {
  //     // If not found, add the keywordId to the array
  //     setCheckedKeywords([...checkedKeywords, KeywordId]);
  //   } else {
  //     // If found, remove the KeywordId from the array
  //     const newCheckedKeywords = checkedKeywords.filter((id) => id !== KeywordId);
  //     setCheckedKeywords(newCheckedKeywords);
  //   }
  // };

  const sendChunkToServer = async (chunk) => {
    const res = await Myaxios.post('/api/v1/send-video-s3', chunk, {params: {video_id:id}},{headers: {'Content-Type':'application/octet-stream'}});
    if(!res.status == 200){
      throw new Error('Upload failed');
    }
  }

  const uploadVideo = async () => {
    // if(state===null || state===undefined){
    //   navigate('/upload')
    // }else{
      // setUploadProgress(10)
      // var formDataVideo = new FormData();
      // const reader = new FileReader()
      const uploadedFile = state.uploadedFile
      // formDataVideo.append("file",uploadedFile)
      // setUploadProgress(30)
      try{
        const chunkSize = 10 * 1024 * 1024; // 6MB chunks (adjust as needed)
        // let start = 0;
        // let end = Math.min(chunkSize, uploadedFile.size);
        // reader.onload = (event) => {
        //   const chunk = event.target.result;
        //   sendChunkToServer(chunk)

        //   var nextStart = reader.result.byteLength;
        //   var end = Math.min(nextStart + chunkSize, uploadedFile.size);
        //   if (end < uploadedFile.size){
        //     reader.readAsArrayBuffer(uploadedFile.slice(nextStart,end));
        //   }else{
        //     reader.readAsArrayBuffer(uploadedFile.slice(nextStart));
        //   }


        // }

        // reader.readAsArrayBuffer(uploadedFile.slice(0,chunkSize));
        const chunks = [];

        var finalSizeTrack = 0

        for (let start = 0; (uploadedFile.size - start) > 2*chunkSize; start += chunkSize) {
          const end = Math.min(start + chunkSize, uploadedFile.size);
          const chunk = uploadedFile.slice(start, end);
          finalSizeTrack += chunkSize
          chunks.push(chunk);
        }

        const finalChunk = uploadedFile.slice(finalSizeTrack, uploadedFile.size)

        chunks.push(finalChunk)

        // reader.readAsDataURL(uploadedFile)
        // const res = await axios.post('https://boston-backend.sgeede.com/api/v1/upload-video', formDataVideo, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}})
        // const res = await Myaxios.post('/api/v1/upload-video', formDataVideo, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}})
        

        for (let i = 0; i < chunks.length; i++) {
          const formData = new FormData();
          formData.append('file', chunks[i]);
          // formData.append('partIndex', i); // Optional: track part index for server-side assembly

          if(i+1 < chunks.length){
            const res = await Myaxios.post('/api/v1/upload-video', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}})
            if(!res.status==200){
              var percentage_element = document.getElementById("progress_count");
              percentage_element.innerHTML = percentage_element.innerHTML = "Your video upload process has been interupted. Please refresh the page to try again";
              console.log(res, " res of multipart upload gemini")
              throw new Error(`Upload failed with status ${res.status}`);
            }else{
                setUploadProgress(Math.round((i+1)/chunks.length*90))
              // upload_finished = true;
            }
          }else{
            const res = await Myaxios.post('/api/v1/upload-video', formData, {params: {video_id:id,last_chunk:true}},{headers: {'Content-Type':'multipart/form-data'}})

            if(!res.status==200){
              var percentage_element = document.getElementById("progress_count");
              percentage_element.innerHTML = percentage_element.innerHTML = "Your video upload process has been interupted. Please refresh the page to try again";
              console.log(res, " res of multipart upload gemini")
              throw new Error(`Upload failed with status ${res.status}`);
            }else{
              setUploadProgress(100)
              // upload_finished = true;
            }
          }
          
    
          // var res = await fetch(process.env.REACT_APP_BACKEND + `/api/v1/upload-video?${queryParams}`,{
          //   method:'POST',
          //   body:formData,
          // });

    
          // if (!res.ok) {
          //   console.log(res, " res of multipart upload gemini")
          //   throw new Error(`Upload failed with status ${res.status}`);
          // }else{
          // }
          // if(!res.status==200){
          //   var percentage_element = document.getElementById("progress_count");
          //   percentage_element.innerHTML = percentage_element.innerHTML = "Your video upload process has been interupted. Please refresh the page to try again";
          //   console.log(res, " res of multipart upload gemini")
          //   throw new Error(`Upload failed with status ${res.status}`);
          // }else{
          //   if(res.response.final==='true'){
          //     setUploadProgress(100)
          //   }else{
          //     setUploadProgress(Math.round((i+1)/chunks.length*90))
          //   }
          //   // upload_finished = true;
          // }


          // console.log("chunk number :" + i.toString())
          // console.log(res,"this is res from sending upload video")
    
          // Update progress bar or provide user feedback (optional)
        }
        // const uploadStart = axios.post(
        //   'http://54.254.132.160:5000/api/v1/video-progress',
        // const uploadStart = Myaxios.post(
        //   '/api/v1/video-progress',
        //   formDataVideo,
        //   {
        //     params: { video_id: id },
        //     headers: { 'Content-Type': 'multipart/form-data' },
        //     // signal:controller.signal,
        //     cancelToken:cancelTokenSource.token,
        //     onUploadProgress: (p) => {
        //       const percentCompleted = Math.round((p.loaded * 50) / p.total);
        //       setUploadProgress(percentCompleted);
        //       console.log(percentCompleted);
        //     },
        //     // signal:controller.signal
        //   }
        // );
        // cancelTokenSource.cancel('Cancel Upload');
        // controller.abort()


        // if(uploadStart.status==200){
        //   setUploadProgress(100);
        // }
      } catch(error){
        var percentage_element = document.getElementById("progress_count");
        percentage_element.innerHTML = percentage_element.innerHTML = "Your video upload process has been interupted. Please refresh the page to try again";
        console.log(error)
        console.log("errorerrorerrorerrorerrorerror")
        // setUploadProgress(0);
      }
    // }
  }
  
  const updateVideoData = async () => {
    var formData = new FormData();
    var json_to_string = {}
    var listExistKeywords = masterCategoryKeyword.map((stateItem) => stateItem.masterState.keywords)
    var selectedList = listExistKeywords.flat().filter((keywordState)=> {return keywordState.select==true}).map((item) => item.keywordItem)
    var selectedKeywords = selectedList.map((keywordId) => keywordId.id)
    json_to_string["video-title"] = videoTitle
    json_to_string["video-description"] = videoDescription
    json_to_string["video-reviewers"] = checkedReviewers
    json_to_string["video-keywords"] = selectedKeywords
    var string_json = JSON.stringify(json_to_string)
    formData.append("video-details",string_json)

    // const res_json = await axios.post('https://boston-backend.sgeede.com/api/v1/update-video-data', formData, {params: {video_id:id,division_id:state.chosenDivision}},{headers: {'Content-Type':'multipart/form-data'}})
    // const res_json = await axios.post('http://54.254.132.160:5000/api/v1/update-video-data', formData, {params: {video_id:id,division_id:state.chosenDivision}},{headers: {'Content-Type':'multipart/form-data'}})
    const res_json = await Myaxios.post('/api/v1/update-video-data', formData, {params: {video_id:id,division_id:state.chosenDivision}},{headers: {'Content-Type':'multipart/form-data'}})

  }

  const filterChosenKeywords = async (allKeywords) => {
    var keywordsToJson = []
    allKeywords.forEach((keyword) => {
      keywordsToJson.push({"keywords":keyword.keyword_name,"category" : keyword.psst_category.category_code})
      })

    return keywordsToJson
  } 

  const uploadCSVJSON = async () => {

    var filteredChosen = [];
    var listExistKeywords = masterCategoryKeyword.map((stateItem) => stateItem.masterState.keywords);
    var selectedList = listExistKeywords.flat().filter((keywordState)=> {return keywordState.select==true}).map((item) => item.keywordItem);

    const filteringData = filterChosenKeywords(selectedList).then((filterResult)=> {
      const JSONFile = new Blob([JSON.stringify(filterResult)], { type:"text/json"});
     
      var formData = new FormData();
      formData.append("file",JSONFile)
      formData.append("video_id",id)
      //////////////////////////////// For CSV conversion data
      // const CSVFileData = Papa.unparse(upload_data_test)
      // const CSVFile = new Blob([CSVFileData], { type:"text/csv"})
      //////////////////////////////// For CSV conversion data

      // formData.append("file",CSVFile)
          try{
            // const res_json = axios.post('http://54.254.132.160:5000/api/v1/upload-keyword', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}}). then((api_response) => {
            const res_json = Myaxios.post('/api/v1/upload-keyword', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}}). then((api_response) => {
            // const res_json = Myaxios.post('/api/v1/upload-keyword', formData,{headers: {'Content-Type':'multipart/form-data'}}). then((api_response) => {
            // const res_json = axios.post('https://boston-backend.sgeede.com/api/v1/upload-keyword', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}}). then((api_response) => {
              if(api_response.status == 200){
                const res_sagemaker_api = axios.post('https://j289u2fwz3.execute-api.ap-southeast-1.amazonaws.com/default/trigger_pipeline', {
                  "pipeline_trigger": "new",
                  "video_id": id,
                  "environment": "sandbox"
                }).then((api_call_result) => {
                  setIsBlockUIOpen(false);
                  setBackToPlaylistOpen(true);
                });
              }
            }); 
          } catch(error){
            console.log(error)
          }
      });

  }

  const user_name = sessionStorage.getItem('user_name');

  const username = user_name; 

  const initials = username && username
    .split(' ')
    .slice(0, 2)
    .map(word => word[0])
    .join('') 

  useEffect(()=> {
    const reallow = Myaxios.post('/api/v1/reallow-upload', {
        id:id,
        uploader_id: user_id,
        division_id:state.chosenDivision 
      })
      
    
    handleCheckboxChange({id:Number(user_id)});
    var elem = document.getElementById("progressbar");
    var percentage_element = document.getElementById("progress_count");

    var submitBtnElem = document.getElementById("submit-btn")
    submitBtnElem.style.backgroundColor = "#A9A9A9"
    handleVideoTitleChange(state.fileName)

    elem.style.width = uploadProgress + '%';
    percentage_element.innerHTML = percentage_element.innerHTML = "Your video is being uploaded... (" + uploadProgress + "%)";
    // if(is_sso == null){
    //   FetchSSOUser();
    // }
    // fetchDivisionsData();
    uploadVideo();
}, []);


useEffect(()=> {
  var elem = document.getElementById("progressbar");
  var percentage_element = document.getElementById("progress_count");
  elem.style.width = uploadProgress + '%';
  percentage_element.innerHTML = "Your video is being uploaded... (" + uploadProgress + "%)";
},[uploadProgress])


useEffect(()=> {
  var submitBtnElem = document.getElementById("submit-btn")
  const mandatoryCategory = ['BSC_PROD','COMPETITOR','SPELLING']
  var filteredMandatoryCategoryKeyword = masterCategoryKeyword.filter((stateItem) => {return mandatoryCategory.includes(stateItem.masterState.category.category_code)})
  var listExistKeywords = filteredMandatoryCategoryKeyword.map((stateItem) => stateItem.masterState.keywords)
  var selectList = listExistKeywords.flat().map((keywordState) => keywordState.select)
  let checker = selectList.every(state => state ==false)
  if(videoTitle && checkedReviewers.length > 0 && (!checker) && uploadProgress>=100){
    submitBtnElem.style.backgroundColor = "#003c71"
  }else{
    submitBtnElem.style.backgroundColor = "#A9A9A9"
  }
},[videoTitle, checkedReviewers, masterCategoryKeyword,uploadProgress,selectAll])

  const [isBackToPlaylistOpen, setBackToPlaylistOpen] = useState(false);
  const [isMissingTitleDescriptionOpen, setIsMissingTitleDescriptionOpen] = useState(false);


    const handleIsMissingTitleDescriptionClose = () => {
      setIsMissingTitleDescriptionOpen(false);
  }

  const handleBackToPlaylistModal = () => {
    const mandatoryCategory = ['BSC_PROD','COMPETITOR','SPELLING']
    var filteredMandatoryCategoryKeyword = masterCategoryKeyword.filter((stateItem) => {return mandatoryCategory.includes(stateItem.masterState.category.category_code)})
    var listExistKeywords = filteredMandatoryCategoryKeyword.map((stateItem) => stateItem.masterState.keywords)
    var selectList = listExistKeywords.flat().map((keywordState) => keywordState.select)
    let checker = selectList.every(state => state ==false)
    if(uploadProgress < 100){
      return
      // setErrorText("Please wait till Upload is Complete!")    
      // setIsMissingTitleDescriptionOpen(true);
      // }else if(checkedReviewers.length==0){
        //   setErrorText("Please make sure the reviewers are selected!")    
        //   setIsMissingTitleDescriptionOpen(true);
    }else if(!videoTitle || checkedReviewers.length==0 || (checker)){
      setErrorText("Please make sure All the requirements are filled!")    
      setIsMissingTitleDescriptionOpen(true);
    }else{
      setIsBlockUIOpen(true);
      uploadCSVJSON();
      updateVideoData();
    }
  };
  


  const handleBackToPlaylist = () => {
    navigate('/playlist');
  }

  const handleVideoTitleChange = (value) =>{
    setVideoTitle(value);
  }

  const handleVideoDescriptionChange = (value) =>{
    setVideoDescription(value);
  }


  const handleCancelUpload = async () => {
    try{
      var formData = new FormData();
      controller.abort();
      cancelTokenSource.cancel('Upload Cancelled!!')
      // const res_json = await axios.post('http://54.254.132.160:5000/api/v1/remove-video-trace', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}})
      const res_json = await Myaxios.post('/api/v1/remove-video-trace', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}})
      // const res_json = await axios.post('https://boston-backend.sgeede.com/api/v1/remove-video-trace', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}})
      navigate('/playlist')
    }catch(err){
      console.log(err)
    }

    // navigate('/playlist')
  }

  


  const [isDuplicateKeywordOpen, setDuplicateKeywordOpen] = useState(false);
  
  const [errorText, setErrorText] = useState();

  const handelDuplicateKeyword = (keywordAdd) => {
    setErrorText("The Keyword you are adding is already exist!")    
    setDuplicateKeywordOpen(true);

  };

    const handleDuplicateKeywordClose = () => {
    setDuplicateKeywordOpen(false);
  }
  
  const [isBlockUIOpen, setIsBlockUIOpen] = useState(false);

  const NoHandle = () => {

  }


  return (
  <>
    <div className="container mx-auto px-1 h-screen">
      <Header type={'Upload'} invisiblePlaylist={true} onClickLogout={handleCancelUpload} addClickLogout={true} logout={<Avatar initials={initials}/>}/>
      <form>
        <div className='flex flex-col xl:flex-row font-["Arial"]'>
          <div className='min-[1280px]:mr-[100px] w-[810px] min-[1294px]:mr-[10px] 2xl:mr-[50px] 2xl:w-[1050px]'
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
          <div className='h-[455.63px] border mb-[30px] 2xl:h-[590.63px]' style={{width:'inherit'}}>
            <div className='text-center video-wrapper' style={{ height: 'inherit', display:'flex', backgroundColor: '#F2F2F2',flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
              <div id="progressbar_case" className="mx-auto bg-slate-500 w-[600px] h-[8px] rounded ">
                <div id="progressbar" className="bg-red-600 h-[9px] rounded">
                  <span className="opacity-0">Transparant text</span>
                </div>
              </div>
              <span id="progress_count" className="text-center opacity-50 pt-8 pb-16"></span>
              <span className="text-center opacity-50 w-[700px] pb-3">Select <b>at least one keyword</b> from any of these categories: BSC Product, Competitor, or Spelling. Once reviewers and keywords are updated, proceed to submit the video for AI processing</span>
              <Button
                id="submit-btn"
                onClick={handleBackToPlaylistModal}
                style={ProcessingStyle}
                text={'Submit for AI Processing'}
              />
              <ModalTextButton
                isOpen={isBackToPlaylistOpen}
                ModalStyle={{
                  background: '#fff',
                  width: '511.59px',
                  height: '261.59px',
                  padding: '60px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection:'column',
                }}
                text={'You have successfully added this video to the AI processing queue'}
                text_style = {{fontSize:'24px'}}
                showFaCheck = {true}
                Button={<Button onClick={handleBackToPlaylist} style={BacktoPLaylistStyle} text={'Back to Playlist'}/>}
              />
              <span className="text-center opacity-50 pt-3">Uploaded by mistake? <u onClick={handleCancelUpload} className="cursor-pointer">Cancel upload</u></span>
            </div>
            <ModalTextButton
              isOpen={isDuplicateKeywordOpen}
              ModalStyle={{
                background: '#fff',
                width: '480px',
                height: '220px',
                padding: '50px',
                borderRadius: '8px',
                textAlign: 'center',
                display: 'flex',
                flexDirection:'column',
              }}
              text={errorText}
              showFaCheck = {false}
              text_style = {{fontSize:'24px'}}
              Button={<Button onClick={handleDuplicateKeywordClose} style={DuplicateKeywordStyle} text={'Return'}/>}
            />
            <ModalTextButton
              isOpen={isMissingTitleDescriptionOpen}
              ModalStyle={{
                background: '#fff',
                width: '480px',
                height: '220px',
                padding: '50px',
                borderRadius: '8px',
                textAlign: 'center',
                display: 'flex',
                flexDirection:'column',
              }}
              text={errorText}
              showFaCheck = {false}
              text_style = {{fontSize:'24px'}}
              Button={<Button onClick={handleIsMissingTitleDescriptionClose} style={DuplicateKeywordStyle} text={'Return'}/>}
            />
            <ModalTextButton
              isOpen={isBlockUIOpen}
              ModalStyle={{
                background: '#fff',
                width: '0px',
                height: '0px',
                padding: '0px',
                borderRadius: '0px',
                textAlign: 'center',
                display: 'flex',
                flexDirection:'column',
              }}
              text={''}
              showFaCheck = {false}
              text_style = {{fontSize:'0px'}}
              Button={<Button onClick={NoHandle} style={SmallStyle} text={''}/>}
            />
          </div>

            <VideoDet video_data={{video_title:state.fileName}} onChangeTitle={handleVideoTitleChange} onChangeDetail={handleVideoDescriptionChange} video_id={id} division_id={state.chosenDivision} isModal={false} onReviewersDataChanges={handleCheckboxChange} checkedKeywords={checkedReviewers} updateCheckedKeywords={(updatedKeywords) => setCheckedReviewers(updatedKeywords)} />
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
             <Keywordlist title={"*Keyword List"} video_id={id} openDuplicateKeywordModal={handelDuplicateKeyword} division_id={state.chosenDivision} user_id={user_id} masterCategoryKeyword={masterCategoryKeyword} setMasterCategoryKeyword={setMasterCategoryKeyword} setUIBlock={setIsBlockUIOpen} chosenCategory={chosenCategory} setChosenCategory={setChosenCategory} intitialEmpty={true}/>
          </div>
        </div>
      </form>
    </div>
  </>
    
  );
};
export default UploadProgression;
