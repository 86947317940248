import {axios} from "../config";

export const GetVttDownload = async (video_id) => {
  const videos = await axios.post('/api/v1/vtt', {
    video_id : video_id
  }).then((a) => {
    return a;
  })

  return videos;
}