import {axios} from "../config";

export const DeleteReviewers = async (user_id , video_id) => {
  const TranscriptComment = await axios.delete('/api/deletereviewers',{
    data:{
      user_id: parseInt(user_id),
      video_id: parseInt(video_id),
    }
  }).then((a) => {
    return a;
  })

  return TranscriptComment;
}