import {axios} from "../config";

export const Update_Transcript_Data = async (id, edited_text) => {
  const TranscriptUpdate = await axios.put('/api/update_transcript_data',{
    id: parseInt(id),
    transcript_text : edited_text,
    is_changed : true,
  }).then((a) => {
    return a;
  })

  return TranscriptUpdate;
}