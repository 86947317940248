import {axios} from "../config";

export const Update_Video = async (id, video_data, checkedReviewers) => {
  const update_video = await axios.put('/api/update_video',{
    id: parseInt(id),
    video_data : video_data,
    reviewers: checkedReviewers,
  }).then((a) => {
    return a;
  })

  return update_video;
}