import React, { useState } from 'react';
import { GoX } from 'react-icons/go';

const SSOLogin = () => {
  const [IsBsciOffline, setIsBsciOffline] = useState(true);

  const handleCloseBsciOffline = () => {
    setIsBsciOffline(false);
  };

  const modalOverlay = {
    position: 'fixed',
    top: '0px',
    width: '100%',
    left: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    zIndex: 1,
  };

  const modal = {
    background: '#FFFDF0',
    width: '50%',
    // height: '35%',
    padding: '20px',
    borderRadius: '6px',
    marginTop: '20px'
  };

  const boldStyle = {
    color: '#003c71',
    fontSize: '36px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontWeight: '800',
    // wordWrap: 'break-word',
    // display: 'flex',
  };
  const lineStyle = {
    color: '#003c71',
    fontSize: '36px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontWeight: '400',
    // wordWrap: 'break-word',
    // display: 'flex',
  };

  return (
    <div className='md:container md:mx-auto container mx-auto px-4 h-screen flex justify-center items-center font-["Arial"]'>
      {IsBsciOffline && (
        <div style={modalOverlay}>
          <div className='w-[40%] 2xl:w-[25%]' style={modal}>
            <div className='flex flex-row-reverse'>
                <button onClick={handleCloseBsciOffline} style={{zIndex: 1}}>
                  <GoX />
                </button>
            </div>
            <div className='flex flex-row items-center'>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1655_840)">
              <path d="M25.0001 29.6111C25.479 29.6111 25.9382 29.4209 26.2768 29.0823C26.6154 28.7437 26.8057 28.2844 26.8057 27.8056V19.4444C26.8057 18.9656 26.6154 18.5063 26.2768 18.1677C25.9382 17.8291 25.479 17.6389 25.0001 17.6389C24.5212 17.6389 24.062 17.8291 23.7234 18.1677C23.3848 18.5063 23.1945 18.9656 23.1945 19.4444V27.7778C23.1909 28.0172 23.2348 28.255 23.3239 28.4773C23.413 28.6995 23.5454 28.9019 23.7135 29.0725C23.8815 29.2431 24.0818 29.3786 24.3027 29.4711C24.5236 29.5635 24.7606 29.6111 25.0001 29.6111Z" fill="#F24E1E"/>
              <path d="M24.9305 35.7917C26.0811 35.7917 27.0138 34.8589 27.0138 33.7083C27.0138 32.5577 26.0811 31.625 24.9305 31.625C23.7799 31.625 22.8472 32.5577 22.8472 33.7083C22.8472 34.8589 23.7799 35.7917 24.9305 35.7917Z" fill="#F24E1E"/>
              <path d="M42.1249 35.4722L28.5971 10.5556C28.239 9.89753 27.71 9.34822 27.066 8.9655C26.4219 8.58277 25.6866 8.38077 24.9374 8.38077C24.1882 8.38077 23.4529 8.58277 22.8088 8.9655C22.1648 9.34822 21.6358 9.89753 21.2777 10.5556L7.73599 35.4722C7.38156 36.1084 7.20006 36.8264 7.20959 37.5546C7.21911 38.2828 7.41934 38.9958 7.7903 39.6225C8.16127 40.2492 8.69001 40.7677 9.32383 41.1264C9.95765 41.4851 10.6744 41.6714 11.4027 41.6667H38.4582C39.1805 41.6673 39.8905 41.4802 40.5187 41.1237C41.1469 40.7672 41.6716 40.2535 42.0413 39.633C42.4111 39.0126 42.6132 38.3067 42.6278 37.5845C42.6425 36.8624 42.4692 36.1488 42.1249 35.5139V35.4722ZM39.6527 38.1667C39.5297 38.374 39.355 38.5458 39.1455 38.6651C38.9361 38.7844 38.6992 38.8472 38.4582 38.8472H11.4027C11.1612 38.8479 10.9238 38.7856 10.7138 38.6665C10.5038 38.5475 10.3284 38.3757 10.2051 38.1682C10.0817 37.9607 10.0145 37.7246 10.0102 37.4832C10.0059 37.2418 10.0646 37.0035 10.1804 36.7917L23.7082 11.875C23.8272 11.6545 24.0037 11.4703 24.2188 11.342C24.434 11.2136 24.6799 11.1458 24.9304 11.1458C25.181 11.1458 25.4269 11.2136 25.642 11.342C25.8572 11.4703 26.0337 11.6545 26.1527 11.875L39.6804 36.7917C39.7956 37.0036 39.8536 37.2418 39.8487 37.4829C39.8438 37.7239 39.7763 37.9596 39.6527 38.1667Z" fill="#F24E1E"/>
              </g>
              <defs>
              <clipPath id="clip0_1655_840">
              <rect width="50" height="50" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <p className='text-[16px]'>
            Due to technical issues, PSST AI is currently not operational on the BSC network.
            </p>
            </div>
          </div>
        </div>
      )}
      <form
      // onSubmit={handleSubmit}
        className='w-[450px] h-[280px] border border-t-0 flex flex-col p-[35px]'
        style={{ boxShadow: '2px 6px 11px 0px grey' }}
        action={`${process.env.REACT_APP_BACKEND}/auth/loginSAML`}
        method="POST" // Set the form method to POST
      >
        <div className='flex'>
          <span style={boldStyle}>PSST</span>
          <span className='pl-[10px]' style={lineStyle}>
            AI
          </span>
        </div>
        <div className='flex flex-col justify-between items-center'>
          <button className='h-[45px] bg-[#003366] text-white mt-[40px] border rounded-[6px] border-[#003366] w-[80%] font-["Arial"] text-[15px]' type="submit">
              <span>Login with SSO</span>
          </button>
          <div className='text-[12px] flex flex-col justify-center items-center mt-[50px]' >
              <p>Don't have an account? Request addition <u><a href='https://bostonscientific.sharepoint.com/sites/AutomateIT/SitePages/ADGroupMembership.aspx'>here</a></u> to:</p>
              <p><b>BSC-PSST-AI-USERS-SNG</b></p>
          </div>
        </div>
        
      </form>
    </div>
  );
};

export default SSOLogin;
