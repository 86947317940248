import {axios} from "../config";

export const Update_Video_Status = async (id) => {
  const update_video_status = await axios.put('/api/update_video_status',{
    // user_id: parseInt(user_id),
    id: parseInt(id),
  }).then((a) => {
    return a;
  })

  return update_video_status;
}