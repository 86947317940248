import React from 'react';
import Button from '../button/Button';
import { GoX } from 'react-icons/go';

const DisclaimerModal = ({ isOpen, onClose }) => {
  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  };

  const modal = {
    background: '#fff',
    width: '25%',
    padding: '20px',
    borderRadius: '6px',
  };

  const downloadStyle = {
    width: '280px',
    height: '40px',
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
  };
  const closeStyle = {
    backgroundColor: 'transparent',
    color: 'black',
    cursor: 'pointer',
    border: 'none',
    textAlign: 'right',
    width: '2px',
  };

  return (
    isOpen && (
      <div style={modalOverlay}>
        <div style={modal}>
          <div className='flex px-[30px] items-center justify-center flex-col'>
            <p className='text-[12px]'>
              <strong>Disclaimer:</strong> PSST AI for video is used exclusively for the purpose of reviewing video content, 
              aimed at making the video review process easier and more efficient. 
              The requirements and functionalities provided by this tool are currently
              based on needs specific to review process of webinars intended for APAC Educare. 
              This tool does not replace, alter, or interfere with any existing PSST processes or procedures already established, 
              and is not intended for use in any other capacity, 
              including but not limited to, content creation or video editing.   
            </p>
            <Button
              onClick={onClose}
              style={downloadStyle}
              text={'I understand. Go to Playlist'}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default DisclaimerModal;
