import {axios} from "../config";

export const AddTranscriptAuditLog = async (transcript_id, original_text, edited_text, user_id) => {
  const TranscriptAuditLog = await axios.post('/api/addtranscript_audit_log',{
    transcript_id: parseInt(transcript_id),
    original_text : original_text,
    edited_text: edited_text,
    edited_by: user_id,
  }).then((a) => {
    return a;
  })

  return TranscriptAuditLog;
}