import React, { useState } from 'react';
import Button from '../button/Button';
import ConfirmedModal from './Confirmed';
import { GoX } from 'react-icons/go';

// const ActionsModal = ({ isOpen, onClose, onConfirm, onOpenVideoDetail,  , showConfirmedModal, activeOnConfirm, onUndoReview }) => {
const ActionsModal = ({ isOpen, onClose, onConfirm, onOpenVideoDetail, DownloadReportPreview , onVttDownload , showConfirmedModal, activeOnConfirm, onUndoReview }) => {
  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  };

  const modal = {
    background: '#fff',
    // width: '25%',
    // height: '35%',
    padding: '20px',
    borderRadius: '6px',
  };

  const downloadStyle = {
    width: '300px',
    height: '50px',
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
    fontSize: '15px'
  };
  const closeStyle = {
    backgroundColor: 'transparent',
    color: 'black',
    cursor: 'pointer',
    border: 'none',
    textAlign: 'right',
    width: '2px',
  };

  return (
    isOpen && (
      <div style={modalOverlay}>
        <div className='w-[40%] 2xl:w-[25%]' style={modal}>
          <div className='flex flex-row-reverse'>
              <button onClick={onClose}>
                <GoX />
              </button>
          </div>
          <div className='flex px-[30px] items-center justify-center flex-col'>
            <p className='text-[20px]'>What would you like to do today?</p>
            <Button onClick={onOpenVideoDetail} style={downloadStyle} text={'Edit Video Title & Description'} />
            <Button
              onClick={DownloadReportPreview}
              style={downloadStyle}
              text={'Download Report'}
            />
            <Button
              onClick={onVttDownload}
              style={downloadStyle}
              text={'Download Subtitle (.vtt)'}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default ActionsModal;
