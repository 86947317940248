import React from 'react';
import Button from '../button/Button';
import { GoX } from 'react-icons/go';

const DownloadModal = ({ isOpen, onCancel, onConfirm }) => {
  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  };

  const modal = {
    background: '#fff',
    width: 'auto',
    padding: '20px',
    borderRadius: '6px',
  };

  const downloadStyle = {
    width: '280px',
    height: '40px',
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
  };
  const closeStyle = {
    backgroundColor: 'transparent',
    color: 'black',
    cursor: 'pointer',
    border: 'none',
    textAlign: 'right',
    width: '2px',
  };

  return (
    isOpen && (
      <div style={modalOverlay}>
        <div style={modal}>
          <div className='flex justify-between'>
            <div style={{ textAlign: 'left', marginTop: '10px' }}>
              <p>Select an item to download:</p>
            </div>
            <button onClick={onCancel}>
              <GoX />
            </button>
          </div>
          <div className='flex px-[30px] items-center justify-center flex-col'>
            <Button onClick={onConfirm} style={downloadStyle} text={'Report'} />
            <Button
              onClick={onCancel}
              style={downloadStyle}
              text={'Subtitle (.vtt)'}
            />
            <Button
              onClick={onCancel}
              style={downloadStyle}
              text={'Video (260MB)'}
            />

            <p className='text-[12px]'>
              Download video through this platform only when necessary
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default DownloadModal;
