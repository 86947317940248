import {axios} from "../config";

export const Update_Status_Undo_Reviewer = async (user_id, video_id) => {
  const update_status_undo_reviewer = await axios.put('/api/update_status_undo_reviewer',{
    user_id: parseInt(user_id),
    video_id : parseInt(video_id),
  }).then((a) => {
    return a;
  })

  return update_status_undo_reviewer;
}