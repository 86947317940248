import React, { useState } from 'react';
import { FaCheck, FaCheckCircle } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from '../button/Button';
import { useNavigate } from 'react-router-dom';
import ActionsModal from './Actions';

const ConfirmedModal = ({ isOpen, onClose, content, isPlaylist, isComplete, activeOnConfirm, onUndoReview }) => {
  const navigate = useNavigate();
  
  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  };

  const modal = {
    position: 'absolute',
    background: '#fff',
    width: 'auto',
    padding: '20px',
    borderRadius: '6px',
    textAlign: 'center',
  };

  const reminderStyle = {
    width: '140px',
    height: '40px',
    backgroundColor: '#003c71',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '6px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Arial, Helvetica, sans-serif',
  };

  function HandleConfirm(event){
    event.preventDefault();
    if (isPlaylist == false) {
      navigate('/playlist');
    }
    else if (!isComplete) {
      navigate('/playlist');
    }
    onClose();

  };

  const [isActionsModalOpen, setActionsModalOpen] = useState(false);
  const handleOpenActionsModal = () => {
    setActionsModalOpen(true);
    
  };

  return (
    <>
      {isOpen && (
        <div style={modalOverlay}>
          <div style={modal}>
            <div>
              <div className='pl-[30px] flex my-12 items-center justify-center'>
                {/* <FaCheck style={{ color: 'green', fontSize: '4em', fontWeight: 'lighter' }} /> */}
                <FontAwesomeIcon icon={faCheck} style={{ color: 'green', fontSize: '4em'}}/>
                <p className='px-3' style={{ textAlign: 'left' }}>
                {!isComplete ? 'You have marked this review as completed' : content}
                </p>
              </div>
              <div className='flex items-center justify-center'>
                <Button
                  onClick={!isComplete ? HandleConfirm : HandleConfirm}
                  style={reminderStyle}
                  text={'Back to Playlist'}
                />
              </div>
              {!isComplete && (
                <u>
                  <span onClick={() => {
                    onUndoReview();
                    // setActionsModalOpen(true);
                  }} className='mt-7 text-[12px] cursor-pointer'>
                    Mark “Completed” by accident? Click here to undo
                  </span>
                </u>
              )}
              {isActionsModalOpen && (
            <ActionsModal isOpen={isActionsModalOpen} onConfirm={activeOnConfirm} onClose={() => setActionsModalOpen(false)} />
          )}
              {/* {content === 'You have marked this review as completed' ? (
              <u>
                <span className='mt-7 text-[12px]'>
                  Mark "Completed" by accident? Click here to undo
                </span>
              </u>
            ) : (
              <u>
                <span className='mt-7 text-[12px]'>
                  Other text or no text based on the content value
                </span>
              </u>
            )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmedModal;
