import {axios} from "../config";

export const Update_Reviewer = async (user_id, video_id) => {
  const update_reviewer = await axios.put('/api/update_reviewer',{
    user_id: parseInt(user_id),
    video_id : parseInt(video_id),
  }).then((a) => {
    return a;
  })

  return update_reviewer;
}