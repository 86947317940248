import React from 'react';

const Avatar = ({ initials }) => {
  const avatarStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: '#003C71',
    fontWeight: '300',
    fontSize: '24px',
    backgroundColor: '#9AC2FE',
    width: '40px',
    height: '40px',
    marginLeft: '15px',
    fontFamily: 'Arial, sans-serif',
  };

  return (
    <div className='avatar' style={avatarStyle}>
      {initials}
    </div>
  );
};

export default Avatar;
