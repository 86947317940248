import React, {useEffect, useState} from 'react';
import Button from '../button/Button';
import VideoDet from '../../molecules/video-detail/VideoDetail';
import { GoX } from 'react-icons/go';
import { GetVideoByID } from '../../../axios/get/video_by_id';
import { Update_Video } from '../../../axios/put/update_video';
// import axios from 'axios';

const EditVideoDetailReviewModal = ({ isOpen, onCancel, video_id , onConfirm, onSave }) => {

  const modalOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
  };

  const modal = {
    background: '#fff',
    padding: '35px',
    borderRadius: '8px',
  };

  const [editedVideo, setEditedVideo] = useState({
    video_title: '',
    description: '',
    // ... (other fields)
  });
  const [video, setVideo] = useState([]);
  const [checkedKeywords, setCheckedKeywords] = useState([]);

  // UseEffect to send notification when checkedKeywords change
  // useEffect(() => {
  //   if (checkedKeywords.length > 0 && previousReviewers) {
  //     const newReviewers = checkedKeywords.filter(id => !previousReviewers.some(reviewer => reviewer.id === id));
  //     if (newReviewers.length > 0) {
  //       sendNotificationToNewReviewers(newReviewers); // Implement this function to send notification to new reviewers
  //     }
  //   }
  // }, [checkedKeywords]);

  // Function to send notification to new reviewers
  // const sendNotificationToNewReviewers = (newReviewers) => {
    // Implement notification sending logic here
  //   console.log('Sending notification to new reviewers:', newReviewers);
  // };

  // Handle changes in VideoDetail and update editedVideo state
  const handleVideoDetailChange = (field, value) => {
    setEditedVideo(prevState => ({
      ...prevState,
      [field]: value,
    }));
    // console.log('handle change ========== ', value);
  };

  const handleCheckboxChange = (ReviewerId) => {
    // console.log('handle checkbox change ========== ', ReviewerId.id);
    if(ReviewerId.reviewers.length==0){
      if (!checkedKeywords.includes(ReviewerId.id)) {
        // If not found, add the keywordId to the array
        setCheckedKeywords([...checkedKeywords, ReviewerId.id]);
      } else {
        // If found, remove the ReviewerId from the array
        const newCheckedKeywords = checkedKeywords.filter((id) => id !== ReviewerId.id);
        setCheckedKeywords(newCheckedKeywords);
      }
    }else if(!ReviewerId.reviewers[0].review_status){
      if (!checkedKeywords.includes(ReviewerId.id)) {
        // If not found, add the keywordId to the array
        setCheckedKeywords([...checkedKeywords, ReviewerId.id]);
      } else {
        // If found, remove the ReviewerId from the array
        const newCheckedKeywords = checkedKeywords.filter((id) => id !== ReviewerId.id);
        setCheckedKeywords(newCheckedKeywords);
      }
    }
  };

  // const [previousReviewers, setPreviousReviewers] = useState(null);
  const handleupdatevideo = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to the backend
      // setPreviousReviewers(video.reviewers);
      const updated_video = await Update_Video(video_id , editedVideo, checkedKeywords)
      // console.log(updated_video, 'updated video=======?????');
      onSave(updated_video);
      // const reviewerIds = JSON.parse(updated_video.config.data).reviewers;
      // console.log(reviewerIds, 'id dari reviewers');
      // console.log('add or remove reviewer--------------------->>>>>>>>>>>>>>>>>>>>');
      // const reviewerIdsArray = reviewerIds.map(reviewers => reviewers.id);
      // console.log(reviewerIdsArray, 'array of reviewer IDs');
      onCancel();
      await GetVideoByID(video_id)  // Update the endpoint
      .then(response => {
        setVideo(response.data);
      })
      .catch(error => {
        console.error('Error fetching video:', error);
      });
      // var formData = new FormData();
      // var json_to_string = {}
      // json_to_string["video-reviewers"] = checkedKeywords
      // var string_json = JSON.stringify(json_to_string)
      // formData.append("video-details",string_json)
      // const res_json = await axios.post('http://localhost:5000/api/v1/update-video-data', formData, {params: {video_id:id}},{headers: {'Content-Type':'multipart/form-data'}})

    } catch (error) {
      console.error('Error creating user:', error);
    }
    
  };

  useEffect(() => {
    // Fetch data from your API or backend
    GetVideoByID(video_id)  // Update the endpoint
      .then(response => {
        setVideo(response.data);
        // console.log('masuk ke useEffect after add or remove reviewer============');
      })
      .catch(error => {
        console.error('Error fetching video:', error);
      });
  }, []);


  return (
    isOpen && (
      <div style={modalOverlay}>
        <div className='w-[70%] 2xl:w-[30%]' style={modal}>
          <div className='flex flex-row-reverse'>
            <button onClick={onCancel}>
              <GoX />
            </button>
          </div>
          <VideoDet video_data={video} onVideoDetailChange={handleVideoDetailChange} onReviewersDataChanges={handleCheckboxChange} checkedKeywords={checkedKeywords} updateCheckedKeywords={(updatedKeywords) => setCheckedKeywords(updatedKeywords)} video_id={video_id} isModal={true}/>
          <div className='flex mt-[10px] justify-center items-center'>
            <button onClick={handleupdatevideo} className='text-[20px] text-[white] font-["Arial"] bg-[#003C71] rounded-[6px] w-[20%] pt-[15px] pb-[15px] mr-[20px]' type='button'>Save</button>
            <button onClick={onCancel} className='text-[20px] font-["Arial"] bg-[#D9D9D9] rounded-[6px] w-[20%] pt-[15px] pb-[15px]' type='button'>Cancel</button>
          </div>
        </div>
      </div>
    )
  );
};

export default EditVideoDetailReviewModal;
