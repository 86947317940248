import {axios} from "../config";

export const AddTranscriptComment = async (transcript_id, user_id ,comment_text) => {
  const TranscriptComment = await axios.post('/api/addtranscript_comment',{
    user_id: parseInt(user_id),
    transcript_id: parseInt(transcript_id),
    comment_text : comment_text
  }).then((a) => {
    return a;
  })

  return TranscriptComment;
}